import sc from 'styled-components';
import {Layout, Text} from '@phonectas/unifon-ui-kit';

export const CenteredTextStyled = sc(Text)`
	text-align: center;
`;

export const DashboardStyled = sc(Layout)`
	padding: ${({theme}) => theme.spacing(0, 5)};
`;

export const BmoStyled = sc(Layout)`
	padding: ${({theme}) => theme.spacing(0, 5)};
`;

