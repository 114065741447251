/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewOkIcon({color = '#47AC45'}) {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="7.5" cy="7.5" r="7.5" fill={color}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M12.5614 5.44392L6.71298 11.5341L2.78516 7.44392L3.91859 6.35547L6.71298 9.26534L11.428 4.35547L12.5614 5.44392Z" fill="white"/>
		</svg>
	);
}

NewOkIcon.propTypes = {
	color: PropTypes.string,
};

export default NewOkIcon;
