import React from 'react';
import PropTypes from 'prop-types';
import {RotatableIcon} from '../AdaptiveDrawer/styled';

function ToggleDrawerIcon({isOpen, props}) {
	return (
		<RotatableIcon isOpen={isOpen} {...props}>
			<svg width="24" height="24" viewBox="0 0 19 17" fill="none" className="drawer-icon">
				{/* eslint-disable */}
				<path d="M0 8C0 3.58172 3.58172 0 8 0H11C15.4183 0 19 3.58172 19 8V9C19 13.4183 15.4183 17 11 17H8C3.58172 17 0 13.4183 0 9V8Z" fill="white"/>
				<rect fill="none" x="0.5" y="0.5" width="18" height="16" rx="0.5" stroke="#2C305E"/>
				<line fill="none" x1="13.5" y1="1" x2="13.5" y2="17" stroke="#2C305E"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M9.06066 4.14645C9.25592 4.34171 9.25592 4.65829 9.06066 4.85355L5.41421 8.5L9.06066 12.1464C9.25592 12.3417 9.25592 12.6583 9.06066 12.8536C8.8654 13.0488 8.54881 13.0488 8.35355 12.8536L4 8.5L8.35355 4.14645C8.54882 3.95118 8.8654 3.95118 9.06066 4.14645Z"/>
			</svg>
		</RotatableIcon>
	);
}

ToggleDrawerIcon.propTypes = {
	isOpen: PropTypes.bool,
	props: PropTypes.any,
}

export default React.memo(ToggleDrawerIcon);
