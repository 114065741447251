/* eslint-disable no-useless-escape */
/**
 * @param {string} email
 * @return {boolean}
 */
export function isEmailValid(email) {
	if (!email && typeof email !== 'string') return false;
	// eslint-disable-next-line max-len
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export const isStringNotEmpty = ((value) => {
	return value.trim() !== '';
});

export function isValidPhone(phone) {
	// +47<8 digits>
	// or (without leading +47)
	// <8 digits>
	// the 8 digits have to start with 2,3,4,5,6,7 or 9
	return /^\+?(\d{8,})$/.test(phone);
}

export function isNumber(value) {
	return /^\d+$/.test(value);
}

export function isNumberPhone(value) {
	// is number
	// and min length of 8 digits
	return /^(?:\+47)?-?\d{8,}$/.test(value);
}
