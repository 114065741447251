import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import useBMOStore from 'common/stores/BMO';

import {Dialog, Layout} from '@phonectas/unifon-ui-kit';
import TextDivWithLabelEditable from 'common/components/TextDivWithLabelEditable';

function EditNoteDialog({show, onClose, note, caseId}) {
	const [t] = useTranslation();
	const {updateNote, getTicketId} = useBMOStore();
	const [text, setText] = useState(note?.text || '');
	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);
	const handleNoteChange = useCallback((event) => {
		setText(event.target.value);
	}, [text]);
	const onUpdateNote = useCallback(async () => {
		try {
			await updateNote(caseId, note?.id, text, () => {
				onClose();
				getTicketId(caseId);
			});
		} catch (error) {
			console.log(error);
		}
	}, [caseId, note, text, onClose, updateNote]);

	return (
		<Dialog
			title={t('Edit note {{id}}', {id: note?.id})}
			show={show}
			onOk={onUpdateNote}
			okButtonLabel={t('Update')}
			onClose={handleClose}
			closeButtonLabel={t('Close')}
		>
			<Layout fullWidth>
				<TextDivWithLabelEditable
					text={text}
					onEdit={handleNoteChange}
				/>
			</Layout>
		</Dialog>
	);
}

EditNoteDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	// onOk: PropTypes.func.isRequired,
	caseId: PropTypes.string.isRequired,
	note: PropTypes.object.isRequired,
};

export default EditNoteDialog;
