import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {StyledLabel, StyledTextDiv} from '../TextDivWithLabel/styled';
import {StyledInput} from './styled';

function TextDivWithLabelEditable({label, text, onEdit}) {
	const [editableText, setEditableText] = useState(text);

	const handleTextChange = useCallback((event) => {
		setEditableText(event.target.value);
		onEdit(event);
	}, [onEdit]);

	return (
		<>
			{label && <StyledLabel variant="body1">{label}</StyledLabel>}
			<StyledTextDiv>
				<StyledInput
					type="text"
					value={editableText}
					onChange={handleTextChange}
				/>
			</StyledTextDiv>
		</>
	);
}

TextDivWithLabelEditable.propTypes = {
	text: PropTypes.string.isRequired,
	label: PropTypes.string,
	onEdit: PropTypes.func.isRequired,
};

export default TextDivWithLabelEditable;
