import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Button, Checkbox, Layout, Text} from '@phonectas/unifon-ui-kit';
import {unifonEmailValid} from 'utils/email';
import EmailTable from './EmailTable';
import {StyledTextFieldXl} from './styled';

function EmailNotice({form, user, onChange}) {
	const [t] = useTranslation();
	const [activateNotice, setActivateNotice] = useState(true);
	const [addEmail, setAddEmail] = useState('');
	const [ErrorMsg, setErrorMsg] = useState('');
	const handleActiveNotice = useCallback(() => {
		setActivateNotice((prevActivateNotice) => !prevActivateNotice);
	}, []);
	const disableAddEmail = form.notificationEmails.length >= 10;
	useEffect(() => {
		if (!activateNotice) {
			onChange('notificationEmails', []);
			setAddEmail('');
			setErrorMsg('');
		} else if (user?.email && activateNotice) {
			const userEmail = user.email;
			if (!form.notificationEmails.includes(userEmail)) {
				onChange('notificationEmails', [...form.notificationEmails, user?.email]);
			}
		}
	}, [activateNotice, user, onChange]);
	const handleAddEmail = useCallback((val) => {
		setAddEmail(val.target.value);
	}, [addEmail]);
	const onAddEmail = useCallback(() => {
		if (onValidate()) {
			onChange('notificationEmails', [...form.notificationEmails, addEmail]);
			setAddEmail('');
			setErrorMsg('');
		} else {
			setErrorMsg(t('Must be valid Email and no duplicates'));
		}
	}, [form.notificationEmails, addEmail, onChange]);

	const handleDeleteEmail = useCallback((index) => {
		const updatedEmails = form.notificationEmails.toSpliced(index, 1);
		onChange('notificationEmails', updatedEmails);
	}, [form.notificationEmails, onChange]);

	const onValidate = useCallback(() => {
		let isValid;
		if (unifonEmailValid(addEmail)) {
			const cleanEmail = addEmail.trim().toLowerCase();
			if (!form.notificationEmails.map((email) => email.trim().toLowerCase()).includes(cleanEmail) &&
            form.notificationEmails.length < 10) {
				isValid = true;
			}
		} else {
			isValid = false;
		}
		return isValid;
	}, [addEmail, form.notificationEmails]);

	return (
		<Layout fullWidth direction="column">
			<Checkbox
				big
				toggle
				label={t('Email notification')}
				checked={activateNotice}
				onChange={handleActiveNotice}
			/>
			{
				activateNotice && (
					<Layout fullWidth direction="column" nowrap>
						<Text color="error">{ErrorMsg}</Text>
						<Layout
							direction="row"
							alignItems="end"
							justifyContent="space-between"
							fullWidth
						>
							<StyledTextFieldXl
								fullWidth
								name="email"
								label={t('Add email')}
								placeholder={t('name.lastname@unifon.no')}
								value={addEmail}
								onChange={handleAddEmail}
							/>
							<Button
								variant='secondary'
								onClick={onAddEmail}
								disabled={disableAddEmail}
							>
								{t('Add')}
							</Button>
						</Layout>
						<Layout
							direction="row"
							fullWidth
						>
							<EmailTable
								form={form}
								onDelete={handleDeleteEmail}
							/>
						</Layout>
					</Layout>
				)
			}
		</Layout>
	);
}

EmailNotice.propTypes = {
	form: PropTypes.object,
	user: PropTypes.object,
	onChange: PropTypes.func,
};

export default EmailNotice;
