export const ROUTES = {
	DEV_DOC: '/dev-docs',
	DEV_DOC_USER: '/dev-docs/:id',
	PROFILE: '/profile',
	DASHBOARD: '/dashboard',
	BMODASHBOARD: '/BMO/*',
	BMO_DASHBOARD: 'cases',
	BMO_CREATE_TICKET: 'create_ticket',
	BMO_CASES: 'cases',
	BMO_CASES_ID: 'cases/:id',
	THREE_SIXTY: '/Unifon360',
	THREE_SIXTY_CUSTOMER: '/Unifon360/:id',
	THREE_SIXTY_NUMBER_NUMB: '/Unifon360/n/:numb',
	UNAUTHORIZED_USER: '/unauthorized',
};

export const RESPONSE_STATUSES = {
	OK: 'OK',
	ERROR: 'error',
};
export const DEBOUNCE_INPUT_TIMEOUT = 400;

export const SORT_DIR = {
	ASC: 'asc',
	DESC: 'desc',
};

export const SORT_SUBSCRIPTION_BY = {
	NAME: 'FirstName',
	PHONE: 'PhoneNumber',
	PROFILE: 'ProfileName',
	SUM_USAGE_WORK: 'SumUsageWork',
	SUM_USAGE_PRIVATE: 'SumUsagePrivate',
	SUM_USAGE_TOTAL: 'SumUsageTotal',
	DATA_SIZE: 'DataSize',
	DATA_FREE: 'DataFree',
};

export const CUSTOM_COLOR_INFO = '#1488F2';

// roles
export const ROLES = {
	ADMIN: 'admin',
	BMO: 'bmo',
	LARGEACC: 'largeaccount',
	CUSTOMER_SERVICE: 'customerservice',
};

export const LIMIT_PAYLOAD = 50;
