import React, {useCallback} from 'react';
import {
	Avatar, CallIcon, Layout, MobilesIcon, Text, Divider, Radio,
} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';
import {LANG_LS_KEY} from 'common/i18n';

import {phoneNumber} from 'utils/number';
import UserModel from 'common/models/user';
import {SubscriptionDetailsType} from '../../proptypes';
import {StyledNoSelectText} from '../../styled';

UserInfo.propTypes = {
	data: SubscriptionDetailsType.isRequired,
};

/**
 * @module dataInfo
 * @param {SubscriptionDetailsType} data
 * @return {JSX.Element}
 * @constructor
 */
function UserInfo({data}) {
	const [t, i18n] = useTranslation();
	const onChangeLanguage = useCallback(async (e) => {
		await i18n.changeLanguage(e.target.value);
		try {
			localStorage.setItem(LANG_LS_KEY, e.target.value);
		} catch (err) {
			console.error(err);
		}
	}, [i18n]);

	return (
		<>
			<Layout
				nowrap
				fullWidth
				alignItems="start"
				justifyContent="space-between"
			>
				<Avatar size="huge" src={UserModel.getAvatarUrl(data.id, data.customerId, data.picture)} />
				<Layout fullWidth direction="column" nowrap>
					<Layout gap="xs" direction="column">
						<Text variant="h1">{data.name}</Text>
						<Layout gap="none" fullWidth nowrap alignItems="center" direction="column">
							{data?.mobile && (
								<Layout nowrap fullWidth key={data.mobile} alignItems="center">
									<MobilesIcon />
									<Text color="textSecondary" variant="body1">{phoneNumber(data?.mobile)}</Text>
								</Layout>
							)}
							{data?.fixed && (
								<Layout nowrap fullWidth key={data.fixed} alignItems="center">
									<CallIcon />
									<Text color="textSecondary" variant="body1">{phoneNumber(data?.fixed)}</Text>
								</Layout>
							)}
						</Layout>
						{data?.departmentName && (
							<Layout nowrap gap="xs" key={data?.departmentName}>
								<StyledNoSelectText color="textPrimary" variant="body1">{t('Department:')}</StyledNoSelectText>
								<Text color="textSecondary" variant="body1">{data?.departmentName}</Text>
							</Layout>
						)}
						{data?.email && (
							<Layout nowrap gap="xs" key={data?.email}>
								<StyledNoSelectText color="textPrimary" variant="body1">{t('Email:')}</StyledNoSelectText>
								<Text color="textSecondary" variant="body1">{data?.email}</Text>
							</Layout>
						)}
						<Layout direction="row" gap="xs" style={{alignItems: 'center'}}>
							<StyledNoSelectText color="textPrimary" variant="body1">{t('Roles:')}</StyledNoSelectText>
							{
								data.roles?.map((role) => (
									<StyledNoSelectText color="textSecondary" variant="body1" key={role}>{role}</StyledNoSelectText>
								))
							}
						</Layout>
					</Layout>
					<Divider fullWidth/>
					<Text>{t('Language settings')}</Text>
					<Layout fullWidth nowrap>
						<Radio
							name="lang"
							value="en"
							label="English"
							checked={i18n.language === 'en'}
							onChange={onChangeLanguage}
						/>
						<Radio
							name="lang"
							value="no"
							label="Norsk"
							checked={i18n.language === 'no'}
							onChange={onChangeLanguage}
						/>
					</Layout>
					<Divider fullWidth/>
				</Layout>
			</Layout>
			<Layout
				nowrap
				fullWidth
				alignItems="start"
				justifyContent="space-between"
			>
			</Layout>
		</>
	);
}

export default React.memo(UserInfo);
