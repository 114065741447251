export function calculateUserCases(json, user) {
	const openCases = json.filter((ticket) =>
		(ticket.status === 'Open' ||
        ticket.status === 'Acknowledged' ||
        ticket.status === 'InProgress') &&
        ticket.reporter === user,
	);
	const closedCases = json.filter((ticket) =>
		(ticket.status === 'Resolved' ||
        ticket.status === 'Closed') &&
        ticket.reporter === user,
	);
	const onHoldCases = json.filter((ticket) =>
		(ticket.status === 'Held' ||
        ticket.status === 'pending') &&
        ticket.reporter === user,
	);

	const totalOpenCases = json.filter((ticket) =>
		ticket.status === 'Open' ||
        ticket.status === 'Acknowledged' ||
        ticket.status === 'InProgress',
	);
	const totalClosedCases = json.filter((ticket) =>
		ticket.status === 'Resolved' ||
        ticket.status === 'Closed',
	);
	const totalOnHoldCases = json.filter((ticket) =>
		ticket.status === 'Held' ||
        ticket.status === 'pending',
	);

	return {
		usersCases: {
			open: openCases.length,
			closed: closedCases.length,
			held: onHoldCases.length,
			total: openCases.length + closedCases.length + onHoldCases.length,
		},
		totalCases: {
			open: totalOpenCases.length,
			closed: totalClosedCases.length,
			held: totalOnHoldCases.length,
			total: totalOpenCases.length + totalClosedCases.length + totalOnHoldCases.length,
		},
	};
}
