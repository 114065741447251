import sc from 'styled-components';
import {Layout, Dialog, Divider, Text, IconButton} from '@phonectas/unifon-ui-kit';

export const StyledToolbarNew = sc(Layout)`
	margin: ${({theme}) => theme.spacing(4, 0, 3)};
	padding: ${({theme, $vPadding = 0, $hPadding = 0}) => theme.spacing($vPadding, $hPadding)};
	height: ${({theme}) => theme.spacing(7.5)}px;
	min-height: ${({theme}) => theme.spacing(7.5)}px;
	align-items: center;
	position: relative;
`;

export const StyledSmallDialog = sc(Dialog)`
	width: 600px;
`;

export const StyledDivider = sc(Divider)`
	margin: 10px 0;
`;

export const StyledBigDialog = sc(Dialog)`
	width: 960px;
	max-width: 80vw;
	max-height: 90vh;
`;

export const StyledInfoText = sc(Text)`
	justify-content: center;
	align-items: center;
`;

export const StyledIconButton = sc(IconButton)`
	height: ${({width}) => width || 36}px;
	min-Height: ${({width}) => width || 36}px;
	width: ${({height}) => height || 36}px;
	min-Width: ${({height}) => height || 36}px;
`;
