import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, Layout, Text} from '@phonectas/unifon-ui-kit';
import {StyledTextDiv, StyledLabel} from './styled';
import {maskString} from 'utils/div';
// import ShowPasswordIcon from 'common/components/icons/ShowPasswordIcon';
// import HidePasswordIcon from 'common/components/icons/hidePasswordIcon';


function TextDivWithLabel({label, text, password, noBorder, smBorderRadius}) {
	const [isTextVisible, setIsTextVisible] = useState(false);

	// const handleMouseEnter = useCallback(() => {
	// 	if (password) {
	// 		setIsTextVisible(true);
	// 	}
	// }, []);
	// const handleMouseLeave = useCallback(() => {
	// 	if (password) {
	// 		setIsTextVisible(false);
	// 	}
	// }, []);
	const handleShowText = useCallback(() => {
		if (password) {
			setIsTextVisible((prev) => !prev);
		}
	}, []);

	return (
		<>
			{
				password ? (
					<Layout fullWidth gap="sm">
						{label && (
							<StyledLabel variant="body1">
								{label}
							</StyledLabel>
						)}
						<StyledTextDiv
							$noBorder={noBorder}
							$smBorderRadius={smBorderRadius}
							style={{
								display: 'inline-flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								height: '40px',
							}}
						>
							<Text variant="body1">{isTextVisible ? text : maskString(text)}</Text>
							<IconButton size="xs" onClick={handleShowText}>
								{/* { isTextVisible ? <HidePasswordIcon /> : <ShowPasswordIcon />} */}
							</IconButton>
						</StyledTextDiv>
					</Layout>
				) : (
					<Layout fullWidth gap="sm">
						{label && (
							<StyledLabel variant="body1">
								{label}
							</StyledLabel>
						)}
						<StyledTextDiv
							$noBorder={noBorder}
							$smBorderRadius={smBorderRadius}
						>
							<Text variant="body1">{text}</Text>
						</StyledTextDiv>
					</Layout>
				)
			}
		</>
	);
}

TextDivWithLabel.propTypes = {
	text: PropTypes.string.isRequired,
	label: PropTypes.string,
	password: PropTypes.bool,
	noBorder: PropTypes.bool,
	smBorderRadius: PropTypes.bool,
};

export default TextDivWithLabel;
