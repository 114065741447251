import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {LIMIT_PAYLOAD} from 'common/constants';


function BillingGroupTable({search, id}) {
	const [t] = useTranslation();
	const {fetchBillingGroups} = useThreeSixtyStore();
	const {billingGroups, totalCount, accountNotFound} = useThreeSixtyStore((state) => ({
		billingGroups: state.accountTables?.billingGroups,
		totalCount: state.accountTables?.billingGroups?.total_count,
		accountNotFound: state.accountNotFound,
	}));
	const billingGroupsData = billingGroups?.billing_groups;

	const onLoadMore = useCallback((size) => {
		if (totalCount <= size) return;
		fetchBillingGroups(size + LIMIT_PAYLOAD, id);
	}, [id, totalCount]);

	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			// onClick={() => onRowClick(item)}
			selected={item.index === index}
			index={index}
		>
			<Table.Cell>
				{item?.name ? item?.name : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.owner ? item?.owner : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.company_vat_no ? item?.company_vat_no : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.total_outstanding ? item?.total_outstanding : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.mobile_count ? item?.mobile_count : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.mbb_count ? item?.mbb_count : ''}
			</Table.Cell>
			<Table.Cell>
				{item?.fixed_count ? item?.fixed_count : ''}
			</Table.Cell>
		</Table.Row>
	), []);

	useEffect(() => {
		if (accountNotFound) return;

		const isAccountIdMatched = billingGroupsData?.length > 0 && id === billingGroupsData[0]?.account_id;
		const isBillingGroupFetched = billingGroups !== null && isAccountIdMatched;
		const isBillingGroupsFilterMatched = billingGroups?.filters?.account_id === id && billingGroupsData === null;

		if (isBillingGroupFetched || isBillingGroupsFilterMatched) return;

		fetchBillingGroups(LIMIT_PAYLOAD, id);
	}, [id, billingGroups, billingGroupsData, accountNotFound]);

	if (!billingGroups || !billingGroups?.billing_groups) {
		return (
			<StyledSectionDiv $endDiv $fullHeight>
				<Layout
					fullHeight
					fullWidth
					alignItems="center"
					justifyContent="center"
				>
					<Text>{t('No data')}</Text>
				</Layout>
			</StyledSectionDiv>
		);
	}

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={billingGroupsData}
				renderItem={(item, index) => renderItem(item, index)}
				virtualized
				onScrollDown={onLoadMore}
			>
				<Table.Row style={{position: 'sticky', top: '0px'}}>
					<Table.Cell>{t('Name')}</Table.Cell>
					<Table.Cell>{t('Owner')}</Table.Cell>
					<Table.Cell>{t('Org')}</Table.Cell>
					<Table.Cell>{t('Balance')}</Table.Cell>
					<Table.Cell>{t('Mobile')}</Table.Cell>
					<Table.Cell>{t('Broadband')}</Table.Cell>
					<Table.Cell>{t('Fixed')}</Table.Cell>
				</Table.Row>
			</Table>
		</StyledSectionDiv>
	);
}

BillingGroupTable.propTypes = {
	id: PropTypes.string,
	search: PropTypes.string,
};

export default BillingGroupTable;
