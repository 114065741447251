import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
// import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {serviceStatuses, maskString} from 'utils/div';
import {EmailIcon, Table, Tooltip} from '@phonectas/unifon-ui-kit';
import {nullCheck} from 'utils/div';
import {formatFileSize} from 'utils/file';

function UsersTableRow({data, onClick}) {
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const [isHovered, setIsHovered] = useState(false);
	const checkedPin = nullCheck(data?.pin);
	const checkedPuk = nullCheck(data?.puk);
	const handleCopyEmail = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(data?.email);
	}, [copyToClipboard, data?.email]);
	const handleCopyPin = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(`PIN: ${checkedPin} / PUK: ${checkedPuk}`);
	}, [copyToClipboard, data?.pin, data?.puk]);

	const handleRowClick = useCallback(() => {
		onClick(data);
	}, [onClick, data]);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<Table.Row onClick={handleRowClick}>
			<Table.Cell>{nullCheck(data?.name)}</Table.Cell>
			<Table.Cell>{nullCheck(data?.phone_number)}</Table.Cell>
			<Table.Cell>
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					{data?.email && (
						<Tooltip
							text={data?.email}
							arrow
						>
							<span
								style={{cursor: 'pointer'}}
								onClick={handleCopyEmail}
							>
								<EmailIcon />
							</span>
						</Tooltip>
					)}
				</span>
			</Table.Cell>
			<Table.Cell>
				{
					serviceStatuses(data?.status)
				}
			</Table.Cell>
			<Table.Cell>{nullCheck(data?.subscription_type)}</Table.Cell>
			<Table.Cell>{formatFileSize(data?.used)}</Table.Cell>
			<Table.Cell>{nullCheck(data?.sim_type)}</Table.Cell>
			<Table.Cell
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{cursor: 'pointer'}}
				onClick={handleCopyPin}
			>
				{
					data?.pin && data?.puk && (
						isHovered ? `${checkedPin} / ${checkedPuk}` : `${maskString(checkedPin.toString())} / ${maskString(checkedPuk.toString())}`
					)
				}
			</Table.Cell>
		</Table.Row>
	);
}

UsersTableRow.propTypes = {
	data: PropTypes.object.isRequired,
	onClick: PropTypes.func,
};

export default UsersTableRow;
