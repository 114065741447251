import sc from 'styled-components';

import {Layout, ShevronDownIcon} from '@phonectas/unifon-ui-kit';

export const StyledInfoBox = sc(Layout)`
	background-color: #F3F3F5;
	border-radius: ${({theme}) => theme.borderRadius * 2}px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	height: 96px;
	width: 100%;
	min-width: 183px;
	padding: 10px;
	flex-wrap: nowrap;
`;

export const StyledHeaderWarning = sc(Layout)`
	background-color: #2C305E;
	padding: 15px;
	border-radius: ${({theme}) => {
		const radius = theme.borderRadius * 2;
		return `${radius}px ${radius}px 0 0`;
	}};
`;

export const StyledCustomerBox = sc(Layout)`
	background-color: #F3F3F5;
	border-radius: ${({theme}) => theme.borderRadius * 2}px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	height: 110px;
    width: 100%;
    max-width: 180px;
	padding: 10px;
`;

export const StyledShevronIcon = sc(ShevronDownIcon)`
	color: ${({theme}) => theme.palette.primary};
	transitions: transform 100ms;
`;

export const StyledShevronButton = sc.button`
	cursor: pointer;
	border: 0;
	box-sizing: border-box;
	position: relative;
	display: flex;
	align-items: start;
	justify-content: space-between;
	background-color: transparent;
	padding-top: 10px;
	width: auto;
	${({$open}) => `${StyledShevronIcon} {
		transform: rotate(${!$open ? '180deg' : '0deg'});
	}`}
`;
