import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
// import {truncate} from 'lodash';

// import {phoneNumber} from 'utils/number';
// import {getDateByCustomFormat, DATE_FORMATS, formatDuration} from 'utils/date';
import {Divider, Layout, Text} from '@phonectas/unifon-ui-kit';
// import NewEllipseIcon from 'common/components/icons/newEllipseIcon';
// import NewWindowIcon from 'common/components/icons/NewWindowIcon';
// import {StyledDurationDiv} from './styled';
// import getIconByType from '../../getIconByType';
// import EventDescription from './EventDescription';
import {useTranslation} from 'react-i18next';
import eventTypeMapping from '../../eventTypeMapping';
// import getTitleByType from '../../getTitleByType';

function Events({events, show, handleScrollAction}) {
	const [t] = useTranslation();
	const handleScroll = () => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			const {scrollTop, scrollHeight, clientHeight} = scrollElement;
			if (scrollTop + clientHeight >= scrollHeight - 1) {
				handleScrollAction();
			}
		}
	};

	useEffect(() => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			scrollElement.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	if (!events || !events?.feed_entries) {
		return (
			<Layout direction="column" gap="none" fullWidth fullHeight>
				<Text variant="body1">{t('No events found')}</Text>
			</Layout>
		);
	}

	return (
		<Layout
			fullWidth
			fullHeight
			direction="column"
			nowrap
			autoOverflow
			id="events_wrapper"
			style={{
				paddingRight: '5px',
				paddingBottom: show ? '20px' : '0px',
				// maxHeight: '100vh',
			}}
		>
			{events?.feed_entries?.map((event, index) => {
				const EventComponent = eventTypeMapping[event.feed_type];
				return EventComponent ? (
					<Layout gap="sm" direction="column" fullWidth nowrap key={index}>
						<EventComponent key={index} data={event} id={event.id} />
						{index !== events?.feed_entries.length - 1 && <Divider />}
					</Layout>
				) : ( <></>
				// <div key={index}>Unknown event type: {event.feed_type}</div>
				);
			})}
			{/* {
				events?.feed_entries?.map((event, index) => (
					<Layout gap="sm" direction="column" fullWidth nowrap key={index}>
						<Layout gap="sm" fullWidth nowrap fullHeight>
							<Layout direction="column" nowrap style={{width: '42px'}}>
								{getIconByType(event.feed_type)}
							</Layout>
							<Layout direction="column" fullWidth nowrap>
								<Layout direction="row" fullWidth nowrap>
									<Layout direction="column" fullWidth nowrap gap="xs">
										<Layout direction="row" fullWidth alignItems="center" justifyContent="space-between">
											<Text variant="h3">{getTitleByType(event.feed_type)}</Text>
											<Text color="textSecondary" variant="body1">
												{
													getDateByCustomFormat(event.created_at, DATE_FORMATS.DD_MMM_YYYY_HH_mm)
												}
											</Text>
										</Layout>
										<Layout fullWidth direction="row" nowrap>
											{
												event.feed_type === 'BN_CALL_LOG' ?
													<Layout fullWidth gap="none">
														<Layout
															direction="row"
															justifyContent="space-between"
															fullWidth
															alignItems="center"
															gap="none"
														>
															<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
																{phoneNumber(event?.data_fields.receiver_number)}
															</Text>
															<StyledDurationDiv>
																<Text variant="body1">{formatDuration(event.data_fields.calltime_ms)}</Text>
															</StyledDurationDiv>
														</Layout>
														<Layout
															direction="row"
															fullWidth
															justifyContent="space-between"
															gap="none"
															alignItems="center"
															nowrap
														>
															<Text color="textSecondary" variant="body1">{event?.data_fields.user_name}</Text>
														</Layout>
													</Layout> :
													<Text color="textSecondary" variant="body1">{event.contact}</Text>
											}
											{
												event.feed_type === 'THREESIXTY_COMMENT' && (
													<Text color="textSecondary" variant="body1">{event.data_fields?.actor?.name}</Text>
												)
											}
										</Layout>
										{
											event?.data_fields?.body && (
												<Layout direction="row" nowrap>
													<EventDescription description={event?.data_fields.body} />
												</Layout>
											)
										}
										<Layout direction="row" fullWidth style={{gap: '8px'}}>
											<Layout gap="none" nowrap alignItems="center">
												<Text
													style={{userSelect: 'none'}}
													variant="body1"
													color="textSecondary"
												>
													Zendesk
												</Text>
												<IconButton size="sm" onClick={() => console.log('open zendesk')}>
													<NewWindowIcon color="#9798A9" />
												</IconButton>
											</Layout>
											<div style={{alignItems: 'center', display: 'flex'}}><NewEllipseIcon /></div>
											<Layout gap="none" alignItems="center" nowrap>
												<Text variant="body1" color="textSecondary">{`ID ${event.id}`}</Text>
											</Layout>
											{
												event.feed_type === 'BN_CALL_LOG' && (
													<>
														<div style={{alignItems: 'center', display: 'flex'}}><NewEllipseIcon /></div>
														<Layout gap="none" alignItems="center" nowrap>
															<Text variant="body1" color="textSecondary">
																{`${t('Queue')} # ${event?.data_fields?.queue_name}`}
															</Text>
														</Layout>
													</>
												)
											}
										</Layout>
									</Layout>
								</Layout>
							</Layout>
						</Layout>
						{index !== events.length - 1 && <Divider />}
					</Layout>
				))
			} */}
		</Layout>
	);
}

Events.propTypes = {
	events: PropTypes.object,
	show: PropTypes.bool,
	handleScrollAction: PropTypes.func,
};

export default Events;
