import sc from 'styled-components';

import {Layout} from '@phonectas/unifon-ui-kit';

export const StyledEventLayout = sc(Layout)`
	height: ${({activeTab}) => (activeTab === '1' ? '100%' : 'auto')};
	width: 100%;
	overflow: hidden;
`;

export const StyledTabsLayout = sc(Layout)`
	display: flex;
`;
