import BnCall from './CustomerEvents/components/eventTypes/BnCall';
import ErateSystemCall from './CustomerEvents/components/eventTypes/ErateSystemCall';
import ErateSystemSms from './CustomerEvents/components/eventTypes/ErateSystemSms';
import ThreeSixtyComments from './CustomerEvents/components/eventTypes/ThreeSixtyComments';
import ThreeSixtyNewCustomerResp from './CustomerEvents/components/eventTypes/ThreeSixtyNewCustomerResp';

const eventTypeMapping = {
	THREESIXTY_COMMENT: ThreeSixtyComments,
	THREESIXTY_NEW_CUSTOMER_RESP: ThreeSixtyNewCustomerResp,
	BN_CALL_LOG: BnCall,
	ERATE_CALL_LOG: ErateSystemCall,
	ERATE_SYSTEM_SMS: ErateSystemSms,
	// Add more mappings as needed
};

export default eventTypeMapping;
