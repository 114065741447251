import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {formatNumber} from 'utils/number';
import NewMobilesIcon from 'common/components/icons/NewMobilesIcon';
import BroadbandIcon from 'common/components/icons/BroadbandIcon';
import BedriftsnettIcon from 'common/components/icons/BedriftsnettIcon';
import NewTeamsIcon from 'common/components/icons/NewTeamsIcon';
import NewQuestionIcon from 'common/components/icons/NewQuestionIcon';
import {IconButton, Layout, Text, Tooltip} from '@phonectas/unifon-ui-kit';
import {StyledCustomerDevices, StyledDeviceContainer} from './styled';


function CustomerDevices({data}) {
	const [t] = useTranslation();
	const products = data?.account_details?.products;
	const details = data?.account_details;

	const InfoBox = useMemo(() => ({
		mobiles: {
			label: t('Mobiles'),
			icon: <NewMobilesIcon />,
			value: products?.mobiles_active_count,
			inactive: products?.mobiles_inactive_count,
			inPorting: products?.mobiles_on_the_way_count,
			name: 'mobiles',
		},
		broadband: {
			label: t('Mobile broadband'),
			icon: <BroadbandIcon />,
			value: products?.mobile_broadbands_count,
			name: 'mobileBroadband',
		},
		bedriftsnett: {
			label: t('Bedriftsnett'),
			icon: <BedriftsnettIcon />,
			value: products?.bedriftsnett_count,
			name: 'bedriftsnett',
		},
		teams: {
			label: t('Speech in Teams'),
			icon: <NewTeamsIcon />,
			value: products?.teams_count,
			name: 'speechInTeams',
		},
		lastInvoice: {
			label: t('Last invoice excl. VAT.'),
			value: formatNumber(details?.revenue_last_month),
			// date: getDateByCustomFormat(data?.invoice.date, DATE_FORMATS.DD_MM_YYYY),
			name: 'lastInvoice',
		},
		binding: {
			label: t('Binding'),
			value: getDateByCustomFormat(details?.locking_end, DATE_FORMATS.DD_MM_YYYY),
			name: 'binding',
		},
	}), [t, data]);

	return (
		<StyledDeviceContainer>
			{
				Object.values(InfoBox).map((box, index) => (
					<StyledCustomerDevices key={index}>
						<Layout
							direction="column"
							fullWidth
							gap="none"
							justifyContent="space-between"
						>
							<Layout
								direction="row"
								fullWidth
								alignItems="center"
								gap="sm"
								style={{minHeight: '28px', userSelect: 'none'}}
							>
								{
									box.icon && (
										<Layout>
											{box.icon}
										</Layout>
									)
								}
								<Layout gap="none">
									<Text variant="body" color="textSecondary">
										{box.label}
									</Text>
									{/* {
										box.name === 'lastInvoice' && (
											<Text color="textSecondary">
												{`(${box.date})`}
											</Text>
										)
									} */}
								</Layout>
							</Layout>
							<Layout direction="row" gap="none" fullWidth>
								<Text variant="h1" style={{fontSize: '30px'}}>
									{box.value}
								</Text>
								{
									box.name === 'mobiles' && (
										<Tooltip
											position="top"
											text={
												<span>
													<span style={{color: '#2c305e'}}>{`${box?.inPorting}`}</span>
													{` ${t('in porting')} / `}
													<span style={{color: '#2c305e'}}>{`${box?.inactive}`}</span>
													{` ${t('inactive')}`}
												</span>
											}
										>
											<IconButton style={{alignItems: 'center'}}>
												<NewQuestionIcon />
											</IconButton>
										</Tooltip>
									)
								}
							</Layout>
						</Layout>
					</StyledCustomerDevices>
				))
			}
		</StyledDeviceContainer>
	);
}

CustomerDevices.propTypes = {
	data: PropTypes.object,
};

export default CustomerDevices;
