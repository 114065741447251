import React, {useCallback, useEffect, useState} from 'react';
import {Layout, ThemeProvider, ToastOutlet} from '@phonectas/unifon-ui-kit';
import {Routes, Route, Navigate} from 'react-router-dom';

import 'common/i18n';
import '../../../assets/favicon.png';
import GlobalStyle from 'common/globalStyle';
import AppBar from './components/AppBar';
import MainNav from './components/MainNav';
import {ROUTES} from 'common/constants';
import ProtectedRoute from '../../common/components/ProtectedRoute';
import Login from 'containers/Auth';
import Logout from '../Auth/logout';
import BMODashboard from '../BMO';
import useUserStore from 'common/stores/user';
import useBMOStore from 'common/stores/BMO';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import AuthService from 'services/auth';
import 'utils/date';
import CasesTab from '../BMO/tabs/CasesTab';
import CreateTicketTab from '../BMO/tabs/CreateTicketTab';
import Profile from '../Profile';
import ThreeSixtyDashboard from '../ThreeSixty';
import ThreeSixtyCustomer from '../ThreeSixty/components/customer';
import DeveloperDocUsers from '../DevDoc';
import Unauthorized from '../Unauthorized';
import RedirectFromBn from '../ThreeSixty/RedirectFromBn';
// import {ROLES} from 'common/constants';

/**
 * @module RootModule
 * @return {JSX.Element}
 * @constructor
 */
function App() {
	const {user, getUser} = useUserStore();
	const {setActiveUser} = useBMOStore();
	const {setCurrentUser} = useThreeSixtyStore();

	const [expandMainNav, setExpandMainNav] = useState(false);
	const toggleNav = useCallback(() => setExpandMainNav(!expandMainNav), [expandMainNav]);
	useEffect(() => {
		if (AuthService.isValidToken()) {
			getUser();
		}
	}, [getUser]);

	useEffect(() => {
		if (user?.name) {
			setActiveUser(user?.name);
			setCurrentUser(user?.name);
		}
	}, [user?.name]);

	if (!user) return null;


	return (
		<ThemeProvider theme="unifon">
			<AppBar onToggleMenu={toggleNav}/>
			<Layout
				gap="none"
				nowrap
				fullWidth
				fullHeight
				alignItems="stretch"
				autoOverflow
				style={{minWidth: 1000}}
			>
				<MainNav expand={expandMainNav} />
				<Routes location={location}>
					<Route path="/*" element={<Navigate to={ROUTES.BMODASHBOARD} />} />
					<Route path={ROUTES.BMODASHBOARD} element={<ProtectedRoute><BMODashboard /></ProtectedRoute>} >
						<Route path={ROUTES.BMO_CREATE_TICKET} element={<ProtectedRoute><CreateTicketTab /></ProtectedRoute>} />
						<Route path={ROUTES.BMO_CASES} element={<ProtectedRoute><CasesTab /></ProtectedRoute>} />
					</Route>
					<Route path={ROUTES.PROFILE} element={<ProtectedRoute><Profile /></ProtectedRoute>} />
					<Route path={ROUTES.DEV_DOC} element={<ProtectedRoute><DeveloperDocUsers /></ProtectedRoute>} />
					<Route path={ROUTES.DEV_DOC_USER} element={<ProtectedRoute><DeveloperDocUsers /></ProtectedRoute>} />
					<Route path={ROUTES.THREE_SIXTY} element={
						<ProtectedRoute><ThreeSixtyDashboard /></ProtectedRoute>
					} />
					<Route path={ROUTES.THREE_SIXTY_CUSTOMER} element={
						<ProtectedRoute><ThreeSixtyCustomer /></ProtectedRoute>
					} />
					<Route path={ROUTES.THREE_SIXTY_NUMBER_NUMB} element={
						<ProtectedRoute><RedirectFromBn /></ProtectedRoute>
					} />
					<Route path={ROUTES.UNAUTHORIZED_USER} element={<Unauthorized />} />
					<Route path="login" element={<Login/>} exact />
					<Route path="logout" element={<Logout />} exact />
					<Route path="*" element={<Navigate to={ROUTES.BMODASHBOARD} />} />
				</Routes>
			</Layout>
			<GlobalStyle />
			<ToastOutlet />
		</ThemeProvider>
	);
}

export default App;
