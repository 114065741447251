import React from 'react';
import PropTypes from 'prop-types';

import {CopyIcon, IconButton, Layout, Text} from '@phonectas/unifon-ui-kit';
// import {maskString} from 'utils/div';

function CustomList({label, primaryText, password, copy, onClick, compact}) {
	return (
		<table style={{
			borderBottom: '1px solid rgba(44, 48, 94, 0.2)',
			height: '43px',
			width: compact ? 'fit-content' : '100%',
		}}>
			<tbody>
				<tr style={{width: '100%'}}>
					<td style={{width: compact ? 'fit-content' : '110px', height: '100%'}}>
						<Text color="secondary" variant="body1">{label}:</Text>
					</td>
					<td style={{width: compact ? '100%' : 'fit-content'}}>
						<Layout
							fullWidth
							alignItems="center"
							justifyContent="center"
							style={{
								height: '100%',
								minWidth: '100px',
								justifyContent: compact ? 'center' : 'space-between',
								width: compact ? 'fit-content' : '100%',
							}}
						>
							<Text variant="body1">
								{primaryText}
							</Text>
						</Layout>
					</td>
					{
						copy && (
							<td style={{width: '36px', height: '100%'}}>
								<IconButton onClick={onClick}>
									<CopyIcon />
								</IconButton>
							</td>
						)
					}
				</tr>
			</tbody>
		</table>
	);
}

CustomList.propTypes = {
	label: PropTypes.string,
	primaryText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	copy: PropTypes.bool,
	onClick: PropTypes.func,
	password: PropTypes.bool,
	compact: PropTypes.bool,
};

export default CustomList;
