import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import EmailTableRow from './EmailTableRow';
import {Divider, Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';

function EmailTable({form, onDelete}) {
	const [t] = useTranslation();
	const emailLenght = form.notificationEmails.length;

	const renderItem = useCallback((item, index) => {
		return (
			<EmailTableRow
				key={`item:${index}`}
				index={index}
				item={item}
				onDelete={onDelete}
			/>
		);
	}, [form, onDelete]);

	return (
		<Layout fullWidth>
			<Divider />
			<Layout
				fullWidth
				justifyContent="space-between"
				style={{padding: '0px 5px'}}
			>
				<Text variant="body1">{t('Email list')}</Text>
				<Text>{`${emailLenght}/10`}</Text>
			</Layout>
			<Table
				items={form.notificationEmails}
				renderItem={renderItem}
				variant="compact"
				fullWidth
			>
				<Table.Row>
					<Table.Cell>{t('Email')}</Table.Cell>
					<Table.Cell width={45}>{t('Delete')}</Table.Cell>
				</Table.Row>
			</Table>
		</Layout>
	);
}

EmailTable.propTypes = {
	form: PropTypes.object,
	onDelete: PropTypes.func,
};

export default EmailTable;
