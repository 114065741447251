export const sliceFullName = (fullName, getFirstPart) => {
	const nameParts = fullName.split(' ');

	if (getFirstPart) {
		// Return the first part of the name
		return nameParts[0];
	} else {
		// Return the last part of the name
		return nameParts[nameParts.length - 1];
	}
};
