export const storkundeListe = [
	{
		name: 'Polanka Alvarado',
		amount: 203,
		customers: [
			{
				kunde: 'Den store AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fiki reiser AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Øl huset AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Top utøver AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Svong gull AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Bare sølv AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Turist i Norge AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Chip fabrikken AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'Fisk fisk og bjørn AS',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Glenn Martiniussen',
		amount: 145,
		customers: [
			{
				kunde: 'BrumBrum bil AS',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'teleTek AB',
				antall: 1,
				account: 1,
			},
			{
				kunde: 'TempTemp fast ansettelse AS',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Anita Hatlestad',
		amount: 81,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Najeb Sadiq',
		amount: 69,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Sara Bjindal',
		amount: 54,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Gina Gjermundnes',
		amount: 45,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Glenn Olsen',
		amount: 45,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Anne Louise',
		amount: 17,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Daniel Tallaksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Linn Pettersen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Najeb Mahmood',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Live Krok',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Anne Danielsen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Linn Petteren',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Susanne Eriksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Susanne Eriksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Susanne Eriksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Susanne Eriksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
	{
		name: 'Susanne Eriksen',
		amount: 15,
		customers: [
			{
				kunde: 'Kunde 6',
				antall: 1,
				account: 1,
			},
		],
	},
];
