import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useBMOStore from 'common/stores/BMO';

import {Layout, Button} from '@phonectas/unifon-ui-kit';

function SimpleUploader({id, onUploadOk}) {
	const [t] = useTranslation();
	const fileInputRef = useRef();
	const [files, setFiles] = useState([]);
	const {postAttachments, getTicketId} = useBMOStore();

	const handleAddFiles = useCallback((event) => {
		const newFiles = Array.from(event.target.files);
		newFiles.forEach((file) => {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					resolve(reader.result);
				};
				reader.onerror = reject;
				reader.readAsDataURL(file);
			});
		});
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
	}, [files]);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const onUploadFiles = useCallback(async () => {
		try {
			const formData = new FormData();
			files.forEach((file) => {
				formData.append('files', file);
			});
			await postAttachments(id, formData, () => {
				setFiles([]);
				if (onUploadOk) {
					getTicketId(id);
				}
			});
		} catch (error) {
			console.log(error);
		}
	}, [files]);

	return (
		<Layout>
			<Button
				variant="primary"
				onClick={handleButtonClick}
			>
				{t('Add files')}
			</Button>
			<Button
				variant="primary"
				color="success"
				disabled={files.length === 0}
				onClick={onUploadFiles}
			>
				{t('Upload files')}
			</Button>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleAddFiles}
				style={{display: 'none'}}
				multiple
			/>
			<Layout fullWidth nowrap>
				{
					files.map((file, index) => (
						<p key={index}>{file.name}</p>
					))
				}
			</Layout>
		</Layout>
	);
}

SimpleUploader.propTypes = {
	id: PropTypes.string,
	onUploadOk: PropTypes.bool,
};
export default SimpleUploader;
