import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import UsersTableRow from './UsersTableRow';
import {LIMIT_PAYLOAD} from 'common/constants';
import TableDialog from '../TableDialog';
import UserDialogContent from './dialogContent/UserDialogContent';
import FilterOnIcon from 'common/components/icons/FilterOnIcon';
import FilterOffIcon from 'common/components/icons/FilterOffIcon';
import {StyledIconButton} from 'common/components/styled';

function UsersTable({search, id}) {
	const [t] = useTranslation();
	const {fetchUsers, setActiveRow} = useThreeSixtyStore();
	const activeRow = useThreeSixtyStore((state) => state.activeRow);
	const {users, totalCount, accountNotFound} = useThreeSixtyStore((state) => ({
		users: state.accountTables?.users,
		totalCount: state.accountTables?.users?.total_count,
		accountNotFound: state.accountNotFound,
	}));
	const [isFilter, setIsFilter] = useState(false);
	const handleFilterChange = useCallback(() => {
		setIsFilter((prev) => !prev);
	}, []);
	const usersData = users?.customer_users;

	const [showDialog, setShowDialog] = useState(false);
	const onHideDialog = useCallback(() => {
		setShowDialog(false);
	}, [showDialog]);
	const handleRowClick = useCallback((item) => {
		setActiveRow(item);
		setShowDialog(true);
	}, [setActiveRow, setShowDialog]);

	const onLoadMore = useCallback((size) => {
		if (totalCount <= size) return;
		fetchUsers(size + LIMIT_PAYLOAD, id);
	}, [id, totalCount]);


	const renderItem = useCallback((item, index) => (
		<UsersTableRow
			key={index}
			data={item}
			onClick={handleRowClick}
			showDialog={setShowDialog}
		/>
	), [handleRowClick]);

	useEffect(() => {
		if (accountNotFound) return;

		const isAccountIdMatched = usersData?.length > 0 && id === usersData[0]?.account_id;
		const isUsersFetched = users !== null && isAccountIdMatched;
		const isUsersFilterMatched = users?.filters?.account_id === id && usersData === null;

		if (isUsersFetched || isUsersFilterMatched) return;

		fetchUsers(LIMIT_PAYLOAD, id);
	}, [users, id, usersData, accountNotFound]);

	if (!users || !users?.customer_users) {
		return (
			<StyledSectionDiv $endDiv $fullHeight>
				<Layout
					fullHeight
					fullWidth
					alignItems="center"
					justifyContent="center"
				>
					<Text>{t('No data')}</Text>
				</Layout>
			</StyledSectionDiv>
		);
	}

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={usersData}
				renderItem={(item, index) => renderItem(item, index)}
				virtualized
				onScrollDown={onLoadMore}
			>
				<Table.Row>
					<Table.Cell width={140}>{t('Name')}</Table.Cell>
					<Table.Cell width={90}>{t('Number')}</Table.Cell>
					<Table.Cell
						width={42}
					>
						{t('Email')}
					</Table.Cell>
					<Table.Cell style={{fontSize: '12px'}}>
						<span style={{alignItems: 'center', display: 'flex'}}>
							{t('Status')}

							<StyledIconButton
								onClick={handleFilterChange}
								width={24}
								height={22}
								style={{marginLeft: '5px'}}
							>
								{
									isFilter ?
										<FilterOnIcon /> :
										<FilterOffIcon />
								}
							</StyledIconButton>
						</span>
					</Table.Cell>
					{/* <Table.Cell width={80}>{t('Binding')}</Table.Cell> */}
					<Table.Cell>{t('SIM type')}</Table.Cell>
					<Table.Cell>{t('Used')}</Table.Cell>
					<Table.Cell>{t('Product')}</Table.Cell>
					<Table.Cell>{t('PIN / PUK')}</Table.Cell>
				</Table.Row>
			</Table>
			<TableDialog show={showDialog} onClose={onHideDialog} data={activeRow}>
				<UserDialogContent data={activeRow} onClose={onHideDialog}/>
			</TableDialog>
		</StyledSectionDiv>
	);
}

UsersTable.propTypes = {
	search: PropTypes.string,
	id: PropTypes.string.isRequired,
};

export default UsersTable;
