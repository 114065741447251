import {Record} from 'immutable';
import AuthService, {TOKEN_ALIAS} from 'services/auth';

/**
 * @class UserModel
 */
export default class UserModel extends Record({
	id: '',
	name: '',
	email: '',
	roles: [],
	fixed: '',
	mobile: '',
	number: '',
	picture: '',
	maxIdle: 0,
	customerId: '',
	customerName: '',
	catalogID: '',
	departmentName: '',
}) {
	/**
	 * @constructor
	 * @param {UserInfoType} props
	 */
	constructor(props) {
		super({
			id: props.catalog_id,
			name: props.name,
			email: props.email,
			roles: props.roles,
			fixed: props.fixed,
			mobile: props.mobile,
			number: props.number,
			picture: props.picture,
			maxIdle: props.max_session_idle_seconds,
			customerId: props.customer_id,
			customerName: props.customer_name,
			catalogID: props.catalog_id,
			departmentName: props.department_name,
		});
	}

	/**
	 * @type {string}
	 */
	get avatarSrc() {
		return `${AuthService.apiUrl}catalog/profileimage?u=${this.id}&s=128x128&c=${this.customerId}&h=${this.picture}`;
	}

	/**
	 * @param {string} catalogId
	 * @param {number} customerId
	 * @param {string} picture
	 * @return {string}
	 */
	static getAvatarUrl(catalogId, customerId, picture) {
		if (!catalogId || !customerId || !picture) return '';
		return `${AuthService.apiUrl}catalog/profileimage?u=${catalogId}&s=128x128&c=${customerId}&h=${picture}`;
	}

	/**
	 * @return {{cuid: [number]}}
	 */
	get impersonedData() {
		const token = localStorage.getItem(TOKEN_ALIAS);
		return AuthService.parseToken(token)?.imp;
	}
}
