import React from 'react';
import PropTypes from 'prop-types';

import {Box, CircularProgress, circularProgressClasses} from '@mui/material';
import {Layout} from '@phonectas/unifon-ui-kit';

Loader.protoType = {
	size: PropTypes.number,
};


export function Loader(size = 60) {
	return (
		<Box sx={{position: 'relative'}}>
			<CircularProgress
				variant="determinate"
				// sx={{color: '#dedcdc'}}
				sx={{color: 'transparent'}}
				size={50 || size}
				thickness={4}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				sx={{
					color: '#2C305E',
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
				}}
				size={50 || size}
				thickness={4}
			/>
		</Box>
	);
}


export default function LoaderBar() {
	return (
		<Layout
			fullHeight
			fullWidth
			style={{justifyContent: 'center', alignItems: 'center'}}
		>
			<Loader />
		</Layout>
	);
}

