import {useMediaQuery} from '@phonectas/unifon-ui-kit';

/**
 * @description Media query for ex. '(max-width:1599px)'
 * @param {string=} size
 * @return {boolean}
 */
export function useSmallScreen(size) {
	return useMediaQuery(size || '(max-width:1599px)');
}
