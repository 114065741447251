import sc from 'styled-components';

export const StyledCustomerGrid = sc.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	grid-template-rows: 40px auto 1fr;
	row-gap: 5px;
	column-gap: 20px;
	height: 100%;
	overflow: hidden;
	grid-auto-flow: row;
	grid-template-areas:
	"header header"
	"info right"
	"table right";

	@media (max-width: 1600px) {
		align-items: stretch;
		grid-template-rows: 40px 1fr 2fr;
		grid-template-areas:
			"header header"
			"info right"
			"table table";
	}

	@media (min-height: 2600px) {
		grid-template-rows: 40px 0.1fr 10fr;
	}
`;

export const StyledGridItemHeader = sc.div`
	grid-area: header;
`;

export const StyledGridItemCustomer = sc.div`
	grid-area: info;
	display: flex;
    flex-direction: column;
`;

export const StyledGridItemRight = sc.div`
	grid-area: right;
	overflow: hidden;
	display: flex;
    flex-direction: column;
	row-gap: 5px;

	@media (max-width: 1600px) {
		max-height: ;
	}
`;

export const StyledGridItemTables = sc.div`
	grid-area: table;
	overflow: hidden;
	height: 100%;
	display: flex;
    flex-direction: column;
`;

export const StyledSectionDiv = sc.div`
	box-sizing: border-box;
	width: 100%;
	display: ${({$flex}) => $flex ? 'flex' : 'block'};
	padding: ${({$noPadding}) => $noPadding ? 0 : 16}px;
	border-radius: ${({theme, $endDiv}) => {
		const radius = theme.borderRadius * 2;
		return $endDiv ? `${radius}px ${radius}px 0 0` : `${radius}px`;
	}};
	height: ${({$fullHeight}) => $fullHeight ? '100%' : 'auto'};
	background-color: ${({theme, $noBg}) => {
		return $noBg ? 'none' : theme.palette.white;
	}};
	@media (max-width: 1600px) {
	border-radius: ${({theme, $endDiv, $isSmall}) => {
		const radius = theme.borderRadius * 2;
		return $endDiv && $isSmall ? `${radius}px` : undefined;
	}};
	}
`;

export const StyledSectionDivHeight = sc.div`
	box-sizing: border-box;
	width: 100%;
	height: ${({$fullHeight}) => $fullHeight ? '100%' : 'auto'};
	display: ${({$flex}) => $flex ? 'flex' : 'block'};
	padding: ${({$noPadding}) => $noPadding ? 0 : 16}px;
	border-radius: ${({theme, $endDiv}) => {
		const radius = theme.borderRadius * 2;
		return $endDiv ? `${radius}px ${radius}px 0 0` : `${radius}px`;
	}};
	height: ${({$fullHeight}) => $fullHeight ? '100%' : 'auto'};
	background-color: ${({theme, $noBg}) => {
		return $noBg ? 'none' : theme.palette.white;
	}};
	@media (max-width: 1600px) {
	border-radius: ${({theme, $endDiv, $isSmall}) => {
		const radius = theme.borderRadius * 2;
		return $endDiv && $isSmall ? `${radius}px` : undefined;
	}};
	}
`;

export const StyledEventsTab = sc(StyledSectionDivHeight)`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 5px;
	position: ${({expandEvent}) => expandEvent ? 'absolute' : 'relative'};
	height: '100%';
	width: ${({expandEvent}) => expandEvent ? '40%' : 'auto'};
	right: ${({expandEvent}) => expandEvent ? '20px' : 'auto'};
	border-radius: ${({expandEvent}) => expandEvent ? '1px solid #e0e0e0' : 'none'};
	box-shadow: ${({expandEvent}) => expandEvent ? '0px 0px 10px 0px rgba(0, 0, 0, 0.1)' : 'none'};
`;
