import React, {useCallback, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import {useTranslation} from 'react-i18next';
import SortByCases from '../SortByCases';
import {SORT_BY, STATUS_FILTER} from '../../constants';
import useUserStore from 'common/stores/user';
// import useBMOStore from 'common/stores/BMO';

import SearchWidget from 'common/components/SearchWidget';
import {Layout, Table, Text, Checkbox} from '@phonectas/unifon-ui-kit';
import {StyledCenterCenterLayout} from 'common/styled';
import {useSmallScreen} from 'common/hooks/useScreenSize';
// import {LIMIT_PAYLOAD} from 'services/constants';


/**
 * @module containers/BMO/cases/CasesTable
 * @param  {Object}	data
 * @return {JSX.Element}
 * @constructor
 */
function CasesTable({data}) {
	const [t] = useTranslation();
	const user = useUserStore((state) => state.user);
	// const ticketList = useBMOStore((state) => state.BMO.ticketsList);
	// const dataSize = ticketList?.length;
	// const {fetchAllCases} = useBMOStore();
	const [query, setQuery] = useState('');
	const [sortByValue, setSortByValue] = useState(SORT_BY.OPEN);
	const [myCases, setMyCases] = useState(false);
	const navigate = useNavigate();
	const isSmallScreen = useSmallScreen('(max-width:1300px)');
	const onClick = useCallback((item) => {
		navigate(`/BMO/cases?id=${item.id}`);
	}, [onClick]);
	const onToggleMyCases = useCallback(() => {
		setMyCases((prev) => !prev);
	}, [myCases]);

	const clearSearch = useCallback(() => setQuery(''), [query]);
	const sortingFunctions = {
		[SORT_BY.ALL]: (a, b) => a.createdAt - b.createdAt,
		[SORT_BY.OPEN]: (a, b) => a.createdAt - b.createdAt,
		[SORT_BY.CLOSED]: (a, b) => a.createdAt - b.createdAt,
		[SORT_BY.ON_HOLD]: (a, b) => a.createdAt - b.createdAt,
		type: (a, b) => a.ticketType.localeCompare(b.ticketType),
	};

	const filteredData = useMemo(() => {
		const baseFilteredData = query === '' ? data : data?.filter(
			(item) =>
				(item?.id && item.id.toString().includes(query)) ||
				(item?.name && item.name.toLowerCase().includes(query)) ||
				(item?.reporter && item.reporter.toLowerCase().includes(query)) ||
				(item?.severity && item.severity.toLowerCase().includes(query)) ||
				(item?.status && item.status.toLowerCase().includes(query)) ||
				(item?.zendeskRef && item.zendeskRef.toLowerCase().includes(query)),
		);
		// Set sorting to "All" only if query is not empty
		const sortValue = query !== '' ? SORT_BY.ALL : sortByValue;

		// Filter by status if not "All"
		const filteredByStatus = sortValue === SORT_BY.ALL ? baseFilteredData :
			baseFilteredData.filter((item) => {
				if (STATUS_FILTER[SORT_BY.CLOSED].includes(item.status) && sortValue === SORT_BY.CLOSED) {
					return true;
				}
				if (STATUS_FILTER[SORT_BY.OPEN].includes(item.status) && sortValue === SORT_BY.OPEN) {
					return true;
				}
				if (STATUS_FILTER[SORT_BY.ON_HOLD].includes(item.status) && sortValue === SORT_BY.ON_HOLD) {
					return true;
				}
				return false;
			});
		// Filter by my cases if enabled
		const filteredByMyCases = myCases ?
			filteredByStatus.filter((item) => item.reporter.toLowerCase() === user?.name.toLowerCase()) :
			filteredByStatus;

		// Sort the filtered data
		const sortedData = filteredByMyCases.sort(sortingFunctions[sortValue]);

		return sortedData;
	}, [data, query, sortByValue, sortingFunctions]);

	// const onLoadMore = useCallback(() => {
	// 	fetchAllCases(dataSize.length + LIMIT_PAYLOAD);
	// 	console.log(dataSize);
	// 	console.log(LIMIT_PAYLOAD);
	// }, [dataSize]);

	const isDataInFilter = filteredData?.length === 0;
	const renderItem = useCallback((id, item, index) => (
		<Table.Row
			key={item.id}
			index={index}
			onClick={() => onClick(item)}
			selected={item.id === index}
		>
			<Table.Cell>{item?.id}</Table.Cell>
			<Table.Cell>{item?.name}</Table.Cell>
			<Table.Cell>{item?.status}</Table.Cell>
			<Table.Cell>{item?.severity}</Table.Cell>
			<Table.Cell>{item?.reporter}</Table.Cell>
			<Table.Cell>{item?.responsible}</Table.Cell>
			<Table.Cell>{item?.zendeskRef}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(item?.createdAt, DATE_FORMATS.DD_MM_YYYY)}</Table.Cell>
		</Table.Row>
	), [onClick]);

	return (
		<Layout fullHeight fullWidth>
			<Layout
				direction="row"
				fullWidth
				justifyContent="end"
				gap="xxl"
				alignItems="center"
			>
				<Layout direction="column">
					<SortByCases
						sortByValue={sortByValue}
						onSort={(value) => setSortByValue(value)}
					/>
				</Layout>
				<Layout direction="column" justifyContent="start">
					{
						user?.name && (
							<Checkbox
								label={t('My cases')}
								toggle
								big
								checked={myCases}
								onChange={onToggleMyCases}
							/>
						)
					}
				</Layout>
				<Layout direction="column">
					<SearchWidget
						onChange={setQuery}
						placeholder={t('Search...')}
						clearSearch={clearSearch}
						searchQuery={query}
					/>
				</Layout>
			</Layout>
			<Layout fullWidth nowrap fullHeight>
				{
					isDataInFilter ?
						<StyledCenterCenterLayout>
							<Text variant="h4">
								{/* {t('No cases in this catogory')} */}
								{t(`No cases available in this category`)}
							</Text>
						</StyledCenterCenterLayout> :
						<Layout grow fullWidth fullHeight direction="column" nowrap>
							<Table
								style={{paddingBottom: '20px'}}
								items={filteredData}
								renderItem={(item, index) => renderItem(item.id, item, index)}
								// virtualized
								fullHeight
								// onScrollDown={onLoadMore}
							>
								<Table.Row>
									<Table.Cell width={isSmallScreen ? 90 : undefined}>{t('ID')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 130 : undefined}>{t('name')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 110 : undefined}>{t('status')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 80 : undefined}>{t('severity')}</Table.Cell>
									<Table.Cell>{t('Reporter')}</Table.Cell>
									<Table.Cell>{t('responsible')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 85 : undefined}>{t('zendesk ref')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 95 : undefined}>{t('Created at')}</Table.Cell>
								</Table.Row>
							</Table>
						</Layout>
				}
			</Layout>
		</Layout>
	);
}

CasesTable.propTypes = {
	data: PropTypes.array,
};

export default React.memo(CasesTable);
