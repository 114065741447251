import {useEffect} from 'react';
import useUserStore from '../../common/stores/user';

/**
 * @return {null}
 * @constructor
 */
function Logout() {
	const {logout} = useUserStore();

	useEffect(() => {
		logout();
	}, [logout]);

	return null;
}

export default Logout;
