/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewSmsIcon({customSize = 32}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#F3F3F5"/>
			<path d="M21.334 10.667H10.6673C9.93094 10.667 9.33398 11.2639 9.33398 12.0003V20.0003C9.33398 20.7367 9.93094 21.3337 10.6673 21.3337H21.334C22.0704 21.3337 22.6673 20.7367 22.6673 20.0003V12.0003C22.6673 11.2639 22.0704 10.667 21.334 10.667Z" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M9.33398 12.667L16.0007 16.3337L22.6673 12.667" stroke="#9798A9" strokeWidth="1.5"/>
		</svg>

	);
}

NewSmsIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewSmsIcon;
