import sc from 'styled-components';
import {Layout, TextArea} from '@phonectas/unifon-ui-kit';

// import {EXPANDED_CARD_HEIGHT} from './components/ContactCardExpanded/styled';

export const StyledCatalogColumns = sc.div`
	width: 100%;
	flex-grow: 1;
	box-sizing: border-box;
`;

export const StyledDividerRow = sc.div`
	bottom: ${({theme}) => theme.spacing(4)}px;
	width: ${({theme, $width}) => $width - theme.spacing(5)}px;
	position: absolute;
`;

export const StyledHeaderContent = sc(Layout)`
	position: relative;
`;

export const StyledQueueCardTitle = sc(Layout)`
	cursor: pointer;
`;

export const StyledTelLink = sc.a`
	color: ${({theme}) => theme.palette.primary};
	text-decoration: none;
`;

export const StyledTextArea = sc(TextArea)`
	height: 250px;
	width: 450px;
`;

export const StyledLeftColumn = sc(Layout)`
	width: 450px;
`;

export const StyledRightColumn = sc(Layout)`
	width: 370px;
`;

export const StyledLeftMainColumn = sc(Layout)`
	min-width: 55%;
`;
