/**
 * @function alpha
 * @description Add alpha opacity to hex color
 * @param {string} color
 * @param {number} opacity
 * @return {string}
 */
export function alpha(color, opacity) {
	// coerce values so ti is between 0 and 1.
	const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
	return color + _opacity.toString(16).toUpperCase();
}

/**
 * @description Return segment color for chart
 * @param {number} length
 * @param {number} index
 * @return {string}
 */
export function getChartColor(length, index) {
	// return alpha('#48507B', 1 - (1 / length * index));
	return alpha('#48507B', 1 - index * 0.15);
}
