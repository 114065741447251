import sc, {keyframes} from 'styled-components';

import {Layout, Text} from '@phonectas/unifon-ui-kit';

const expandWidth = keyframes`
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
`;

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	20%, 100% {
		opacity: 1;
	}
`;

export const StyledUnifonWarningLayout = sc(Layout)`
	background-color: #EE5151;
	border-radius: ${({theme}) => theme.borderRadius * 2}px;
	padding: ${({$noPadding, theme}) => $noPadding ? 0 : theme.spacing(2, 4)};
	align-items: center;
	width: 100%;
	animation: ${expandWidth} 1s forwards, ${fadeIn} 1s forwards;
	animation-delay: ${({$delay}) => $delay}s;
	overflow: hidden;
	height: 50px;
	opacity: 0;
`;

export const StyledUnifonWarningText = sc(Text)`
	color: white;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
`;

export const StyledUnifonWarningDiv = sc(Layout)`
	animation: ${fadeIn} 1s forwards;
	animation-delay: ${({$delay}) => $delay}s;
	align-items: center;
	width: 100%;
	padding: 0px;
`;
