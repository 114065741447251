import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {IconButton, Table, Text, TrashBinIcon} from '@phonectas/unifon-ui-kit';
import {truncate} from 'lodash';

function EmailTableRow({index, item, onDelete}) {
	const handleDelete = useCallback(() => {
		onDelete(index);
	}, [onDelete, index]);

	return (
		<Table.Row
			index={index}
			key={index}
		>
			<Table.Cell>{truncate(item, {length: 38})}</Table.Cell>
			<Table.Cell>
				<Text>
					<IconButton
						color="error"
						onClick={handleDelete}
					>
						<TrashBinIcon />
					</IconButton>
				</Text>
			</Table.Cell>
		</Table.Row>
	);
}

EmailTableRow.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.string.isRequired,
	onDelete: PropTypes.func,
};

export default EmailTableRow;
