import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Layout, ListItem, Select, Text} from '@phonectas/unifon-ui-kit';
import {SORT_BY} from '../../constants';

SortByCases.propTypes = {
	onSort: PropTypes.func.isRequired,
	sortByValue: PropTypes.string,
};

/**
 * @callback SortByCases
 * @param {string} value
 * @return {void}
 */

/**
 * @module containers/BMO/components/SortBy
 * @param {SortByCasesCallback} onSort
 * @return {JSX.Element}
 * @constructor
 */
function SortByCases({onSort, sortByValue}) {
	const [t] = useTranslation();

	const handleSort = useCallback((value) => {
		onSort(value);
	}, [onSort]);

	const items = useMemo(
		() => [
			{key: SORT_BY.ALL, label: t('All')},
			{key: SORT_BY.OPEN, label: t('Open')},
			{key: SORT_BY.ON_HOLD, label: t('On hold')},
			{key: SORT_BY.CLOSED, label: t('Closed')},
			// {key: SORT_BY.RESOLVED, label: t('Resolved')},
			// {key: SORT_BY.REJECTED, label: t('Rejected')},
			// {key: SORT_BY.CANCELLED, label: t('Cancelled')},
			// {key: SORT_BY.INPROGRESS, label: t('InProgress')},
			// {key: SORT_BY.ACKNOWLEDGED, label: t('Acknowledged')},
		],
		[t],
	);

	return (
		<Layout nowrap gap="sm" alignItems="center">
			<Text color="textSecondary" truncate={1} variant="body1">
				{t('Sort by')}:
			</Text>
			<Select alt onChange={handleSort}>
				{items.map((item) => (
					<ListItem key={item.key} value={item.key} primaryText={item.label} selected={sortByValue === item.key}>
						{item.label}
					</ListItem>
				))}
			</Select>
		</Layout>
	);
}

export default React.memo(SortByCases);
