import React, {useCallback} from 'react';
import {
	Avatar,
	Divider,
	ExitIcon,
	Layout,
	ListItem,
	Popover,
	Radio,
	Text,
} from '@phonectas/unifon-ui-kit';
import useUserStore from 'common/stores/user';
import {useTranslation} from 'react-i18next';
import {LANG_LS_KEY} from 'common/i18n';

/**
 * @return {JSX.Element}
 * @constructor
 */
function UserWidget() {
	const [t, i18n] = useTranslation();
	const {user, logout} = useUserStore();

	const onChangeLanguage = useCallback(async (e) => {
		await i18n.changeLanguage(e.target.value);
		try {
			localStorage.setItem(LANG_LS_KEY, e.target.value);
		} catch (err) {
			console.error(err);
		}
	}, [i18n]);

	return (
		<Popover
			click
			content={
				<Layout direction="column" nowrap gap="sm">
					<Text variant="h4">{user?.name}</Text>
					<Divider />
					<Radio
						name="lang"
						value="en"
						label="English"
						checked={i18n.language === 'en'}
						onChange={onChangeLanguage}
					/>
					<Radio
						name="lang"
						value="no"
						label="Norsk"
						checked={i18n.language === 'no'}
						onChange={onChangeLanguage}
					/>
					<Divider />
					<ListItem icon={<ExitIcon />} primaryText={t('Logout')} color="primary" onClick={logout} />
				</Layout>
			}
		>
			<Layout><Avatar size="xxxxl" src={user?.picture} /></Layout>
		</Popover>
	);
}

export default React.memo(UserWidget);
