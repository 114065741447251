import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Tabs} from '@phonectas/unifon-ui-kit';

function CustomerTabsNav({activeTab, setActiveTab, amount}) {
	const [t] = useTranslation();

	const tabs = useMemo(() => [
		{value: '1', label: t('Users').toUpperCase()},
		{value: '2', label: t('Billing group').toUpperCase()},
		{value: '3', label: t('Contacts').toUpperCase()},
		{value: '4', label: `${t('INVOICES')} (${amount ? amount : 0})`},
		{value: '5', label: t('Zendesk').toUpperCase()},
		{value: '6', label: t('Bedriftsnett').toUpperCase()},
		{value: '7', label: t('Config / Forms').toUpperCase()},
	], [t, amount]);

	return (
		<Tabs
			style={{overflow: 'hidden'}}
			items={tabs}
			active={activeTab}
			onChange={setActiveTab}
		/>
	);
}

CustomerTabsNav.propTypes = {
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	amount: PropTypes.number,
};

export default CustomerTabsNav;
