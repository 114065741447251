import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import resBundle from 'i18next-resource-store-loader!./translations/index.js';

export const LANG_LS_KEY = 'cc_lang';
export const lang = localStorage.getItem(LANG_LS_KEY) || 'no';

/**
 * @param {string} defaultLocale
 */
async function initI18n(defaultLocale) {
	await i18n
		.use(initReactI18next)
		.init({
			resources: resBundle,
			lng: defaultLocale,
			fallbackLng: 'en',
			nsSeparator: '|',
			keySeparator: false,
			returnEmptyString: false,
		});
}

await initI18n(lang);

export default i18n;
