import React, {useCallback, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {fileSizeToMB, formatFileSize, MAX_FILE_SIZE} from 'utils/file';

import {Button, Layout, Text} from '@phonectas/unifon-ui-kit';

const acceptedFileTypes = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/webp',
	'image/svg+xml',
];

const maxSize = 10 * 1024 * 1024; // 10MB
const maxFiles = 5;

function UploadFiles({onChange, onClear}) {
	const [t] = useTranslation();
	const fileInputRef = useRef();
	const [uploadFiles, setUploadFiles] = useState([]);
	const [error, setError] = useState('');
	const numberOfFiles = uploadFiles?.length;
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		const tooLargeFiles = files.filter((file) => file.size > maxSize);
		if (tooLargeFiles.length) {
			const firstTooLargeFile = tooLargeFiles[0];
			setError((
				`File ${firstTooLargeFile.name} is too large 
					(${fileSizeToMB(firstTooLargeFile.size)} MB). Maximum allowed size is ${formatFileSize(MAX_FILE_SIZE)}`
			));
			return;
		}
		setUploadFiles((oldFiles) => {
			const updatedFiles = [...oldFiles, ...files];
			onChange('files', updatedFiles);
			return updatedFiles;
		});
	};
	const handleClear = useCallback(() => {
		setUploadFiles([]);
	}, []);

	useEffect(() => {
		if (onClear) {
			setUploadFiles([]);
		}
	}, [onClear]);

	const handleButtonClick = () => {
		fileInputRef.current.click();
		setError('');
	};

	return (
		<Layout fullWidth direction="column">
			<Layout direction="row">
				<Text>{t('Max file size')} {formatFileSize(MAX_FILE_SIZE)}</Text>
				<Text>{t(`Files: {{number}}`, {number: numberOfFiles})}/{maxFiles}</Text>
			</Layout>
			<input
				type="file"
				accept={acceptedFileTypes.join(',')}
				multiple
				onChange={handleFileChange}
				ref={fileInputRef}
				style={{display: 'none'}}
			/>
			{
				error ? <Text color="error">{error}</Text> : null
			}
			<Layout direction="row">
				<Button
					variant="secondary"
					onClick={handleButtonClick}
					disabled={numberOfFiles === maxFiles}
				>
					{t('Upload files')}
				</Button>
				<Button
					variant="secondary"
					color="error"
					onClick={handleClear}
					disabled={numberOfFiles === 0}
				>
					{t('Clear')}
				</Button>

			</Layout>
			{uploadFiles.length > 0 && (
				<Layout>
					<ul style={{padding: '0px', margin: '0px'}}>
						{uploadFiles.map((file, index) => (
							<li
								key={index}
								style={{listStyleType: 'none', margin: '5px 0px'}}
							>
								{file.name}
							</li>
						))}
					</ul>
				</Layout>
			)}
		</Layout>
	);
}

UploadFiles.propTypes = {
	onChange: PropTypes.func.isRequired,
	onClear: PropTypes.bool,
};

export default UploadFiles;
