import React from 'react';
import {Page} from '@phonectas/unifon-ui-kit';
import TicketForm from '../components/form/TicketForm';

const CreateTicketTab = () => {
	return (
		<Page.Section fullHeight>
			<TicketForm />
		</Page.Section>
	);
};

export default CreateTicketTab;
