import useUserStore from 'common/stores/user';
import {useEffect} from 'react';

/**
 * @return {JSX.Element}
 * @constructor
 */
function Login() {
	const {login} = useUserStore();

	useEffect(() => {
		login();
	}, [login]);

	return null;
}

export default Login;
