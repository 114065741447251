/* istanbul ignore file */
import {useState} from 'react';

/**
 * @description Return a dom element when it will appear. No need to test browser api.
 * @param {string} id
 * @return {HTMLElement}
 */
export function useDomObserver(id) {
	const app = document.getElementById('app');
	const target = document.getElementById(id);
	const [node, setNode] = useState(target);
	const callback = (mutationsList, observer) => {
		const targetNode = document.getElementById(id);
		if (targetNode) {
			setNode(targetNode);
			observer.disconnect();
		}
	};
	if (!target) {
		const observer = new MutationObserver(callback);
		observer.observe(app, {
			attributes: true,
			childList: true,
			subtree: true,
		});
	} else {
		return target;
	}
	return node;
}
