import React from 'react';
import useUserStore from 'common/stores/user';
// import {useTranslation} from 'react-i18next';
import UserInfo from 'common/components/UserInfo';

import {Layout, Page} from '@phonectas/unifon-ui-kit';
// import BmoUserTable from './bmoProfile/BmoUserTable';


function UserProfile() {
	const user = useUserStore((state) => state.user);
	// const [t] = useTranslation();

	return (
		<>
			<Page.Section>
				<Layout direction="column" nowrap autoOverflow alignItems="center">
					<UserInfo data={user} />
				</Layout>
			</Page.Section>
		</>
	);
}

export default UserProfile;
