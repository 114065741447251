import React from 'react';
import {StyledSectionDiv} from '../components/customer/styled';
import {Layout, Text} from '@phonectas/unifon-ui-kit';

function HistoryTab(props) {
	return (
		<>
			<div
				style={{
					display: 'grid',
					gap: '10px',
					gridTemplateColumns: 'repeat(auto-fit, minmax(700px, 1fr))',
					width: '100%',
				}}
			>
				<Layout fullWidth gap="sm">
					<span style={{maxHeight: '20px'}}>
						<Text variant="h5">
                        Customers
						</Text>
					</span>
					<StyledSectionDiv style={{direction: 'column'}}>
                        History / Favorites Customers
					</StyledSectionDiv>
				</Layout>
				<Layout fullWidth gap="sm">
					<span>
						<Text variant="h5">
                            Major customer responsible
						</Text>
					</span>
					<StyledSectionDiv style={{direction: 'column'}}>
                        History / Favorites Responsibles
					</StyledSectionDiv>
				</Layout>
			</div>
		</>
	);
}

export default HistoryTab;
