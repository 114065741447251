/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function BedriftsnettIcon({customSize = 28}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="17.4987" cy="3.88834" r="1.16666" fill="#9798A9"/>
			<circle cx="17.4987" cy="8.55533" r="1.16666" fill="#9798A9"/>
			<circle cx="17.4987" cy="13.2213" r="1.16666" fill="#9798A9"/>
			<circle cx="22.1667" cy="3.88834" r="1.16666" fill="#9798A9"/>
			<circle cx="22.1667" cy="8.55533" r="1.16666" fill="#9798A9"/>
			<circle cx="22.1667" cy="13.2213" r="1.16666" fill="#9798A9"/>
			<circle cx="22.1667" cy="17.8883" r="1.16666" fill="#9798A9"/>
			<circle cx="26.8327" cy="3.88834" r="1.16666" fill="#9798A9"/>
			<circle cx="26.8327" cy="8.55533" r="1.16666" fill="#9798A9"/>
			<circle cx="26.8327" cy="13.2213" r="1.16666" fill="#9798A9"/>
			<path d="M8.3214 18.2229C10.776 18.2229 12.7658 16.233 12.7658 13.7784C12.7658 11.3238 10.776 9.33398 8.3214 9.33398C5.8668 9.33398 3.87695 11.3238 3.87695 13.7784C3.87695 16.233 5.8668 18.2229 8.3214 18.2229Z" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
			<path d="M16.1005 24.89C15.1194 23.8724 13.9325 23.0531 12.6109 22.481C11.2892 21.9089 9.85981 21.5957 8.40796 21.5601C6.95612 21.5244 5.51162 21.7672 4.16066 22.2737C2.8097 22.7803 1.57998 23.5403 0.544922 24.5084" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
		</svg>

	);
}

BedriftsnettIcon.propTypes = {
	customSize: PropTypes.number,
};

export default BedriftsnettIcon;
