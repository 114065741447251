import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {Layout, IconButton, CloseIcon, TextField} from '@phonectas/unifon-ui-kit';

const DEBOUNCE_TIMEOUT = 400;

/**
 * @param {string} searchQuery
 * @param {string} placeholder
 * @param {function} onChange
 * @param {function} clearSearch
 * @return {JSX.Element}
 * @constructor
 */
function SearchWidget({searchQuery = '', placeholder = '', onChange, clearSearch, autoFocus = false}) {
	const [query, setQuery] = useState(searchQuery);
	const [isTyping, setIsTyping] = useState(false);

	const debouncedSearch = useCallback(debounce(onChange, DEBOUNCE_TIMEOUT), [onChange]);
	const showReset = isTyping && query !== '';
	const resetSearch = useCallback(() => {
		clearSearch();
		setQuery('');
	}, [onChange]);

	const onQueryChange = useCallback((event) => {
		const newQuery = event.target.value;
		if (query !== newQuery) {
			setQuery(newQuery);
			debouncedSearch(newQuery);
			setIsTyping(true);
		}
	}, [debouncedSearch, query]);

	const onBlurHandle = useCallback(() => {
		if (!query) {
			setIsTyping(false);
		}
	}, [query]);

	useEffect(() => {
		if (!isTyping) {
			setQuery(searchQuery);
			debouncedSearch(searchQuery);
		}
	}, [debouncedSearch, isTyping, searchQuery]);

	useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

	return (
		<Layout fullWidth nowrap alignItems="center" style={{position: 'relative'}}>
			<Layout fullWidth direction='row'>
				<TextField
					value={query}
					compact
					onChange={onQueryChange}
					placeholder={placeholder}
					fullWidth
					inputProps={{autoComplete: 'off'}}
					onBlur={onBlurHandle}
					autoFocus={autoFocus}
				/>
				{
					showReset ? <IconButton
						style={{position: 'absolute', right: '0'}}
						onClick={resetSearch}
						compact color="secondary"
					>
						<CloseIcon />
					</IconButton> : null
				}
			</Layout>
		</Layout>
	);
}

SearchWidget.propTypes = {
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	searchQuery: PropTypes.string,
	clearSearch: PropTypes.func,
	autoFocus: PropTypes.bool,
};

export default React.memo(SearchWidget);

