import React from 'react';
import {Text} from '@phonectas/unifon-ui-kit';

const COUNTRY_CODE = '+47';
const FULL_NUMBER_LENGTH = 11;

/**
 * @param {number} value
 * @return {string | number}
 */
export function abbreviateNumber(value) {
	if (value >= 1000) {
		const suffixes = ['', 'k', 'm', 'b', 't'];
		const suffixNum = Math.floor( ('' + value).length / 3 );
		let shortValue = '';
		for (let precision = 2; precision >= 1; precision--) {
			shortValue = parseFloat(
				(suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value)
					.toPrecision(precision),
			);
			const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z \d]+/g, '');
			if (dotLessShortValue.length <= 2) {
				break;
			}
		}
		if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
		return shortValue + suffixes[suffixNum];
	}
	return value;
}

/**
 * @description Convert bytes to abbreviated string (1024 -> 1 KB)
 * @param {number} bytes
 * @param {number=} decimals
 * @return {string}
 */
export function abbreviateBytes(bytes, decimals = 0) {
	if (bytes === 0) return '0 b';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 *
 * @param	{number|string}		value
 * @param	{string} 			separator
 * @return	{string}
 */
export function formatThousandsWithSeparator(value, separator = ' ') {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

/**
 *
 * @param	{number} 	value
 * @param	{number} 	decimal
 * @param	{string} 	separator
 * @return	{string}
 */
export function addDecimalsWithSeparator(value, decimal = 1, separator = ',') {
	return value.toFixed(decimal).replace('.', separator);
}

/**
 * @param {number} amount
 * @return {string}
 */
export function toLocalCurrency(amount) {
	return new Intl.NumberFormat('ja-JP', {style: 'currency', currency: 'NOK'}).format(amount);
}

/**
 * @param {String} number
 * @return {String}
 */
export function displayMobileNumber(number) {
	if (!number) {
		return '';
	}
	// +4721619302 -> 216 19 302
	return number.replace(/\D*(\d{2})\D*(\d{3})\D*(\d{2})\D*(\d{3})\D*/, '$2 $3 $4');
}

/**
 * @param {String} number
 * @return {String}
 */
function displayFixedNumber(number) {
	if (!number) {
		return '';
	}
	// +4721619302 -> 21 61 93 02
	return number.replace(/\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*/, '$2 $3 $4 $5');
}

/**
 * @param  {String}   phone
 * @return {String}
 */
export function phoneNumber(phone) {
	if (!phone) {
		return '';
	}
	if (phone.startsWith(COUNTRY_CODE)) {
		const withoutCountryCode = phone.replace(/\+47(.*)/, '$1');
		if (withoutCountryCode.length === FULL_NUMBER_LENGTH - COUNTRY_CODE.length) {
			if (/^([489])/.test(withoutCountryCode)) {
				return displayMobileNumber(phone);
			}
			if (/^([23567])/.test(withoutCountryCode)) {
				return displayFixedNumber(phone);
			}
		}
		return withoutCountryCode;
	}
	return phone;
}

export function formatNumber(numString) {
	// Convert the string to a number and check if it's over 5 digits
	if (!numString) {
		return 0;
	}
	if (Number(numString) >= 10000) {
		// If it's over 5 digits, format it with a comma
		return Number(numString).toLocaleString() + ',-';
	} else {
		return numString;
	}
}

export function displayInvoiceNumber(number) {
	if (number >= 0) {
		return <Text>{number}</Text>;
	} else {
		return <Text color="error">{number}</Text>;
	}
}
