import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Layout, ListItem, Select, Text, TextArea, TextField} from '@phonectas/unifon-ui-kit';

function SmsModule(props) {
	const [t] = useTranslation();
	const [text, setText] = useState('');
	const [template, setTemplate] = useState('');
	const [recipient, setRecipient] = useState('');
	return (
		<Layout direction="column" fullWidth gap="md">
			<Layout direction="row" justifyContent="end" fullWidth>
				<Text color="textSecondary">{`${text.length}/480`}</Text>
			</Layout>
			<Layout direction="row" fullWidth>
				<TextArea
					fullWidth
					disabled
					value={text}
					onChange={(e) => setText(e.target.value)}
					placeholder={t('Text')}
					style={{height: '120px'}}
				/>
			</Layout>
			<Layout direction="row" nowrap alignItems="end" fullWidth>
				<TextField
					fullWidth
					value={recipient}
					onChange={(e) => setRecipient(e.target.value)}
					label={t('Recipient')}
					disabled
				/>
				<Select
					fullWidth
					label={t('Template')}
					onChange={(e) => setTemplate(e.target.value)}
					value={template}
					disabled
				>
					<ListItem value="1"><Text>Template 1</Text></ListItem>
					<ListItem value="2"><Text>Template 2</Text></ListItem>
				</Select>
				<Button variant="primary" color="success" disabled>{t('Send')}</Button>
			</Layout>
		</Layout>
	);
}
export default SmsModule;
