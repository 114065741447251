import {useState, useEffect, useRef} from 'react';

/**
 * Custom hook to get the bounding client rect of a referenced element.
 *
 * @return {[React.RefObject, { top: number, right: number, bottom: number, left: number }]}
 *          An array containing the ref object and the bounding rect object.
 *
 * @example
 * import React from 'react';
 * import useBoundingClientRect from './useBoundingClientRect';
 *
 * const MyComponent = () => {
 *     const [sectionRef, boundingRect] = useBoundingClientRect();
 *
 *     return (
 *         <div ref={sectionRef}>
 *             <p>Top: {boundingRect.top}</p>
 *             <p>Right: {boundingRect.right}</p>
 *             <p>Bottom: {boundingRect.bottom}</p>
 *             <p>Left: {boundingRect.left}</p>
 *         </div>
 *     );
 * };
 *
 * export default MyComponent;
 */
const useBoundingClientRect = () => {
	const sectionRef = useRef(null);
	const [boundingRect, setBoundingRect] = useState({top: 0, right: 0, bottom: 0, left: 0});

	useEffect(() => {
		if (sectionRef.current) {
			const rect = sectionRef.current.getBoundingClientRect();
			setBoundingRect({
				top: rect.top,
				right: rect.right,
				bottom: rect.bottom,
				left: rect.left,
			});
		}
	}, []);

	return [sectionRef, boundingRect];
};

export default useBoundingClientRect;
