import sc from 'styled-components';
import {
	Button,
	IconButton,
	Layout,
	ListItem,
	Text,
	TextArea,
	Page,
} from '@phonectas/unifon-ui-kit';

export const StyledChartTooltip = sc(Layout)`
	${({theme}) => `
		background-color: ${theme.palette.white};
		border-radius: ${theme.borderRadius}px;
		box-shadow: 0px 12px 32px rgba(38, 38, 74, 0.3);
		padding: ${theme.spacing(2, 3)};
		z-index: 1000;
		position: fixed;
	`}
`;

export const StyledToolbar = sc(Layout)`
	min-height: 58px;
`;

export const StyledDeleteIconButton = sc(IconButton)`
	svg {
		transition: fill 300ms;
	}
	
	&:hover {
		svg {
			fill: ${({theme}) => theme.palette.error}
		}
	}
`;

export const StyledNestedListItem = sc(ListItem)`
	padding-left: ${({$nested}) => $nested ? 20 : 0}px;
`;
export const StyledButtonError = sc(Button)`
	background-color: ${({theme}) => theme.palette.error};
	color: #fff;
`;

export const StyledButtonSuccess = sc(Button)`
	background-color: ${({theme}) => theme.palette.success};
	color: #fff;
`;

export const StyledButtonGray = sc(Button)`
	border: solid 1px #9d9d9e;
	color: #9d9d9e;
`;

export const StyledNoSelectText = sc(Text)`
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

export const StyledCenterLayout = sc(Layout)`
	justify-content: center;
	margin: 50px 0;
`;

export const StyledCenterCenterLayout = sc(Layout)`
	justify-content: center;
	align-item: center;
	width: 100%;
	height: 50%;
	transform: translateY(47px);
`;

export const StyledPositionBtn = sc(Button)`
	position: absolute;
	right: -20px;
	top: 1px;
`;

export const StyledTextAreaDisabled = sc(TextArea)`
	min-height: 60px;
	border: solid 1px gray;
	color: #2C305E;
	background-color: #f4f4f8;
`;

export const StyledLayoutPaddingLeft = sc(Layout)`
	padding-left: 20px;
`;

export const StyledToolbarNew = sc(Layout)`
	margin: ${({theme}) => theme.spacing(4, 0, 3)};
	padding: ${({theme, $vPadding = 0, $hPadding = 0}) => theme.spacing($vPadding, $hPadding)};
	height: ${({theme}) => theme.spacing(7.5)}px;
	min-height: ${({theme}) => theme.spacing(7.5)}px;
	align-items: center;
	position: relative;
`;

export const StyledNoPaddingPage = sc(Page)`
	padding: 0px;
`;
