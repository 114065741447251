import sc from 'styled-components';

import {Layout} from '@phonectas/unifon-ui-kit';

export const StyledGrowLayout = sc(Layout)`
	transition: height 0.5s;
	-webkit-transition: height 0.5s;
	transition: 1.5s;
	overflow: hidden;
	// ${({activeTab}) => activeTab === '1' ? 'height: 100%;' : 'height: 0;'}

	&.hover {
		height: 80vh;
		z-index: 1;
	}
`;

export const StyledExpandableLayout = sc(Layout)`
	overflow: hidden;
	position: ${({show}) => show ? 'absolute' : 'relative'};
	background-color: white;
	padding: 16px;
	height: ${({dynamicHeight}) => dynamicHeight || '100%'};
	max-height: ${({dynamicHeight}) => dynamicHeight || '100%'};
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	flex-grow: 1;
	display: ${({show}) => show ? 'flex' : 'none'};
	z-index: ${({show}) => show ? 1 : 'auto'};
	top: 101px;
	right: 20px;
	max-width: 491px;
`;
