import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import Immutable from 'immutable';
import TicketFormModel from '../models/Ticket';
import {ALIAS_HEADER, ALIAS_HEADER_FORM_DATA} from 'services/constants';
import {showError, showSuccess} from '@phonectas/unifon-ui-kit';
import {getI18n} from 'react-i18next';
import {calculateUserCases} from 'services/bmo.utils';
import createDefaultTicketFormModel from '../models/createDefaultTicketFormModel';
import {LIMIT_PAYLOAD} from 'services/constants';
import {bmoApiUrl} from 'services/services';

const useBMOStore = create(devtools((set) => ({
	BMO: {
		ticketForm: new TicketFormModel(),
		ticketById: {},
		ticketsList: [],
		searchList: [],
		isLoading: false,
		activeUser: null,
		cases: {
			usersCases: {
				open: 0,
				closed: 0,
				held: 0,
				total: 0,
			},
			totalCases: {
				open: 0,
				closed: 0,
				held: 0,
				total: 0,
			},
		},
	},
	setTicketForm: (user) => set((state) => {
		state.BMO.ticketForm = createDefaultTicketFormModel(user);
	}),
	setActiveUser: (user) => set((state) =>
		({BMO: {...state.BMO, activeUser: user}}), false, 'BMO/setActiveUser'),
	fetchAllCases: async (limit = LIMIT_PAYLOAD) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/fetchAllCases');
			const currentState = (state) => state.BMO;
			const user = currentState.activeUser || '';
			const response = await fetch(`${bmoApiUrl}tickets/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({limit: limit}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => {
					const newState = {
						BMO: {
							...state.BMO,
							ticketsList: json,
							isLoading: false,
						},
					};
					const cases = calculateUserCases(json, user);
					newState.BMO.cases = cases;
					return newState;
				}, false, 'BMO/fetchAllCases/setState');
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
			}
		} catch (err) {
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
			console.error(err);
		}
	},
	searchCases: async (query) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/searchCases');
			const response = await fetch(`${bmoApiUrl}tickets/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({search_text: query}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					BMO: {
						...state.BMO,
						searchList: json,
						isLoading: false,
					},
				}));
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
			}
		} catch (err) {
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
			console.error(err);
		}
	},
	getStatuses: async () => {
		try {
			const response = await fetch(`${bmoApiUrl}tickets/statuses`, {
				headers: ALIAS_HEADER,
				method: 'GET',
			});
			const json = await response.json();
			return json;
		} catch (err) {
			console.error(err);
		}
	},
	postTicket: async (data, onPostSuccess) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/postTicket');
			const response = await fetch(`${bmoApiUrl}tickets`, {
				headers: ALIAS_HEADER_FORM_DATA,
				method: 'POST',
				body: data,
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showSuccess({
					message: getI18n().t(
						'Ticket {{id}} successfuly created',
						{
							id: json.id,
						}),
				});
				onPostSuccess && onPostSuccess();
				return response;
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						'Telenor API - Error-code: {{status}}', {
							status: response.status,
						},
					),
				});
			}
		} catch (err) {
			console.error(err);
			showError({
				message: getI18n().t(
					'Error - in Api Call',
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
	addNote: async (id, data, onAddNoteSuccess) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/addNote');
			const response = await fetch(`${bmoApiUrl}tickets/${id}/notes`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify(data),
			});
			if (response.ok) {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showSuccess({
					message: getI18n().t(
						'note successfuly created'),
				});
				onAddNoteSuccess && onAddNoteSuccess();
				return response;
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						'Telenor API - Error-code: {{status}}', {
							status: response.status,
						},
					),
				});
			}
		} catch (err) {
			showError({
				message: getI18n().t(
					`Error - in Api Call ${err}`,
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
	updateNote: async (caseId, noteId, data, onAddNoteSuccess) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/updateNote');
			const response = await fetch(`${bmoApiUrl}tickets/${caseId}/notes/${noteId}`, {
				headers: ALIAS_HEADER,
				method: 'PUT',
				body: JSON.stringify({text:	data}),
			});
			const json = await response.json();
			if (response.ok) {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showSuccess({
					message: getI18n().t(
						'note successfuly updated'),
				});
				onAddNoteSuccess && onAddNoteSuccess();
				return response;
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						'Telenor API - Error-code: {{status}}', {
							status: json.message,
						},
					),
				});
				console.log(response);
			}
		} catch (err) {
			showError({
				message: getI18n().t(
					`Error - in Api Call ${err}`,
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
	getTicketId: async (id, onFetchOk, onError) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/getTicketId');
			const response = await fetch(`${bmoApiUrl}tickets/${id}`, {
				headers: ALIAS_HEADER,
				method: 'GET',
			});
			if (response.ok) {
				const data = await response.json();
				set((state) => ({
					BMO: {
						...state.BMO,
						ticketById: data,
						isLoading: false,
					},
				}), false, 'BMO/getTicketId/setState');
				onFetchOk && onFetchOk();
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						'Telenor API - id not found: {{id}}', {
							id: id,
						},
					),
				});
				onError && onError();
			}
		} catch (err) {
			console.error(err);
			showError({
				message: getI18n().t(
					'Error - in Api Call',
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
	postAttachments: async (id, data, onPostSuccess) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/postAttachments');
			const response = await fetch(`${bmoApiUrl}tickets/${id}/attachments`, {
				headers: ALIAS_HEADER_FORM_DATA,
				method: 'POST',
				body: data,
			});
			const json = await response.json();
			if (response.ok) {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showSuccess({
					message: getI18n().t(
						'Attachment successfuly sent'),
				});
				onPostSuccess && onPostSuccess();
				return response;
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						json.message,
					),
				});
			}
		} catch (err) {
			showError({
				message: getI18n().t(
					'Error - in Api Call',
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
	postTicketWithAttach: async (data, onPostSuccess) => {
		try {
			set((state) => ({BMO: {...state.BMO, isLoading: true}}), false, 'BMO/postTicket');
			const response = await fetch(`${bmoApiUrl}tickets/withattachments`, {
				headers: ALIAS_HEADER_FORM_DATA,
				method: 'POST',
				body: data,
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showSuccess({
					message: getI18n().t(
						'Ticket {{id}} successfuly created',
						{
							id: json.id,
						}),
				});
				onPostSuccess && onPostSuccess();
				return response;
			} else {
				set((state) => ({BMO: {...state.BMO, isLoading: false}}));
				showError({
					message: getI18n().t(
						'Telenor API - Error-code: {{status}}', {
							status: response.status,
						},
					),
				});
			}
		} catch (err) {
			console.error(err);
			showError({
				message: getI18n().t(
					'Error - in Api Call',
				),
			});
			set((state) => ({BMO: {...state.BMO, isLoading: false}}));
		}
	},
})), {
	name: 'BMO store',
	serialize: {
		immutable: Immutable,
	},
});

export default useBMOStore;
