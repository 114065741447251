/* eslint-disable no-empty */
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import {ROUTES} from 'common/constants';
// import {showError} from '@phonectas/unifon-ui-kit';

export const TOKEN_ALIAS = '__auth__';
/**
 * @class AuthService
 */
class AuthService {
	/**
	 * @constructor
	 */
	constructor() {
		this.checkState()?.then(/* do nothing */);
	}

	/**
	 * @method
	 * @return {string}
	 */
	get apiUrl() {
		let url = '';
		switch (window.location.hostname) {
		// preprod
		case 'ks.pp.unifon.no':
			url = 'https://bnapi.pp.unifon.no/bnapi/v1/';
			break;
		// Prod
		case 'ks.unifon.no':
			url = 'https://bnapi.unifon.no/bnapi/v1/';
			break;
		// Localhost
		case 'localhost':
			url = 'https://bnapi.test.unifonip.no/bnapi/v1/';
			break;
		// Staging
		case 'ks.staging.unifon.no':
			url = 'https://bnapi.test.unifonip.no/bnapi/v1/';
			break;
		}
		return url;
	}

	/**
	 * @return {Promise<void>}
	 */
	checkState() {
		const urlParams = new URLSearchParams(location.search);
		const code = urlParams.get('code');
		const state = urlParams.get('state');

		if ((!localStorage.getItem(TOKEN_ALIAS) || !this.isValidToken()) && !code && !state) {
			return this.login();
		}
		if (code && state) {
			return this.authenticate(code, state);
		}
		this.checkToken().then(/* do nothing */);
	}

	/**
	 * @return {boolean}
	 */
	isValidToken() {
		try {
			const token = localStorage.getItem(TOKEN_ALIAS);
			const expireAt = this.parseToken(token)?.exp * 1000;
			// Set the expiration timestamp like 1 minute back, so we will actual renew
			// the token before it expires.
			// The websocket will immediately stop transmitting when the token expires,
			// so to not lose any messages, we should renew it just before.
			const minuteAhead = add(new Date(), {minutes: 1});
			return Boolean(token && expireAt) && isAfter(expireAt, minuteAhead);
		} catch (e) {
			return false;
		}
	}

	/**
	 * @method
	 */
	async login() {
		try {
			const response = await fetch(`${this.apiUrl}session/login`, {
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});

			if (response.ok) {
				const payload = await response.json();
				location.href = payload.redirect_url;
			}
		} catch (e) {}
	}

	/**
	 * @return {Promise<void>}
	 */
	async logout() {
		try {
			const response = await fetch(`${this.apiUrl}session/logout`, {
				headers: {
					'Content-Type': 'application/json',
					'authorization': `Bearer ${localStorage.getItem(TOKEN_ALIAS)}`,
				},
				credentials: 'include',
			});
			if (response.ok) {
				const json = await response.json();
				window.location.href = json.redirect_url;
				localStorage.removeItem(TOKEN_ALIAS);
			}
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * @param {string} code
	 * @param {string} state
	 * @return {Promise<void>}
	 */
	async authenticate(code, state) {
		try {
			const response = await fetch(`${this.apiUrl}session/authenticate?state=${state}&code=${code}`, {
				headers: {
					'Content-Type': 'application/json',
					'authorization': `Bearer ${localStorage.getItem(TOKEN_ALIAS)}`,
				},
				credentials: 'include',
			});

			if (response.ok) {
				const payload = await response.json();

				if (payload.token) {
					localStorage.setItem(TOKEN_ALIAS, payload.token);
					location.href = '/' + ROUTES.CCDASHBOARD;
					// location.href = '/' + ROUTES.BMO_DASHBOARD;
				}
			} else {
				console.error(await response.json());
			}
		} catch (e) {}
	}

	/**
	 * @return {Promise<UserInfoType>}
	 */
	async getUser() {
		try {
			const token = localStorage.getItem(TOKEN_ALIAS);
			const response = await fetch(`${this.apiUrl}session/info`, {
				headers: {
					'Content-Type': 'application/json',
					'authorization': `Bearer ${token}`,
				},
			});
			if (response.ok) {
				return await response.json();
			} else {
				console.error(await response.json());
			}
		} catch (e) {}
	}

	/**
	 * @param {string} token
	 * @return {*}
	 */
	parseToken(token) {
		if (!token) return false;
		try {
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split('')
					.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
					.join(''),
			);
			return JSON.parse(jsonPayload);
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * @method
	 * @description Recursively check token
	 */
	async checkToken() {
		try {
			/**
			 * @param {number} ms
			 * @return {Promise<void>}
			 */
			// eslint-disable-next-line no-inner-declarations
			async function sleep(ms) {
				return await new Promise((resolve) => setTimeout(resolve, ms));
			}
			/**
			 * @param {function} isValid
			 * @param {function} refresh
			 * @description Recursive generator to check token validity
			 */
			// eslint-disable-next-line no-inner-declarations
			async function* check(isValid, refresh) {
				while (true) {
					await sleep(60000);
					if (!isValid()) refresh();
					yield* check(isValid, refresh);
				}
			}

			const gen = check(this.isValidToken.bind(this), this.refreshToken.bind(this));
			await gen.next();
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * @return {Promise<void>}
	 */
	async refreshToken() {
		const response = await fetch(`${this.apiUrl}session/refresh`, {
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			method: 'POST',
			body: JSON.stringify({
				token: localStorage.getItem(TOKEN_ALIAS),
			}),
		});

		if (response.ok) {
			const {token} = await response.json();
			localStorage.setItem(TOKEN_ALIAS, token);
		}
	}
}

export default new AuthService();
