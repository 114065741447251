import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {AutoSuggest, Button, ClearIcon, Dialog, IconButton, Layout, ListItem, SearchIcon, Text} from '@phonectas/unifon-ui-kit';
import {debounce} from 'lodash';

function ChangeRespDialog({show, title, onClose, onOk, currentResp}) {
	const [t] = useTranslation();
	const {responsibleSearch, resetQueryResults, delCustomerResp} = useThreeSixtyStore();
	const data = useThreeSixtyStore((state) => state.queryResaults);
	const accountId = useThreeSixtyStore((state) => state.activeAccount?.id);
	const [selectedValue, setSelectedValue] = useState('');
	const onChangeValue = useCallback((value) => {
		if (!value) {
			setSelectedValue('');
			resetQueryResults();
			return;
		} else {
			setSelectedValue(value);
			debouncedSearch(value);
		}
	}, [debouncedSearch]);

	const debouncedSearch = useCallback(
		debounce((text) => {
			responsibleSearch(text);
		}, 400),
		[responsibleSearch],
	);
	const handleClearInput = useCallback(() => {
		setSelectedValue('');
		resetQueryResults();
	}, []);

	const value = useMemo(() => {
		if (!selectedValue) return '';
		if (selectedValue?.firstname && selectedValue?.lastname) {
			return selectedValue.firstname + ' ' + selectedValue.lastname;
		}
	}, [selectedValue]);

	const onDataSelect = useCallback((value) => {
		const item = data?.entries.find((entry) => entry.catalog_id === value);
		setSelectedValue({
			firstname: item?.firstname,
			lastname: item?.lastname,
			catalog_id: item?.catalog_id,
		});
	}, [data]);

	const renderOption = useCallback(
		({index, style}) => {
			const item = data?.entries[index] || {};
			return (
				<ListItem
					style={style}
					value={item?.catalog_id}
					color="primary"
					onClick={onDataSelect}
					primaryText={<Text variant="h5">{`${item?.firstname} ${item?.lastname}`}</Text>}
					secondaryText={item?.department_name}
				/>
			);
		},
		[data],
	);

	const handleClose = useCallback(() => {
		setSelectedValue('');
		resetQueryResults();
		onClose();
	}, [onClose, resetQueryResults]);

	const handleClickOk = useCallback(() => {
		const selectedItem = data.entries.find((item) => item.catalog_id === selectedValue.catalog_id);
		if (selectedItem) {
			// eslint-disable-next-line camelcase
			const {catalog_id, firstname, lastname} = selectedItem;
			// const {catalog_id, entra_id, firstname, lastname} = selectedItem;
			onOk(catalog_id, firstname, lastname, () => {
				setSelectedValue('');
				resetQueryResults();
				onClose();
			});
		}
	}, [selectedValue, data, onOk, onClose, resetQueryResults]);

	const handleDelete = useCallback(() => {
		delCustomerResp(accountId, () => {
			setSelectedValue('');
			resetQueryResults();
			onClose();
		});
	}, [accountId]);

	return (
		<Dialog
			show={show}
			title={title}
			size="large"
			// closeOnOverlayClick
			closeOnEscape
			onClose={handleClose}
			onOk={handleClickOk}
			okButtonLabel={t('Save')}
			closeButtonLabel={t('Close')}
			customActions={
				<Layout
					direction="row"
					justifyContent="space-between"
					gap="xs"
					fullWidth
					alignItems="center"
				>
					<Layout alignItems="center">
						<Button
							onClick={handleClickOk}
							variant="primary"
							color="success"
							disabled={!value}
						>
							{t('Save')}
						</Button>
						<Button
							onClick={handleClose}
							compact
							color="secondary"
						>
							{t('Close')}
						</Button>
					</Layout>
					<Layout>
						<Button
							onClick={handleDelete}
							color="error"
							variant="secondary"
						>
							{t('Delete')}
						</Button>
					</Layout>
				</Layout>
			}
		>
			<Layout fullWidth alignItems="center" gap="xs" nowrap>
				<Text variant="body">{`${t('Current responsible')}: ${currentResp}`}</Text>
			</Layout>
			<Layout style={{width: '50%'}}>
				<AutoSuggest
					fullWidth
					value={value}
					onChange={onChangeValue}
					itemSize={50}
					// disableFiltering
					filterBy={['firstname', 'lastname']}
					placeholder='Search'
					collection={data?.entries}
					renderItem={renderOption}
					maxDropdownHeight={300}
					inputProps={{autoFocus: 'on'}}
					endAdornment={
						<IconButton>
							{!value ? <SearchIcon /> : <ClearIcon onClick={handleClearInput}/>}
						</IconButton>
					}
				/>
			</Layout>
		</Dialog>
	);
}

ChangeRespDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	currentResp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default React.memo(ChangeRespDialog);
