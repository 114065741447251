/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewTeamsIcon({customSize = 28}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_22003_49549)">
				<path d="M24.1114 11.667H26.5381C26.6278 11.6672 26.7163 11.6876 26.7971 11.7267C26.8779 11.7657 26.9489 11.8224 27.0048 11.8925C27.1475 12.0406 27.2258 12.2391 27.2226 12.4448V18.4181C27.2338 19.6917 26.7897 20.9274 25.9703 21.9025C25.5987 22.3448 25.136 22.7016 24.6139 22.9486C24.0917 23.1955 23.5224 23.3269 22.9448 23.3337C22.4545 23.3303 21.9691 23.2353 21.5137 23.0537" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M24.8889 6.61144C24.8889 6.99601 24.7749 7.37195 24.5612 7.69171C24.3475 8.01147 24.0439 8.2607 23.6886 8.40787C23.3333 8.55504 22.9423 8.59355 22.5651 8.51852C22.1879 8.44349 21.8415 8.2583 21.5695 7.98637C21.2976 7.71443 21.1124 7.36796 21.0374 6.99078C20.9623 6.61359 21.0008 6.22263 21.148 5.86733C21.2952 5.51203 21.5444 5.20835 21.8642 4.99469C22.1839 4.78103 22.5599 4.66699 22.9444 4.66699C23.4601 4.66699 23.9547 4.87185 24.3194 5.23651C24.684 5.60116 24.8889 6.09574 24.8889 6.61144Z" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M16.334 11.667H20.6973C20.839 11.6684 20.979 11.6975 21.1096 11.7525C21.2406 11.8063 21.3596 11.8856 21.4596 11.9859C21.5598 12.0829 21.6392 12.1994 21.6929 12.3281C21.7488 12.4611 21.7778 12.6038 21.7785 12.7481V19.4992C21.7771 20.4128 21.5958 21.3171 21.2448 22.1605C20.8938 23.004 20.3801 23.77 19.7329 24.4148C18.4157 25.7213 16.6337 26.4514 14.7785 26.4448C13.8615 26.4441 12.9531 26.2672 12.1029 25.9237C11.2515 25.5753 10.4771 25.0626 9.82401 24.4148C9.16027 23.7772 8.6313 23.0128 8.26846 22.167C8.21401 22.0348 8.16734 21.9103 8.12068 21.7781L8.02734 21.5137" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M11.666 4.66671C11.7364 4.04682 11.9712 3.45701 12.3461 2.95835C12.7209 2.45968 13.2223 2.07028 13.7982 1.83046C14.3742 1.59064 15.0037 1.50911 15.6218 1.59433C16.2398 1.67955 16.8238 1.9284 17.3133 2.31514C17.8029 2.70187 18.1801 3.21243 18.4061 3.79394C18.632 4.37545 18.6984 5.00679 18.5984 5.62259C18.4984 6.23839 18.2356 6.81626 17.8373 7.29639C17.4389 7.77651 16.9194 8.14143 16.3327 8.35337" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M12.8411 7H1.95221C1.79424 7.00302 1.63842 7.03727 1.49374 7.10077C1.34906 7.16428 1.21838 7.25577 1.10921 7.37C1.00005 7.48423 0.914559 7.61892 0.857674 7.76633C0.80079 7.91374 0.773632 8.07094 0.777765 8.22889V19.7711C0.76938 20.0875 0.886563 20.3944 1.10371 20.6246C1.32086 20.8549 1.62031 20.9898 1.93665 21H12.8255C12.9835 20.997 13.1393 20.9627 13.284 20.8992C13.4287 20.8357 13.5594 20.7442 13.6685 20.63C13.7777 20.5158 13.8632 20.3811 13.9201 20.2337C13.977 20.0863 14.0041 19.9291 14 19.7711V8.22889C14.0084 7.9125 13.8912 7.60565 13.674 7.37539C13.4569 7.14512 13.1574 7.01016 12.8411 7Z" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M3.88867 10.8887H10.8887" stroke="#9798A9" strokeWidth="1.5"/>
				<path d="M7.38867 17.8887V10.8887" stroke="#9798A9" strokeWidth="1.5"/>
			</g>
			<defs>
				<clipPath id="clip0_22003_49549">
					<rect width={`${customSize}px`} height={`${customSize}px`} fill="white"/>
				</clipPath>
			</defs>
		</svg>

	);
}

NewTeamsIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewTeamsIcon;
