import React from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from 'services/auth';
import useUserStore from 'common/stores/user';
import {ROUTES} from 'common/constants';

ProtectedRoute.propTypes = {
	requireUserRole: PropTypes.string,
};

/**
 * @module ProtectedRoute
 * @param {React.ReactChild} children
 * @param {string} requireUserRole
 * @return {JSX.Element}
 * @constructor
 */
export default function ProtectedRoute({children, requireUserRole = ''}) {
	const {user} = useUserStore();
	const {setRequiredRoles} = useUserStore();
	if (user?.id && requireUserRole) {
		if (user.roles.includes(requireUserRole)) return children;
		setRequiredRoles(requireUserRole);
		return <Navigate to={ROUTES.UNAUTHORIZED_USER} />;
	}
	if (AuthService.isValidToken()) return children;
	return <Navigate to='/login' />;
}

ProtectedRoute.propTypes = {
	children: PropTypes.element,
};
