import {Record} from 'immutable';

/**
 * @class DevDocUserModel
 */
export default class DevDocUserModel extends Record({
	id: '',
	username: '',
	created_at: '',
	updated_at: '',
	passVerifyed: false,

}) {
	/**
	 * @constructor
	 * @param {UserInfoType} props
	 */
	constructor(props) {
		super({
			id: props.id,
			username: props.username,
			created_at: props.created_at,
			updated_at: props.updated_at,
		});
	}

	setPassVerifyed(passVerifyed) {
		return this.set('passVerifyed', passVerifyed);
	}
}
