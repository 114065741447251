import React, {useState, useEffect, useCallback} from 'react';
// import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

import {Button, CloseIcon, Divider, IconButton, Layout, ListItem, SearchIcon, Select, Text} from '@phonectas/unifon-ui-kit';
import Events from './components/Events';
import SearchWidget from 'common/components/SearchWidget';
import Portal from 'common/components/Portal';
import CommentEvent from './components/CommentEvent';
import {StyledGrowLayout} from './styled';
import {LIMIT_PAYLOAD} from 'common/constants';

function CustomerEvents() {
	const [t] = useTranslation();
	const {fetchAccountFeed} = useThreeSixtyStore();
	const accountFeed = useThreeSixtyStore((state) => state?.accountFeed);
	const activeAccount = useThreeSixtyStore((state) => state?.activeAccount);
	const [filter, setFilter] = useState('');
	const [currentLimit, setCurrentLimit] = useState(LIMIT_PAYLOAD);
	const [searchQuery, setSearchQuery] = useState('');
	const [showSearch, setShowSearch] = useState(false);
	const handleSearch = useCallback((query) => {
		setSearchQuery(query);
		setCurrentLimit(LIMIT_PAYLOAD);
	}, []);
	const handleSearchClose = useCallback(() => {
		setSearchQuery('');
		setShowSearch(false);
		setCurrentLimit(LIMIT_PAYLOAD);
		if (activeAccount?.id) {
			fetchAccountFeed(activeAccount?.id, filter, LIMIT_PAYLOAD);
		}
	}, [filter]);
	const onShowSearchBar = useCallback(() => setShowSearch(true), []);

	useEffect(() => {
		if (accountFeed?.total_count < currentLimit) {
			return;
		}
		if (activeAccount?.id) {
			fetchAccountFeed(activeAccount?.id, filter, currentLimit);
		}
	}, [searchQuery, currentLimit, filter]);

	const handleScroll = useCallback(() => {
		setCurrentLimit((prevLimit) => prevLimit + LIMIT_PAYLOAD);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [handleScroll]);

	const handleFilterChange = (value) => {
		setFilter(value);
		setCurrentLimit(LIMIT_PAYLOAD);
	};

	return (
		<StyledGrowLayout direction="column" gap="none" nowrap fullHeight fullWidth style={{padding: '0px'}}>
			<Layout fullWidth alignItems="center" gap="xs" nowrap>
				<CommentEvent activeCustomer={activeAccount?.id} />
			</Layout>
			<Layout
				direction="row"
				nowrap
				justifyContent="end"
				fullWidth
				style={{height: '46px'}}
				alignItems="center"
			>
				<div id="search_Events_Portal" />
				<Portal portalId="search_Events_Portal">
					<Layout nowrap alignItems="center">
						{
							showSearch ?
								(
									<>
										<SearchWidget
											onChange={handleSearch}
											placeholder={t('Search')}
											openSearch={setShowSearch}
											clearSearch={handleSearchClose}
											autoFocus
										/>
										<div>
											<IconButton onClick={handleSearchClose}>
												<CloseIcon />
											</IconButton>
										</div>
									</>
								) :
								(
									<Button onClick={onShowSearchBar} icon={<SearchIcon />} compact>
										{t('Search')}
									</Button>
								)
						}
					</Layout>
				</Portal>
				<Layout alignItems="center" gap="xs" nowrap>
					<Text variant="body1" color="textSecondary">{t('Type')}</Text>
					<Select
						value={filter}
						onChange={handleFilterChange}
						alt
					>
						<ListItem value="" primaryText={t('All')} />
						<ListItem value="BN_CALL_LOG" primaryText={t('Call')} />
						<ListItem value="sms" primaryText={t('SMS')} />
						<ListItem value="email" primaryText={t('Email')} />
						<ListItem value="rator" primaryText={t('Rator')} />
						<ListItem value="THREESIXTY_COMMENT" primaryText={t('Comments')} />
						<ListItem value="THREESIXTY_NEW_CUSTOMER_RESP" primaryText={t('360')} />
					</Select>
				</Layout>
			</Layout>
			<Divider style={{marginBottom: '15px'}}/>
			<Layout
				direction="row"
				fullHeight
				nowrap
				fullWidth
				justifyContent="space-between"
				style={{overflow: 'hidden'}}
			>
				<Events handleScrollAction={handleScroll} events={accountFeed}/>
			</Layout>
		</StyledGrowLayout>
	);
}

export default CustomerEvents;
