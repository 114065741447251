/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewWindowIcon({customSize = 18, color = 'white'}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_22003_49588)">
				<path d="M10.5 7.0003V10.0003C10.5001 10.393 10.3462 10.77 10.0713 11.0505C9.79647 11.331 9.4226 11.4924 9.03 11.5003H2.03C1.83051 11.5043 1.63222 11.4684 1.44676 11.3948C1.26129 11.3213 1.09237 11.2114 0.949893 11.0717C0.807412 10.932 0.694232 10.7653 0.616981 10.5813C0.53973 10.3974 0.49996 10.1998 0.5 10.0003V3.0003C0.49996 2.80077 0.53973 2.60323 0.616981 2.41926C0.694232 2.23529 0.807412 2.06858 0.949893 1.92889C1.09237 1.7892 1.26129 1.67935 1.44676 1.60575C1.63222 1.53216 1.83051 1.49631 2.03 1.5003H5.03" stroke={color} strokeMiterlimit="10"/>
				<path d="M11.0293 1L6.0293 6" stroke={color}/>
				<path d="M7.5 0.5H11.5V4.5" stroke={color}/>
			</g>
			<defs>
				<clipPath id="clip0_22003_49588">
					<rect width={`${customSize}px`} height={`${customSize}px`} fill={color}/>
				</clipPath>
			</defs>
		</svg>
	);
}

NewWindowIcon.propTypes = {
	customSize: PropTypes.number,
	color: PropTypes.string,
};
export default NewWindowIcon;
