import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
// import UserModel from 'common/models/user';
// import AuthService from 'services/auth';
import Immutable from 'immutable';
// import ThreeSixtyModel from '../models/threeSixty.model';
import {LIMIT_PAYLOAD, ALIAS_HEADER} from 'services/constants';
import {getI18n} from 'react-i18next';
import {showError, showSuccess} from '@phonectas/unifon-ui-kit';
import DevDocUserModel from '../models/devDocs/user.model';
import {devDocsApiUrl, devDocsApiUrlCreate} from 'services/services';

const useDevDocAdminStore = create(devtools((set) => ({
	isLoading: false,
	usersList: null,
	user: null,
	PasswordVerify: false,
	resetVerify: () => {
		set({PasswordVerify: false}, false, 'DEVDOCS/PasswordVerify');
	},
	fetchList: async (limit = LIMIT_PAYLOAD, searchText = '') => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrl}list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					limit: limit,
					search_text: searchText,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({usersList: json, isLoading: false}, false, 'DEVDOCS/User_list');
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
	createUser: async (user, password, onSuccess) => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrlCreate}`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					password: password,
					username: user,
				}),
			});
			const json = await response.json();
			if (response.ok) {
				showSuccess({
					message: getI18n().t(
						'User {{user}} successfuly created',
						{
							user: json.username,
						}),
				});
				onSuccess && onSuccess();
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
				showError({
					message: getI18n().t(
						'Error: {{status}}', {
							status: json.message,
						},
					),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
	userById: async (id) => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrl}${id}`, {
				headers: ALIAS_HEADER,
				method: 'GET',
			});
			if (response.ok) {
				const json = await response.json();
				const userModel = new DevDocUserModel({
					id: json.id,
					username: json.username,
					created_at: json.created_at,
					updated_at: json.updated_at,
				});
				set({user: userModel, isLoading: false}, false, 'DEVDOCS/user');
				// set({user: json, isLoading: false}, false, 'DEVDOCS/user');
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
	deleteUser: async (id, onSuccess) => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrl}${id}`, {
				headers: ALIAS_HEADER,
				method: 'DELETE',
			});
			const json = await response.json();
			if (response.ok) {
				set({isLoading: false, user: null}, false, 'DEVDOCS/isLoading');
				showSuccess({
					message: getI18n().t(
						'User {{user}} successfuly deleted',
						{
							user: json.username,
						}),
				});
				onSuccess && onSuccess();
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
				showError({
					message: getI18n().t(
						'User delete faild: {{status}}', {
							status: json.status,
						},
					),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
	resetPassword: async (id, password, onSuccess) => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrl}${id}/password/reset`, {
				headers: ALIAS_HEADER,
				method: 'PUT',
				body: JSON.stringify({
					password: password,
				}),
			});
			if (response.ok) {
				// const json = await response.json();
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
				showSuccess({
					message: getI18n().t(
						'New password set'),
				});
				onSuccess && onSuccess();
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
				showError({
					message: getI18n().t(
						'New password failed'),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
	verifyPassword: async (id, password, onSuccess) => {
		try {
			set({isLoading: true}, false, 'DEVDOCS/isLoading');
			const response = await fetch(`${devDocsApiUrl}${id}/password/verify`, {
				headers: ALIAS_HEADER,
				method: 'PUT',
				body: JSON.stringify({
					password: password,
				}),
			});
			const json = await response.json();
			if (json.success === true) {
				set({isLoading: false, PasswordVerify: json.success}, false, 'DEVDOCS/isLoading');
				showSuccess({
					message: getI18n().t('Verify success'),
				});
				onSuccess && onSuccess();
			} else {
				set({isLoading: false}, false, 'DEVDOCS/isLoading');
				showError({
					message: getI18n().t(
						'Verify unsuccessful'),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'DEVDOCS/isLoading');
			console.error(err);
		}
	},
})), {
	name: 'Dev docs admin store',
	serialize: {
		immutable: Immutable,
	},
});

// export const useDevDocAdmin = () => useThreeSixtyStore((state) => state.threeSixty);

export default useDevDocAdminStore;
