/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function EnlargeIcon({color = '#2c305e', size = 32}) {
	return (
		<svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_16_8)">
				<path d="M31.9517 0.84786C31.8913 0.707345 31.7706 0.590249 31.6256 0.531702C31.5532 0.502427 31.4747 0.484863 31.3962 0.484863H24.1509C23.8189 0.484863 23.5472 0.748328 23.5472 1.07034C23.5472 1.39235 23.8189 1.65582 24.1509 1.65582H29.9411L20.0996 11.1932C19.8641 11.4216 19.8641 11.7905 20.0996 12.0187C20.2204 12.1359 20.3713 12.1944 20.5283 12.1944C20.6853 12.1944 20.8362 12.1359 20.957 12.0246L30.7924 2.48134V8.09607C30.7924 8.41808 31.0641 8.68155 31.3962 8.68155C31.7283 8.68155 32 8.41808 32 8.09607V1.07034C32 0.994226 31.9819 0.918116 31.9517 0.84786Z" fill={color}/>
				<path d="M11.043 19.9754L1.20755 29.5186V23.9039C1.20755 23.582 0.935849 23.3185 0.603774 23.3185C0.271698 23.3185 0 23.582 0 23.9039V30.9296C0 31.0058 0.0181132 31.0819 0.0483019 31.1521C0.108679 31.2927 0.229434 31.4097 0.37434 31.4683C0.446792 31.4976 0.525283 31.5151 0.603774 31.5151H7.84906C8.18113 31.5151 8.45283 31.2517 8.45283 30.9296C8.45283 30.6076 8.18113 30.3441 7.84906 30.3441H2.05887L11.8943 20.8068C12.1298 20.5784 12.1298 20.2095 11.8943 19.9812C11.6589 19.7529 11.2785 19.747 11.043 19.9754Z" fill={color}/>
				<path d="M0.603774 8.68155C0.935849 8.68155 1.20755 8.41808 1.20755 8.09607V2.48134L11.043 12.0187C11.1638 12.1359 11.3147 12.1944 11.4717 12.1944C11.6287 12.1944 11.7796 12.1359 11.9004 12.0246C12.1358 11.7963 12.1358 11.4274 11.9004 11.1991L2.05887 1.65582H7.84906C8.18113 1.65582 8.45283 1.39235 8.45283 1.07034C8.45283 0.748328 8.18113 0.484863 7.84906 0.484863H0.603774C0.525283 0.484863 0.446792 0.502427 0.37434 0.531702C0.229434 0.590249 0.108679 0.707345 0.0483019 0.84786C0.0181132 0.918116 0 0.994226 0 1.07034V8.09607C0 8.41808 0.271698 8.68155 0.603774 8.68155Z" fill={color}/>
				<path d="M31.3962 23.3185C31.0642 23.3185 30.7925 23.582 30.7925 23.9039V29.5187L20.957 19.9754C20.7215 19.747 20.3412 19.747 20.1057 19.9754C19.8702 20.2038 19.8702 20.5726 20.1057 20.8009L29.9412 30.3442H24.151C23.8189 30.3442 23.5472 30.6077 23.5472 30.9297C23.5472 31.2517 23.8189 31.5152 24.151 31.5152H31.3962C31.4747 31.5152 31.5532 31.4976 31.6257 31.4684C31.7706 31.4098 31.8913 31.2927 31.9517 31.1521C31.9819 31.0819 32 31.0058 32 30.9297V23.9039C32 23.582 31.7283 23.3185 31.3962 23.3185Z" fill={color}/>
			</g>
			<defs>
				<clipPath>
					<rect width="32" height="32" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

EnlargeIcon.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number,
};

export default EnlargeIcon;
