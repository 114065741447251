import React from 'react';
import PropTypes from 'prop-types';

import {Text} from '@phonectas/unifon-ui-kit';
import {StyledLabel} from '../TextDivWithLabel/styled';

function TextWithLabel({text, label}) {
	return (
		<div>
			<StyledLabel>{label}</StyledLabel>
			<Text>{text}</Text>
		</div>
	);
}

TextWithLabel.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	label: PropTypes.string,
};

export default TextWithLabel;
