/* eslint-disable react/prop-types */
import React, {useCallback, useState} from 'react';
import {PropTypes} from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import useUserStore from 'common/stores/user';

import {EditIcon, Layout} from '@phonectas/unifon-ui-kit';
import {StyledNoSelectText, StyledPositionBtn, StyledLayoutPaddingLeft} from 'common/styled';
import TextDivWithLabel from 'common/components/TextDivWithLabel';
import EditNoteDialog from './EditNoteDialog';

function Notes({notesData, userId, caseId}) {
	const [t] = useTranslation();
	const {user} = useUserStore();
	const sortedNotesData = [...notesData].sort((a, b) => new Date(b.date) - new Date(a.date));
	const isCurrentUser = userId === user?.catalogID;
	const author = (note) => `${note?.authorFirstName} ${note?.authorLastName}`;
	const [currentNote, setCurrentNote] = useState(null);
	const [showEditNoteDialog, setShowEditNoteDialog] = useState(false);
	const onOpenEditNoteDialog = useCallback((note) => {
		setCurrentNote(note);
		setShowEditNoteDialog(true);
	}, []);
	const onCloseEditNoteDialog = useCallback(() => {
		setShowEditNoteDialog(false);
	}, []);

	return (
		<StyledLayoutPaddingLeft direction="column" fullWidth>
			{
				sortedNotesData?.map((note) => (
					<Layout
						fullWidth
						direction="column"
						key={note.id}
						style={{borderTop: 'solid 1px gray'}}
					>
						<Layout fullWidth style={{gap: '5px'}}>
							<table>
								<tbody>
									<tr>
										<td><StyledNoSelectText>{t('Author')}:</StyledNoSelectText></td>
										<td>{author(note)}</td>
									</tr>
									<tr>
										<td><StyledNoSelectText>{t('Company')}:</StyledNoSelectText></td>
										<td>{note?.author}</td>
									</tr>
									<tr>
										<td><StyledNoSelectText>{t('Date')}:</StyledNoSelectText></td>
										<td>{getDateByCustomFormat(note?.date, DATE_FORMATS.DD_MM_YYYY_HH_mm)}</td>
									</tr>
								</tbody>
							</table>
						</Layout>
						<div style={{position: 'relative', width: '100%'}}>
							{
								isCurrentUser && user?.name === author(note) && (
									<StyledPositionBtn
										onClick={() => onOpenEditNoteDialog(note)}
									>
										<EditIcon
											color="blue"
											size="md"
										/>
									</StyledPositionBtn>
								)
							}
							<TextDivWithLabel text={note.text}/>
						</div>
					</Layout>
				))
			}
			{
				currentNote && (
					<EditNoteDialog
						show={Boolean(showEditNoteDialog)}
						onClose={onCloseEditNoteDialog}
						note={currentNote}
						caseId={caseId}
					/>
				)
			}
		</StyledLayoutPaddingLeft>
	);
}

Notes.propTypes = {
	notesData: PropTypes.any,
	userId: PropTypes.string,
};

export default Notes;
