import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {Divider, Text} from '@phonectas/unifon-ui-kit';

CancelDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
};

function CancelDialog({id = '', handleChange, close, open = false}) {
	const [t] = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={close}
		>
			<DialogContent>
				<Text>
					{t('Confirm cancellation of {{id}}', {id: id})}
				</Text>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button fullWidth onClick={close}>{t('No')}</Button>
				<Button color="error" fullWidth onClick={handleChange} autoFocus>{t('Yes')}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CancelDialog;
