import sc from 'styled-components';

import {Layout} from '@phonectas/unifon-ui-kit';

export const StyledDescriptionLayout = sc(Layout)`
	height: fit-content;
	white-space: pre-line;
	word-break: break-word;
	overflow-wrap: break-word;
`;

export const StyledDurationDiv = sc.div`
	border-radius: 10px;
	background: #EFF0F4;
	padding: 5px;
`;
