/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewCallIcon({customSize = 32}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#F3F3F5"/>
			<path d="M20.5768 9.00202C20.1456 9.00202 19.7144 8.99947 19.2832 9.00202C18.998 9.00247 18.7241 9.11396 18.5197 9.31287C18.3152 9.51179 18.1962 9.7825 18.1879 10.0677C18.166 10.9335 18.0528 11.7841 17.7862 12.6094C17.6504 13.0316 17.7497 13.4008 18.0261 13.7467C18.4113 14.2309 18.4243 14.2821 18.792 14.7787C18.8061 14.8048 18.8142 14.8338 18.8156 14.8634C18.817 14.8931 18.8117 14.9227 18.8 14.95C17.9423 16.6003 16.5967 17.9418 14.95 18.8C14.9222 18.8101 14.8925 18.8139 14.8631 18.8109C14.8337 18.8079 14.8054 18.7983 14.7802 18.7828C14.2739 18.406 14.2088 18.3843 13.7086 17.9994C13.3931 17.7551 13.0404 17.6667 12.6654 17.7803C11.8133 18.0427 10.9281 18.1818 10.0366 18.1933C9.50204 18.201 9.01241 18.7002 9.00694 19.2341C8.99769 20.1393 8.99769 21.0445 9.00694 21.9497C9.01241 22.5176 9.52103 23.0004 10.1023 23C12.9867 23 15.5933 22.133 17.8877 20.3878C20.3753 18.4957 21.9738 16.0112 22.6861 12.964C22.9083 12.018 23.0132 11.0481 22.9987 10.0764C22.9899 9.50417 22.4981 9.0075 21.9267 9.00129C21.4754 8.99801 21.0259 9.00202 20.5768 9.00202Z" fill="#9798A9"/>
		</svg>

	);
}

NewCallIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewCallIcon;
