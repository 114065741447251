export const SORT_BY = {
	ALL: 'all',
	OPEN: 'open',
	HELD: 'held',
	ON_HOLD: 'onHold',
	CLOSED: 'closed',
	MYCASES: 'My Cases',
	RESOLVED: 'resolved',
	REJECTED: 'rejected',
	CANCELLED: 'cancelled',
	INPROGRESS: 'inProgress',
	ACKNOWLEDGED: 'Acknowledged',
	// ACKNOWLEDGED: 'acknowledged',
};

export const STATUS_FILTER = {
	[SORT_BY.CLOSED]: ['Cancelled', 'Closed', 'Resolved', 'Rejected'],
	[SORT_BY.OPEN]: ['Acknowledged', 'InProgress'],
	[SORT_BY.ON_HOLD]: ['Held', 'pending'],
};
