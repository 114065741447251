import TicketModel from './Ticket';

function createDefaultTicketFormModel(user) {
	return new TicketModel({
		description: '',
		severity: '',
		ticketType: 'Incident',
		name: '',
		customer: {
			reportedByName: user?.name || '',
			reportedByPhoneNumber: user?.mobile.replace('+47', '') || '',
		},
		faultyService: {
			serviceType: 'Mobil',
			identifier: '',
		},
		identifierType: {
			id: 'MD',
		},
		zendeskRef: '',
		notificationEmails: [user?.email],
		attachments: [],
	});
}

export default createDefaultTicketFormModel;
