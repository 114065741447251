import React from 'react';
import PropTypes from 'prop-types';

function NewShevronDownIcon({size = 10, color = '#262738', style = {}}) {
	return (
		<svg width={size} height={size * 0.7} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
			<path d="M9 1L5 5L1 0.999999" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
		</svg>
	);
}

NewShevronDownIcon.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	style: PropTypes.object,
};

export default NewShevronDownIcon;
