import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import {useTranslation} from 'react-i18next';

import {Button, Dialog, Divider, Layout, Page, PlusIcon, Text} from '@phonectas/unifon-ui-kit';
import AdaptiveDrawer from 'common/components/AdaptiveDrawer';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';
import {StyledToolbarNew} from 'common/components/styled';
import AdminTable from './components/AdminTable';
import UserContent from './components/UserContent';
import AddUserForm from './components/AddUserForm';
import useDevDocAdminStore from 'common/stores/devDocAdmin';
import SearchWidget from '../../common/components/SearchWidget';

function DeveloperDocUsers(props) {
	const [t] = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const {fetchList, deleteUser} = useDevDocAdminStore();
	const usersList = useDevDocAdminStore((state) => state?.usersList);
	const activeUser = useDevDocAdminStore((state) => state?.user);
	const isSmallScreen = useSmallScreen();
	const [query, setQuery] = useState('');
	const onSearch = useCallback((value) => {
		setQuery(value);
	}, []);
	const onClearSearch = useCallback(() => {
		setQuery('');
	}, []);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showAddUser, setShowAddUser] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const onHideDeleteDialog = useCallback(() => setShowDeleteDialog(false), []);
	const onShowDeleteDialog = useCallback(() => setShowDeleteDialog(true), []);
	const onDeleteUser = useCallback(() => {
		deleteUser(activeUser?.id, () => {
			fetchList();
			setSelectedUser(null);
			onHideDeleteDialog();
			navigate('/dev-docs');
		});
	}, [activeUser, deleteUser]);
	const isLoading = false;
	const onShowAddUser = useCallback(() => {
		setShowAddUser(true);
	}, []);
	const onHideAddUser = useCallback(() => {
		setShowAddUser(false);
	}, []);
	const filteredUsers = usersList?.filter((item) => {
		if (query === '') {
			return true;
		} else {
			return item.username.toLowerCase().includes(query.toLowerCase()) ||
				item.id.toString().includes(query);
		}
	});

	useEffect(() => {
		if (location.search === '') {
			setSelectedUser(null);
		}
	}, [location.search, selectedUser]);

	useEffect(() => {
		if (usersList === null) {
			fetchList();
		}
	}, [fetchList]);

	return (
		<Page style={{paddingRight: isSmallScreen && selectedUser ? '0px' : undefined}}>
			<Layout fullHeight nowrap fullWidth gap="lg" alignItems="start">
				<Layout direction="column" nowrap gap="none" fullWidth fullHeight style={{minWidth: '65%'}}>
					<StyledToolbarNew justifyContent="space-between" nowrap fullWidth direction="row">
						<Layout nowrap>
							<Text variant="h1">{t('Developer Docs Users')}</Text>
						</Layout>
						<Layout nowrap>
							<SearchWidget
								placeholder={t('Search')}
								onChange={onSearch}
								value={query}
								clearSearch={onClearSearch}
							/>
							<Button
								icon={<PlusIcon />}
								color="secondary"
								compact
								onClick={onShowAddUser}
							>
								{t('Add user')}
							</Button>
						</Layout>
					</StyledToolbarNew>
					<Page.Section fullHeight fullWidth>
						<Layout fullHeight fullWidth nowrap>
							{
								usersList !== null && (
									<AdminTable
										data={filteredUsers}
										selectedUser={selectedUser}
										setSelectedUser={setSelectedUser}
										onDelete={onShowDeleteDialog}
									/>
								)
							}
						</Layout>
					</Page.Section>
				</Layout>
				{
					selectedUser && (
						<Divider vertical dark />
					)
				}
				{
					selectedUser && (
						<Layout
							direction="column"
							nowrap
							gap="none"
							style={{width: isSmallScreen ? 'auto' : '100%', minWidth: isSmallScreen ? 'auto' : '35%'}}
							fullHeight
						>
							<StyledToolbarNew fullWidth nowrap direction="row">
								{
									!isSmallScreen && (
										<Text variant="h1" style={{textDecoration: 'underline'}}>{t('Details')}</Text>
									)
								}
							</StyledToolbarNew>
							{
								selectedUser && (
									<>
										{
											!isSmallScreen ? (
												<Page.Section fullHeight>
													<Layout direction="column" fullHeight fullWidth nowrap>
														<UserContent
															activeId={selectedUser}
														/>
													</Layout>
												</Page.Section>
											) : <></>
										}
									</>
								)
							}
							{
								isSmallScreen && (
									<AdaptiveDrawer
										width={400}
										initialSize={51}
									>
										<Layout direction="column" fullWidth nowrap>
											<Page.Section fullHeight fullWidth style={{paddingRight: '74px'}}>
												<UserContent
													activeId={selectedUser}
												/>
											</Page.Section>
										</Layout>
									</AdaptiveDrawer>
								)
							}
						</Layout>
					)
				}
			</Layout>
			<Dialog
				show={showAddUser}
				onClose={onHideAddUser}
				title={`${t('Add new user')}`}
			>
				<AddUserForm onClose={onHideAddUser}/>
			</Dialog>
			<Dialog
				show={showDeleteDialog}
				onClose={onHideDeleteDialog}
				title={`${t('Delete')} ${activeUser?.username}?`}
				onOk={onDeleteUser}
			>
				{/* <Text>{t('Are you sure you want to delete this user?')}</Text> */}
				{/* <TextField label={t('Password')} value={password} onChange={onPasswordChange} fullWidth /> */}
			</Dialog>
			<LoaderPortal isLoading={isLoading} />
		</Page>
	);
}

export default DeveloperDocUsers;
