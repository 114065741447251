import React from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';

import {useDomObserver} from 'common/hooks/useDomObserver';

Portal.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	portalId: PropTypes.string,
};

function Portal({portalId = '', children}) {
	const node = useDomObserver(portalId);
	return ReactDOM.createPortal(children, node || document.body);
}

export default React.memo(Portal);
