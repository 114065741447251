import React from 'react';
import PropTypes from 'prop-types';

function RatorEventIcon({customSize = 36}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#F3F3F5"/>
			<path d="M16 9V12" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M16 20V23" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M23 16L20 16" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M12 16L9 16" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M20.9492 11.0498L18.8279 13.1711" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M13.1719 18.8281L11.0506 20.9494" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M20.9492 20.9502L18.8279 18.8289" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M13.1719 13.1719L11.0506 11.0506" stroke="#9798A9" strokeWidth="1.5"/>
		</svg>
	);
}

RatorEventIcon.propTypes = {
	customSize: PropTypes.number,
};

export default RatorEventIcon;
