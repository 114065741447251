import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
// import useThreeSixtyStore from 'common/stores/ThreeSixty';

function BedriftsnettTable({data}) {
	const [t] = useTranslation();
	// const billingGroups = useThreeSixtyStore((state) => state.billingGroups);
	const onRowClick = useCallback((item) => {
		console.log(item.org_nr);
		// navigate(`/unifon_360/customer?id=${item.reg_nr}`);
	}, [onRowClick]);

	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			onClick={() => onRowClick(item)}
			selected={item.index === index}
			index={index}
		>
			<Table.Cell>{item?.org_nr}</Table.Cell>
			<Table.Cell>{item?.name}</Table.Cell>
			<Table.Cell>{item?.storkunde}</Table.Cell>
			<Table.Cell>{item?.storkunde_ansvarlig}</Table.Cell>
			<Table.Cell>{item?.company_type}</Table.Cell>
			<Table.Cell>{item?.brreg_antall_ansatte}</Table.Cell>
			<Table.Cell>{item?.brreg_naeringkode}</Table.Cell>
		</Table.Row>
	), [onRowClick]);

	if (!data) {
		return (
			<StyledSectionDiv $endDiv $fullHeight>
				<Layout
					fullHeight
					fullWidth
					alignItems="center"
					justifyContent="center"
				>
					<Text>{t('No data')}</Text>
				</Layout>
			</StyledSectionDiv>
		);
	}

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={data}
				renderItem={(item, index) => renderItem(item, index)}
			>
				<Table.Row style={{position: 'sticky', top: '0px'}}>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
					<Table.Cell>{t('TBD')}</Table.Cell>
				</Table.Row>
			</Table>
		</StyledSectionDiv>
	);
}

BedriftsnettTable.propTypes = {
	data: PropTypes.array,
};

export default BedriftsnettTable;
