import React, {useState} from 'react';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import PropTypes from 'prop-types';
import {useUser} from 'common/stores/user';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import Notes from './Notes';

import {Button, Divider, Layout, ShevronDownIcon, Text, TextArea} from '@phonectas/unifon-ui-kit';
import {StyledDivider} from 'common/components/styled';
import {useTranslation} from 'react-i18next';
import {StyledNoSelectText} from 'common/styled';
import TextDivWithLabel from 'common/components/TextDivWithLabel';
import EmailNotificationTable from '../caseEmailNotification/EmailNotificationTable';
import CaseAttachments from '../caseAttachments/CaseAttachments';

function CaseContent({currentTicket, noteMsg, handleNoteChange, handleAddNote, ticketList}) {
	const [t] = useTranslation();
	const isUser = useUser()?.catalogID;
	const isNotes = currentTicket?.note.length > 0;
	const attachments = currentTicket?.attachment;
	const attachmentSize = attachments?.length;
	const [noteAccordion, setNoteAccordion] = useState(false);

	return (
		<Layout>
			<Divider style={{marginBottom: '10px'}}/>
			<Layout direction="column" gap="xs" fullWidth>
				<Text variant="h4">
					{currentTicket?.name}
				</Text>
				{
					currentTicket?.responsibleUser && (
						<Text>
							{`${t('responsible')}: ${currentTicket?.responsibleUser}`}
						</Text>
					)
				}
				{
					currentTicket?.priority && (
						<Text>
							{`${t('Priority')}: ${currentTicket?.priority}`}
						</Text>
					)
				}
			</Layout>
			<Layout direction="row" gap="lg" nowrap fullWidth justifyContent="start">
				<Layout direction="column" nowrap>
					<table>
						<tbody>
							<tr>
								<td><StyledNoSelectText>Reporter:</StyledNoSelectText></td>
								<td>{currentTicket.customer?.reportedByName || ''}</td>
							</tr>
							<tr>
								<td><StyledNoSelectText>Type:</StyledNoSelectText></td>
								<td>{currentTicket.faultyService?.serviceType}</td>
							</tr>
							<tr>
								<td><StyledNoSelectText>Status:</StyledNoSelectText></td>
								<td>{currentTicket?.status}</td>
							</tr>
						</tbody>
					</table>
				</Layout>
				<Layout direction="column" nowrap>
					<table>
						<tbody>
							<tr>
								<td><StyledNoSelectText>{t('Created')}:</StyledNoSelectText></td>
								<td>{getDateByCustomFormat(ticketList?.createdAt, DATE_FORMATS.DD_MM_YYYY_HH_mm)}</td>
							</tr>
							<tr>
								<td><StyledNoSelectText>{t('Updated')}:</StyledNoSelectText></td>
								<td>{getDateByCustomFormat(currentTicket?.lastUpdate, DATE_FORMATS.DD_MM_YYYY_HH_mm)}</td>
							</tr>
							<tr>
								<td><StyledNoSelectText>{t('Severity')}:</StyledNoSelectText></td>
								<td>{currentTicket?.severity}</td>
							</tr>
						</tbody>
					</table>
				</Layout>
			</Layout>
			<Layout fullWidth gap="sm">
				<Layout fullWidth>
					<Accordion sx={{width: '100%', marginTop: '10px'}}>
						<AccordionSummary
							expandIcon={<ShevronDownIcon />}
						>
							<Text variant="h4">{`${t('Active Email list')}: ${currentTicket.notificationEmails?.length || 0}/10`}</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Layout
								nowrap
								direction="column"
								fullWidth
								style={{position: 'relative'}}
							>
								<EmailNotificationTable data={currentTicket?.notificationEmails}/>
							</Layout>
						</AccordionDetails>
					</Accordion>
				</Layout>
				<Layout fullWidth>
					<Accordion sx={{width: '100%', marginTop: '10px'}}>
						<AccordionSummary
							expandIcon={<ShevronDownIcon />}
						>
							<Text variant="h4">{`${t('Attachments')}: ${attachmentSize}`}</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Layout
								nowrap
								direction="column"
								fullWidth
								style={{position: 'relative'}}
							>
								<CaseAttachments data={currentTicket} />
							</Layout>
						</AccordionDetails>
					</Accordion>
				</Layout>
			</Layout>
			<StyledDivider />
			<TextDivWithLabel text={currentTicket?.description} label={t('Description')}/>
			<Layout fullWidth>
				<Accordion
					sx={{width: '100%', marginTop: '10px'}}
					expanded={noteAccordion}
					onChange={(_, expanded) => setNoteAccordion(expanded)}
				>
					<AccordionSummary
						expandIcon={<ShevronDownIcon />}
					>
						<Text variant="h4">{`${t('Notes')}: ${currentTicket?.note.length || 0}`}</Text>
					</AccordionSummary>
					<AccordionDetails>
						<Layout nowrap direction="column" fullWidth>
							<Layout direction="row" fullWidth gap="none">
								<TextArea
									fullWidth
									value={noteMsg}
									onChange={handleNoteChange}
									style={{minHeight: '150px'}}
									placeholder={t('Add case note...')}
								/>
								<Button variant="secondary" onClick={handleAddNote}>{t('Send')}</Button>
							</Layout>
							{
								isNotes && (
									<>
										<Divider dark fullWidth />
										<Notes
											notesData={currentTicket?.note}
											userId={isUser}
											caseId={currentTicket?.id}
										/>
									</>
								)
							}
						</Layout>
					</AccordionDetails>
				</Accordion>
			</Layout>
		</Layout>
	);
}

CaseContent.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	currentTicket: PropTypes.object,
	noteMsg: PropTypes.string,
	handleNoteChange: PropTypes.func,
	handleAddNote: PropTypes.func,
	ticketList: PropTypes.object,
};

export default CaseContent;
