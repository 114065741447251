import sc from 'styled-components';
import {Drawer, Layout, Icon} from '@phonectas/unifon-ui-kit';

export const StyledDrawerExpander = sc(Layout)`
	width: 51px;
	cursor: pointer;
	padding: ${({theme}) => theme.spacing(7, 4)};
	box-sizing: border-box;
    svg {
        opacity: 0.5;
        transition: opacity 200ms;
    }
    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

export const StyledDrawer = sc(Drawer)`
	right: -20px !important;
	border-radius: ${({theme}) => theme.borderRadius * 2}px 0 0 0;
`;

export const RotatableIcon = sc(Icon)`
    transition: transform 0.3s ease;
    ${({isOpen}) => isOpen && 'transform: rotate(180deg);'}
`;
