import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import UserModel from 'common/models/user';
import AuthService from 'services/auth';
import Immutable from 'immutable';

const useUserStore = create(devtools((set) => ({
	user: null,
	requiredRoles: null,
	login: async () => await AuthService.login(),
	logout: async () => await AuthService.logout(),
	getUser: async () => {
		const user = await AuthService.getUser();
		set({
			user: user ? new UserModel(user) : null,
		}, false, 'USER/SET');
	},
	setRequiredRoles: (unauthorized) => set(() => ({
		requiredRoles: unauthorized,
	}), false, 'USER/setUnauthorized'),

	authenticate: async (code, state) => await AuthService.authenticate(code, state),
})), {
	name: 'User store',
	serialize: {
		immutable: Immutable,
	},
});

export const useUser = () => useUserStore((state) => state.user);

export default useUserStore;
