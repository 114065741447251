/**
 * This hook is used to keep track of active child route. Recives array of route constants.
 * @param {string[]} routes Array of route constants
 * @return {{activeRoute: string, params: object}}
 */

import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom/dist';

export function useChildRoutes(routes) {
	if (!routes.length) {
		throw new Error('useChildRoutes | route array must not be empty');
	}
	const {pathname} = useLocation();
	const fallbackPath = routes[0];
	// const fallbackPath = routes.find((route) => (route));

	const [activeRoute, setActiveRoute] = useState(fallbackPath);

	useEffect(() => {
		const newRoute = routes.find((route) => pathname.includes(route));

		if (newRoute && newRoute !== activeRoute) {
			setActiveRoute(newRoute);
		}
	}, [routes]);

	return {activeRoute};
}
