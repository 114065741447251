import {NavLink} from 'react-router-dom';
import sc from 'styled-components';
import {Text} from '@phonectas/unifon-ui-kit';

export const StyledListItemIcon = sc.div`
	color: ${({theme}) => theme.palette.primary};
	font-size: 0;
`;

export const StyledListItemText = sc(Text)`
	margin-left: 12px;
`;

export const StyledNavLink = sc(NavLink)`
	text-decoration: none;
	color: ${({theme}) => theme.palette.primary};
	display: flex;
	width: 100%;
	height: 58px;
	align-items: center;
	transition: background-color 300ms;
	padding-left: 22px;
	
	&:hover {
		background-color: ${({theme}) => theme.palette.lightSecondary}
	}
	
	&.active {
		background-color: ${({theme}) => theme.palette.primary};
		color: ${({theme}) => theme.palette.white};
		pointer-events: none;
		
		${StyledListItemIcon} svg, path {
			fill: ${({theme}) => theme.palette.white} !important;
		}
		${StyledListItemText} {
			color: ${({theme}) => theme.palette.white};
		}
	}
`;
