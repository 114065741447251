/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewEmailIcon({customSize = 32}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#F3F3F5"/>
			<g clipPath="url(#clip0_22024_26381)">
				<path d="M15.9517 8C20.6031 8 24 10.8365 24 15.231C24 18.2472 22.6876 20.0849 20.7189 20.0849C19.5416 20.0849 18.7696 19.6454 18.3643 18.7665C17.7467 19.6454 16.7817 20.1648 15.6043 20.1648C13.5199 20.1648 11.8601 18.3271 11.8601 15.97C11.8601 13.613 13.5199 11.7753 15.6043 11.7753C16.6659 11.7753 17.4958 12.2547 18.0555 13.0737V11.9351H19.7925V16.6891C19.7925 17.9675 20.0048 18.407 20.8154 18.407C21.7226 18.407 22.2823 17.1286 22.2823 15.231C22.2823 11.7953 19.5995 9.65793 15.9517 9.65793C12.3812 9.65793 9.71773 12.3945 9.71773 16.0699C9.71773 19.6654 12.304 22.3421 15.7587 22.3421C16.8782 22.3421 18.1906 21.9226 18.9626 21.3433L19.9083 22.7416C19.0205 23.4806 17.3221 24 15.7587 24C11.3776 24 8 20.6042 8 16.0699C8 11.4357 11.4548 8 15.9517 8ZM15.8359 18.387C17.1484 18.387 18.0555 17.4282 18.0555 15.97C18.0555 14.4919 17.1484 13.5531 15.8359 13.5531C14.5814 13.5531 13.5778 14.6117 13.5778 15.97C13.5778 17.3283 14.5814 18.387 15.8359 18.387Z" fill="#9798A9"/>
			</g>
			<defs>
				<clipPath id="clip0_22024_26381">
					<rect width="16" height="16" fill="white" transform="translate(8 8)"/>
				</clipPath>
			</defs>
		</svg>

	);
}

NewEmailIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewEmailIcon;
