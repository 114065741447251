import sc from 'styled-components';
import {Dialog, Layout} from '@phonectas/unifon-ui-kit';

export const StyledDialog = sc(Dialog)`
	width: 690px;
	max-height: 80vh;
	flex-wrap: nowrap;
`;

export const StyledDialogBody = sc(Layout)`
	overflow: visible auto;
	padding: 3px 2px;
`;
