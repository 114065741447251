import React from 'react';
import {Layout, Page, Text} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';

import {StyledToolbar} from 'common/styled';
import {DashboardStyled} from './styled';
import UserProfile from './components/UserProfile';


/**
 * @module Profile
 * @constructor
 */
function Profile() {
	const [t] = useTranslation();

	return (
		<Page>
			<Layout fullWidth fullHeight direction="column" nowrap autoOverflow alignItems="center">
				<DashboardStyled fullHeight fullWidth direction="column" nowrap>
					<Layout fullWidth fullHeight direction="column" nowrap gap="none">
						<StyledToolbar alignItems="center">
							<Text variant="h3">{t('Profile')}</Text>
						</StyledToolbar>
						<Layout gap="xl" direction="column" nowrap fullWidth>
							<UserProfile />
						</Layout>
					</Layout>
				</DashboardStyled>
			</Layout>
		</Page>
	);
}

export default Profile;
