import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {StyledSectionDiv} from '../../../styled';
import {Button, Layout, TextField} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import ChangeRespDialog from './ChangeRespDialog';

function CustomerConfig({customerId}) {
	const [t] = useTranslation();
	const {setCustomerResp, fetchAccountById, fetchAccountFeed} = useThreeSixtyStore();
	const activeAccount = useThreeSixtyStore((state) => state.activeAccount);
	const activeAccountData = activeAccount?.account_details;
	const getCustomerResp = activeAccountData?.customer_responsible?.name ?
		activeAccountData.customer_responsible.name : activeAccountData?.customer_resp_est;
	const [respDialog, setRespDialog] = useState(false);
	const onOpenRespDialog = useCallback(() => setRespDialog(true), []);
	const onCloseRespDialog = useCallback(() => setRespDialog(false), []);
	const onOkRespDialog = useCallback((catId, firstname, lastname ) => {
		setCustomerResp(activeAccount?.id, catId, firstname, lastname);
		setTimeout(() => {
			fetchAccountById(activeAccount?.id);
			fetchAccountFeed(activeAccount?.id);
		}, 1400);
		fetchAccountById(activeAccount?.id);
		onCloseRespDialog();
	}, [activeAccount?.id, setCustomerResp, onCloseRespDialog]);
	const handleInputChange = useCallback((e) => {
		e.preventDefault();
	}, []);
	const inputFields = useMemo(() => [
		{
			label: t('Change customer responsible'),
			type: 'text',
			name: 'customer_responsible',
			value: getCustomerResp,
			id: customerId,
			// onChange: handleInputChange,
			button: t('Change'),
			onClick: onOpenRespDialog,
			disabled: false,
		},
		{
			label: t('Change Binding'),
			type: 'date',
			name: 'binding',
			value: '',
			id: customerId,
			onChange: handleInputChange,
			button: t('Change'),
			disabled: true,
		},
	], [t, activeAccount]);

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Layout direction="column" style={{width: '50%'}}>
				{inputFields.map((field, index) => (
					<Layout alignItems="end" nowrap key={index}>
						<Layout nowrap alignItems="end" style={{width: '380px'}}>
							{field.label && (
								<TextField
									label={field.label}
									value={field.value}
									type={field.type}
									name={field.name}
									onChange={field.onChange}
									// disabled={field.disabled}
									disabled
									fullWidth
								/>
							)}
							<Button disabled={field.disabled} onClick={field.onClick} variant="primary">{field.button}</Button>
						</Layout>
					</Layout>
				))}
			</Layout>
			<ChangeRespDialog
				show={respDialog}
				onClose={onCloseRespDialog}
				title={t('Change customer responsible')}
				onOk={onOkRespDialog}
				currentResp={getCustomerResp}
			>
			</ChangeRespDialog>
		</StyledSectionDiv>
	);
}

CustomerConfig.propTypes = {
	customerId: PropTypes.number,
};

export default CustomerConfig;
