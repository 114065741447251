import React from 'react';
import ReactDOM from 'react-dom';
import Loader from '../index';
import PropTypes from 'prop-types';

const LoaderPortal = ({isLoading, size = 60}) => {
	const portalContainer = document.getElementById('loader-container');

	if (!isLoading || !portalContainer || isLoading === undefined) {
		return null;
	}

	return ReactDOM.createPortal(
		<div
			style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 13000,
			}}
		>
			<Loader size={size}/>
		</div>,
		portalContainer,
	);
};

LoaderPortal.propTypes = {
	isLoading: PropTypes.bool,
	size: PropTypes.number,
};

export default LoaderPortal;
