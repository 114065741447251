import React from 'react';
import PropTypes from 'prop-types';

function NewMobilesIcon({customSize = 28}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="6.97266" y="0.75" width="14.0556" height="26.5" rx="2.75" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M12.25 3.5H15.75" stroke="#9798A9" strokeWidth="1.5"/>
			<path d="M11.666 24.5H16.3327" stroke="#9798A9" strokeWidth="1.5"/>
		</svg>

	);
}

NewMobilesIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewMobilesIcon;
