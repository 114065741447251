/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function ShrinkIcon({color, size = 32}) {
	return (
		<svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_15_2)">
				<path d="M21.9803 20.9746H27.77C28.102 20.9746 28.3737 20.7112 28.3737 20.3892C28.3737 20.0672 28.102 19.8037 27.77 19.8037H20.5254C20.4469 19.8037 20.3684 19.8213 20.296 19.8505C20.1511 19.9091 20.0303 20.0262 19.97 20.1667C19.9398 20.237 19.9217 20.3131 19.9217 20.3892V27.4146C19.9217 27.7366 20.1933 28 20.5254 28C20.8574 28 21.1291 27.7366 21.1291 27.4146V21.8001L30.9636 31.3371C31.0844 31.4541 31.2353 31.5127 31.3923 31.5127C31.5492 31.5127 31.7002 31.4541 31.8209 31.3429C32.0564 31.1146 32.0564 30.7458 31.8209 30.5174L21.9803 20.9746Z" fill={color}/>
				<path d="M10.0086 11.022H4.22503C3.89298 11.022 3.62131 11.2854 3.62131 11.6075C3.62131 11.9294 3.89298 12.1929 4.22503 12.1929H11.4696C11.5481 12.1929 11.6266 12.1753 11.699 12.146C11.8439 12.0875 11.9647 11.9704 12.025 11.8299C12.0552 11.7596 12.0733 11.6835 12.0733 11.6075V4.58203C12.0733 4.26003 11.8017 3.99659 11.4696 3.99659C11.1376 3.99659 10.8659 4.26003 10.8659 4.58203V10.1965L1.03137 0.653666C0.795918 0.42534 0.415576 0.42534 0.180127 0.653666C-0.0553223 0.881991 -0.0553223 1.25082 0.180127 1.47915L10.0086 11.022Z" fill={color}/>
				<path d="M11.699 19.8505C11.6266 19.8213 11.5481 19.8037 11.4696 19.8037H4.22502C3.89298 19.8037 3.62131 20.0672 3.62131 20.3892C3.62131 20.7112 3.89298 20.9746 4.22502 20.9746H10.0147L0.174085 30.5116C-0.0613649 30.74 -0.0613649 31.1087 0.174085 31.3371C0.294828 31.4541 0.445757 31.5127 0.602723 31.5127C0.75969 31.5127 0.910619 31.4541 1.03136 31.3429L10.8659 21.8001V27.4146C10.8659 27.7366 11.1376 28 11.4696 28C11.8017 28 12.0733 27.7366 12.0733 27.4146V20.3892C12.0733 20.3131 12.0552 20.237 12.025 20.1667C11.9647 20.0203 11.85 19.9091 11.699 19.8505Z" fill={color}/>
				<path d="M20.296 12.1462C20.3684 12.1755 20.4469 12.1931 20.5254 12.1931H27.77C28.102 12.1931 28.3737 11.9296 28.3737 11.6076C28.3737 11.2856 28.102 11.0221 27.77 11.0221H21.9803L31.8209 1.4852C32.0564 1.25687 32.0564 0.888034 31.8209 0.659708C31.5855 0.431383 31.2051 0.431383 30.9697 0.659708L21.1291 10.1966V4.58222C21.1291 4.26023 20.8574 3.99677 20.5254 3.99677C20.1933 3.99677 19.9217 4.26023 19.9217 4.58222V11.6076C19.9217 11.6838 19.9398 11.7598 19.97 11.8301C20.0303 11.9764 20.145 12.0877 20.296 12.1462Z" fill={color}/>
			</g>
			<defs>
				<clipPath>
					<rect width="32" height="32" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

ShrinkIcon.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number,
};

export default ShrinkIcon;
