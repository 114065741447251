import React, {useCallback, useState} from 'react';
import {truncate} from 'lodash';
import PropTypes from 'prop-types';

import {Layout, Text} from '@phonectas/unifon-ui-kit';
import NewShevronDownIcon from 'common/components/icons/NewShevronDownIcon';
import {StyledDescriptionLayout} from './styled';

function EventDescription({description}) {
	const [showMore, setShowMore] = useState(false);
	const truncateDesc = truncate(description, {
		length: 100,
		separator: /,?\.* +/,
	});

	const handleShowMore = useCallback(() => {
		setShowMore((prev) => !prev);
	}, []);

	return (
		<Layout style={{position: 'relative'}} direction="row" gap="xs" fullWidth nowrap alignItems="center">
			<StyledDescriptionLayout direction="column" fullWidth>
				<Text
					variant="body1"
					// style={{cursor: 'pointer'}}
					// onClick={handleShowMore}
				>
					{showMore ? description : truncateDesc}
				</Text>
			</StyledDescriptionLayout>
			<Layout direction="column" nowrap fullHeight style={{width: '20px'}}>
				{
					description.length > truncateDesc.length && (
						<Layout
							style={{cursor: 'pointer'}}
							fullHeight
							justifyContent="space-between"
							onClick={handleShowMore}
							direction="column"
						>
							<NewShevronDownIcon
								style={{
									transform: showMore ? 'rotate(180deg)' : 'rotate(0)',
								}}
								size={16}
							/>
						</Layout>
					)
				}
			</Layout>
		</Layout>
	);
}

EventDescription.propTypes = {
	description: PropTypes.string,
};

export default EventDescription;
