/* eslint-disable no-useless-escape */
export function isEmailValid(email) {
	if (!email && typeof email !== 'string') return false;
	// eslint-disable-next-line max-len
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export function simpleIsEmailValid(email) {
	if (!email && typeof email !== 'string') return false;
	const emailRegex = /^[a-zA-Z0-9_]+@[a-zA-Z0-9_]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email.toLowerCase());
}

export function unifonEmailValid(email) {
	if (!email && typeof email !== 'string') return false;
	const emailRegex = /^[a-zA-Z0-9._%+-]+@unifon\.no$/;
	return emailRegex.test(email.toLowerCase());
}


/**
 * Truncates an email address to show the first and last names.
 * @param {string} email - The email address to truncate.
 * @return {Object} An object containing the full email and the truncated version.
 */
export function truncateEmail(email) {
	// Split the email into local part and domain
	if (!email || email === null) return {fullEmail: '', truncated: ''};
	const [localPart] = email.split('@');

	// Split the local part by "."
	const nameParts = localPart.split('.');

	// Concatenate the first and last name parts
	const truncatedName = `${nameParts[0]}. ${nameParts[nameParts.length - 1]}`;

	// Return the full email and the truncated version
	return {
		fullEmail: email,
		truncated: truncatedName,
	};
}
