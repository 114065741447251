import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Layout, Text} from '@phonectas/unifon-ui-kit';
import {useLocation} from 'react-router-dom';
import useBMOStore from 'common/stores/BMO';
import CaseContent from '../components/cases/CaseContent';
import useUserStore from 'common/stores/user';
import {sliceFullName} from 'utils/sliceFullName';
import {CURRENT_DATE_TIME_ISO} from 'utils/date';
import CancelDialog from '../components/cases/CancelDialog';
import {useTranslation} from 'react-i18next';
import {useSmallScreen} from 'common/hooks/useScreenSize';

function Case({data}) {
	const [t] = useTranslation();
	const {addNote, getTicketId, BMO} = useBMOStore();
	// const isLoading = useBMOStore((state) => state.BMO.isLoading);
	const isSmallScreen = useSmallScreen();
	const {user} = useUserStore();
	const location = useLocation();
	const {search} = location;
	const firstName = sliceFullName(user.name, true);
	const lastName = sliceFullName(user.name, false);
	const [noteMsg, setNoteMsg] = useState('');
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const onShowCancelDialog = useCallback(() => setShowCancelDialog(true), []);
	const onHideCancelDialog = useCallback(() => {
		setShowCancelDialog(false);
	}, []);
	const handleCancel = useCallback(() => {
		setShowCancelDialog(false);
	}, []);
	const handleNoteChange = useCallback(({target: {value}}) => {
		setNoteMsg(value);
	}, [setNoteMsg]);
	const id = search ? search.match(/\d+/)[0] : null;
	const activeCase = id === BMO.ticketById.id;
	const ticketList = data?.find((obj) => obj.id.toString() === id);
	const note = {
		'authorFirstName': firstName,
		'authorLastName': lastName,
		'date': CURRENT_DATE_TIME_ISO,
		'text': noteMsg,
		'zendesk_ref': ticketList?.zendeskRef,
	};
	const fetchCase = useCallback(async () => {
		if (!activeCase) {
			try {
				await getTicketId(id, () => {
				}, (error) => {
					console.error('Error during getTicketId:', error);
				});
			} catch (err) {
				console.error(err);
			}
		}
	}, [id, getTicketId, activeCase]);

	useEffect(() => {
		fetchCase();
	}, [fetchCase]);

	const handleAddNote = useCallback(async () => {
		try {
			await addNote((id), note, () => {
				getTicketId(id);
			});
			setNoteMsg('');
		} catch (err) {
			console.error(err);
		}
	}, [addNote, id, note]);

	// if (isLoading) {
	// 	return (<Skeleton />);
	// }

	return (
		<Layout
			style={{width: isSmallScreen ? '780px' : 'fit-content'}}
		>
			<Layout fullWidth style={{justifyContent: 'space-between'}}>
				<Text variant="h3">
					{
						!id ? null : `${t('Case')}: ${id}`
					}
				</Text>
				<Text variant="h3">
					{
						!ticketList?.zendeskRef ? null : `Zendesk: ${ticketList?.zendeskRef}`
					}
				</Text>
			</Layout>
			{
				activeCase && (
					<>
						<CaseContent
							currentTicket={BMO.ticketById}
							ticketList={ticketList}
							noteMsg={noteMsg}
							handleNoteChange={handleNoteChange}
							handleAddNote={handleAddNote}
						/>
						<Layout justifyContent="end" fullWidth>
							<Button disabled onClick={onShowCancelDialog}>{t('Cancel ticket')}</Button>
						</Layout>
						<CancelDialog
							open={showCancelDialog}
							close={onHideCancelDialog}
							id={id}
							handleChange={handleCancel}
						/>
					</>
				)
			}
		</Layout>
	);
}

Case.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
};

export default Case;
