import React, {useEffect} from 'react';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';

import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {Layout} from '@phonectas/unifon-ui-kit';

function RedirectFromBn(props) {
	const navigate = useNavigate();
	const params = useParams();
	const {fetchAccountById, setBnRedirect} = useThreeSixtyStore();
	const isUsable = false;

	useEffect(() => {
		if (!isUsable) {
			return;
		}
		fetchAccountById(params.numb);
		setBnRedirect(params.numb);
		navigate(`/Unifon360/${params.numb}`); // change to id when ready
	}, [params]);
	return (
		<Layout>
			<LoaderPortal isLoading={true} />
		</Layout>
	);
}

export default RedirectFromBn;
