import React from 'react';
import {useTranslation} from 'react-i18next';

import {Layout, Page, Text} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from '../../common/stores/ThreeSixty';


function Unauthorized() {
	const [t] = useTranslation();
	const requiredRoles = useThreeSixtyStore((state) => state?.requiredRoles);
	return (
		<Page style={{overflow: 'hidden'}}>
			<Page.Section
				style={{
					marginTop: '50px',
					overflow: 'hidden',
					height: 'fit-content',
				}}
			>
				<Layout
					gap="xl"
					fullWidth
					fullHeight
					direction="column"
				>
					<Layout fullWidth justifyContent="center">
						<Text variant='h1'>{t('Unauthorized')}</Text>
					</Layout>
					<Layout
						fullWidth
						justifyContent="center"
						direction="column"
						alignItems='center'

					>
						<Text>
							{t('You are not authorized to view this page.')}
						</Text>
						<Text>
							{t('Ask your administrator for access or if you have been given rights, please log in again.')}
						</Text>
						<Text>
							{t('Required roles: {{requiredRoles}}', {requiredRoles: requiredRoles})}
						</Text>
					</Layout>
				</Layout>
			</Page.Section>
		</Page>
	);
}

export default Unauthorized;
