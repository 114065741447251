import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {StyledDialog, StyledDialogBody} from './styled';

function TableDialog({show, children, onClose}) {
	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<StyledDialog
			show={show}
			onClose={handleClose}
		>
			<StyledDialogBody
				fullHeight
				fullWidth
				direction="column"
				nowrap
			>
				{children}
			</StyledDialogBody>

		</StyledDialog>
	);
}

TableDialog.propTypes = {
	show: PropTypes.bool,
	children: PropTypes.node,
	onClose: PropTypes.func,
};

export default TableDialog;
