import sc from 'styled-components';
import {Select, TextField} from '@phonectas/unifon-ui-kit';

export const StyledTextFieldXl = sc(TextField)`
	width: 250px;
`;

export const StyledTextFieldMd = sc(TextField)`
	width: 180px;
`;

export const StyledTextFieldSm = sc(TextField)`
	width: 120px;
`;

export const StyledTextFieldXs = sc(TextField)`
	width: 100px;
`;

export const StyledSelectdMd = sc(Select)`
	width: 180px;
`;
export const StyledSelectdSm = sc(Select)`
	width: 120px;
`;
export const StyledSelectdXs = sc(Select)`
	width: 100px;
`;
