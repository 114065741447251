import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {StyledCenterCenterLayout} from 'common/styled';
import useDevDocAdminStore from 'common/stores/devDocAdmin';
import AdminTableRow from './AdminTableRow';
import {LIMIT_PAYLOAD} from 'common/constants';
// import AdminTableRow from './AdminTableRow';

/**
 * @module containers/ThreeSixty/admin/components/AdminTable
 * @param  {Object}	data
 * @return {JSX.Element}
 * @constructor
 */
function AdminTable({data, selectedUser, setSelectedUser, onDelete}) {
	const [t] = useTranslation();
	const {fetchList, userById} = useDevDocAdminStore();
	const usersList = useDevDocAdminStore((state) => state.usersList);
	const activeUserId = useDevDocAdminStore((state) => state.user?.id);
	const [isData, setIsData] = useState(false);
	const navigate = useNavigate();
	const onClick = useCallback((item) => {
		navigate(`/dev-docs/?id=${item}`);
		setSelectedUser(item);
		if (activeUserId !== item) {
			userById(item);
		}
	}, [navigate, selectedUser]);

	const deleteUser = useCallback((e, item) => {
		// setSelectedUser(item);
		onDelete(item);
	}, []);

	useEffect(() => {
		if (usersList.length > 0) {
			setIsData(false);
		} else {
			setIsData(true);
		}
	}, [usersList]);

	const renderItem = useCallback((id, item, index) => (
		<AdminTableRow
			key={index}
			item={item}
			index={index}
			id={id}
			onClick={onClick}
			onDelete={deleteUser}
			selected={item.id === selectedUser}
		/>
	), [onClick, selectedUser]);

	useEffect(() => {
		if (usersList === null) {
			fetchList(LIMIT_PAYLOAD);
		}
	}, [usersList]);

	const onLoadMore = useCallback((size) => {
		if (LIMIT_PAYLOAD < size) return;
		fetchList(size + LIMIT_PAYLOAD);
	}, []);

	return (
		<Layout fullWidth nowrap fullHeight>
			{
				isData ?
					<StyledCenterCenterLayout>
						<Text variant="h4">
							{t(`No user data available`)}
						</Text>
					</StyledCenterCenterLayout> :
					<Layout grow fullWidth fullHeight direction="column" nowrap>
						<Table
							style={{paddingBottom: '20px'}}
							items={data}
							renderItem={(item, index) => renderItem(item.id, item, index)}
							fullHeight
							fullWidth
							virtualized
							onScrollDown={onLoadMore}
						>
							<Table.Row>
								<Table.Cell>{t('Name')}</Table.Cell>
								<Table.Cell>{t('Updated at')}</Table.Cell>
								<Table.Cell>{t('Created at')}</Table.Cell>
								<Table.Cell width={36}>{t('Delete')}</Table.Cell>
							</Table.Row>
						</Table>
					</Layout>
			}
		</Layout>
	);
}

AdminTable.propTypes = {
	data: PropTypes.array,
	setSelectedUser: PropTypes.func,
	selectedUser: PropTypes.number,
	onDelete: PropTypes.func,
};

export default React.memo(AdminTable);
