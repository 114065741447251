import React, {useCallback, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import SearchWidget from 'common/components/SearchWidget';
import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';

function RenderItemDetails({data}) {
	const userCustomers = data?.customers;
	const [t] = useTranslation();
	const [searchQuery, setSearchQuery] = useState('');
	const handleSearchQuery = useCallback((value) => {
		setSearchQuery(value);
	}, []);
	const handleCrearQuery = useCallback(() => {
		setSearchQuery('');
	}, []);
	const handleClickedRow = useCallback((item) => {
		console.log(item.account);
		// add navigate when api is ready
	}, []);
	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			index={index}
			onClick={() => handleClickedRow(item)}
		>
			<Table.Cell>{item?.kunde}</Table.Cell>
			<Table.Cell>{item?.account}</Table.Cell>
		</Table.Row>
	), [data]);

	const filteredList = useMemo(() => {
		if (!searchQuery) {
			return userCustomers;
		}
		return userCustomers.filter((item) =>
			item?.kunde.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	}, [searchQuery, data]);

	return (
		<Layout fullWidth fullHeight style={{overflow: 'hidden'}} gap="sm">
			<Layout
				direction="row"
				fullWidth
				nowrap
				justifyContent="space-between"
				alignItems="center"
				style={{overflow: 'hidden'}}
			>
				<Layout fullWidth direction="column">
					<Text variant="h4">{data?.name}</Text>
					<Layout style={{width: '300px'}}>
						<SearchWidget
							placeholder="Search"
							onChange={handleSearchQuery}
							clearSearch={handleCrearQuery}
						/>
					</Layout>
				</Layout>
			</Layout>
			<Table
				items={filteredList}
				renderItem={renderItem}
				style={{overflow: 'auto', paddingBottom: '120px'}}
			>
				<Table.Row>
					<Table.Cell>{t('Customer')}</Table.Cell>
					<Table.Cell>Some info</Table.Cell>
				</Table.Row>
			</Table>
		</Layout>
	);
}

RenderItemDetails.propTypes = {
	data: PropTypes.object,
};

export default RenderItemDetails;
