import PropTypes from 'prop-types';

export const SubscriptionDetailsType = PropTypes.shape({
	accountID: PropTypes.string,
	catalogID: PropTypes.string,
	billingGroupName: PropTypes.string,
	billingGroupID: PropTypes.string,
	extCustomerID: PropTypes.string,
	extEmployeeID: PropTypes.string,
	paymentProfileID: PropTypes.string,
	paymentProfileDescription: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	position: PropTypes.string,
	skills: PropTypes.arrayOf(PropTypes.string),
	departmentID: PropTypes.string,
	departmentName: PropTypes.string,
	picture: PropTypes.string,
	bnCustomerID: PropTypes.number,
	bnMobile: PropTypes.string,
	bnFixed: PropTypes.string,
	phoneNumber: PropTypes.string,
	categoryGroupUsage: PropTypes.arrayOf(PropTypes.shape({
		categoryGroupID: PropTypes.string,
		categoryGroupName: PropTypes.string,
		coveredAmount: PropTypes.number,
		coveredAmountString: PropTypes.string,
		usedAmount: PropTypes.number,
		usedAmountString: PropTypes.string,
		usedYtd: PropTypes.number,
		usedYtdString: PropTypes.string,
		deductedAmount: PropTypes.number,
		deductedAmountString: PropTypes.string,
		deductedYtd: PropTypes.number,
		deductedYtdString: PropTypes.string,
		reported: PropTypes.bool,
	})),
});

