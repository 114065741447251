import {useState, useCallback} from 'react';

import {useTranslation} from 'react-i18next';
import {showSuccess, showError} from '@phonectas/unifon-ui-kit';
import {delay} from 'lodash';

/**
 * Custom hook to copy text to clipboard.
 * @return {Array} An array containing the copy status and the copy function.
 */
export function useCopyToClipboard() {
	const [t] = useTranslation();
	const [isCopied, setIsCopied] = useState(false);

	const copyToClipboard = useCallback(async (text, successMsg) => {
		if (!navigator.clipboard) {
			console.error('Clipboard API not available');
			showError({
				message: t('Failed to copy text, try again'),
			});
			return;
		}

		try {
			await navigator.clipboard.writeText(text);
			setIsCopied(true);
			showSuccess({
				message: successMsg || t('Copied to clipboard'),
			});

			delay(setIsCopied, 2000, false);
		} catch (err) {
			console.error('Failed to copy text: ', err);
			showError({
				message: t('Failed to copy text, try again'),
			});
		}
	}, []);

	return [isCopied, copyToClipboard];
}

