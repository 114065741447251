/* eslint-disable max-len */
import React from 'react';

function FilterOffIcon({...props}) {
	return (
		<svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="30.2968" cy="5.17262" r="5.17262" fill="#27AD61"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M22.3641 3.3916H7.91667C6.48728 3.3916 5.22491 4.50373 5.22491 6.00002C5.22491 6.48534 5.36443 6.95741 5.6228 7.36521L14.7202 21.724V29.4316V30.1178L15.3344 30.4238L20.7433 33.1179L22.3459 33.9161V32.1257V21.7247L27.9216 12.9485C27.1976 12.7276 26.5164 12.4084 25.8938 12.0064L20.3019 20.808L20.1291 21.0801V21.4024V30.3353L16.937 28.7454V21.4024V21.0808L16.7649 20.8092L10.4506 10.8431H24.473C24.1402 10.5014 23.8373 10.1306 23.5684 9.7347H9.74838L7.49542 6.17876C7.45832 6.1202 7.44175 6.0586 7.44175 6.00002C7.44175 5.83946 7.59715 5.60844 7.91667 5.60844H22.1798C22.1722 5.46412 22.1683 5.3188 22.1683 5.17258C22.1683 4.56078 22.2359 3.96477 22.3641 3.3916Z" fill="#9798A9"/>
		</svg>

	);
}

export default FilterOffIcon;
