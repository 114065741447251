/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewCopyIcon({customSize = 22}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.48 10.5H3C2.60218 10.5 2.22064 10.342 1.93934 10.0607C1.65804 9.77936 1.5 9.39782 1.5 9V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9C9.39782 1.5 9.77936 1.65804 10.0607 1.93934C10.342 2.22064 10.5 2.60218 10.5 3V5.47" stroke="#9798A9" strokeMiterlimit="10"/>
			<path d="M7 5.5H13C13.3978 5.5 13.7794 5.65804 14.0607 5.93934C14.342 6.22064 14.5 6.60218 14.5 7V13C14.5 13.3978 14.342 13.7794 14.0607 14.0607C13.7794 14.342 13.3978 14.5 13 14.5H7C6.60218 14.5 6.22064 14.342 5.93934 14.0607C5.65804 13.7794 5.5 13.3978 5.5 13V7C5.5 6.60218 5.65804 6.22064 5.93934 5.93934C6.22064 5.65804 6.60218 5.5 7 5.5Z" stroke="#9798A9" strokeMiterlimit="10"/>
		</svg>

	);
}

NewCopyIcon.propTypes = {
	customSize: PropTypes.number,
};

export default NewCopyIcon;
