const TOKEN_ALIAS = '__auth__';
export const token = localStorage.getItem(TOKEN_ALIAS);
export const ALIAS_HEADER = {
	'Authorization': `Bearer ${token}`,
	'Content-Type': 'application/json',
	'Accept': '*/*',
	'Accept-Encoding': 'gzip, deflate, br',
};
export const ALIAS_HEADER_FORM_DATA = {
	'Authorization': `Bearer ${token}`,
};

export const ALIAS_HEADER_TWO = {
	'Authorization': `Bearer ${token}`,
	'Content-Type': 'application/json',
};

export const LIMIT_PAYLOAD = 50;

export const BMO_API_V2_FORM_DATA = 'https://ks.staging.unifon.no/api/v2/telenor/';

export const getBnApiUrl = () => {
	let url = '';
	switch (window.location.hostname) {
	// prod
	// temp Prod remove when fixed and use outmarked
	case 'ks.unifon.no':
		url = 'https://bnapi.pp.unifon.no/bnapi/v1/';
		// 	url = 'https://bnapi.unifon.no/bnapi/v1/';
		break;
	// preprod
	case 'ks.pp.unifon.no':
		url = 'https://bnapi.pp.unifon.no/bnapi/v1/';
		break;
		// dev, test
	case 'localhost':
		url = 'https://bnapi.test.unifonip.no/bnapi/v1/';
		break;
	case 'ks.staging.unifon.no':
		url = 'https://bnapi.test.unifonip.no/bnapi/v1/';
		break;
	}
	return url;
};
