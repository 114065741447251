import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Layout} from '@phonectas/unifon-ui-kit';

import * as Elements from './styled';
import ToggleDrawerIcon from '../icons/ToggleDrawerIcon';

/**
 * @module AdaptiveDrawer
 * @param {React.ElementType} children
 * @return {JSX.Element}
 * @constructor
 */
function AdaptiveDrawer({children, setWidth, initialSize}) {
	const [toggleDrawer, setToggleDrawer] = useState(false);
	const toggleOpen = useCallback(() => setToggleDrawer((prev) => !prev), [toggleDrawer]);
	const closeDrawer = useCallback(() => setToggleDrawer(false), []);

	const handleKeyDown = useCallback((e) => {
		if (e.key === 'Escape') {
			closeDrawer();
		}
	},
	[closeDrawer],
	);
	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [handleKeyDown]);

	useEffect(() => {
		// To update possible tooltips positions
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 500);
	}, [toggleDrawer]);

	return (
		<Elements.StyledDrawer
			expand={toggleDrawer}
			anchor="right"
			variant="persistent"
			initialSize={initialSize || 80}
			finalSize={setWidth || 700}
			useShadow={toggleOpen}
		>
			<Layout nowrap gap="none" fullHeight fullWidth alignItems="start">
				{
					!toggleDrawer && (
						<Elements.StyledDrawerExpander
							data-testid="toggledrawer"
							onClick={toggleOpen}
							fullWidth
							fullHeight
							alignItems="start"
						>
							<ToggleDrawerIcon isOpen={toggleDrawer}/>
						</Elements.StyledDrawerExpander>
					)
				}
				{
					toggleDrawer && (
						<Elements.StyledDrawerExpander
							data-testid="toggledrawer"
							onClick={toggleOpen}
							// fullWidth
							// fullHeight
							alignItems="start"
						>
							<ToggleDrawerIcon isOpen={toggleDrawer}/>
						</Elements.StyledDrawerExpander>
					)
				}
				<Layout fullHeight fullWidth autoOverflow>
					{React.Children.only(children)}
				</Layout>
			</Layout>
		</Elements.StyledDrawer>
	);
}

AdaptiveDrawer.propTypes = {
	children: PropTypes.object.isRequired,
	setWidth: PropTypes.number,
	initialSize: PropTypes.number,

};

export default React.memo(AdaptiveDrawer);
