import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {StyledHeaderWarning, StyledShevronButton, StyledShevronIcon} from './styled';
import {
	Divider,
	IconButton,
	Layout,
	Text,
	WarningIcon,
} from '@phonectas/unifon-ui-kit';
import NewCopyIcon from 'common/components/icons/NewCopyIcon';
import CustomerLinks from '../CustomerLinks';
import CustomerCompanyInfo from '../CustomerCompanyInfo';
import CustomerDevices from '../CustomerDevices';
import {formatInfo} from 'utils/div';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import TextWithLabel from 'common/components/TextWithLabel';
import NewOkIcon from 'common/components/icons/NewOkIcon';

function CustomerDetails({warnings, data}) {
	const [t] = useTranslation();
	const accountDetails = data?.account_details;
	// const isSmallSCreenHeight = useSmallScreen('(max-height:800px)');
	// const isSmallSCreenWidth = useSmallScreen('(max-width:1600px)');
	const isSmallSCreen = useSmallScreen('(max-height:800px)');
	const [showContent, setShowContent] = useState(false);
	const onShowContent = useCallback(() => setShowContent((prev) => !prev), []);
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const handleCopyCustomerName = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(accountDetails?.name);
	}, [accountDetails?.name, copyToClipboard]);

	useEffect(() => {
		if (isSmallSCreen) {
			setShowContent(true);
		} else {
			setShowContent(false);
		}
	}, [isSmallSCreen]);

	return (
		<>
			{
				accountDetails?.note && (
					<StyledHeaderWarning
						direction="column"
						fullWidth
						nowrap
						// style={{minHeight: 'auto'}}
					>
						<Layout justifyContent="start" nowrap direction="row" gap="xs">
							<WarningIcon style={{alignItems: 'start'}} color="white"/>
							<Text variant="body1" color="white">{accountDetails.note}</Text>
						</Layout>
					</StyledHeaderWarning>
				)
			}
			<Layout
				fullWidth
				direction="row"
				nowrap
				onClick={onShowContent}
				style={{
					cursor: 'pointer',
				}}
			>
				<Layout fullWidth direction="column" gap="sm" style={{padding: '16px'}}>
					<Layout direction="row" gap="xs" alignItems="end">
						<Text variant='h1' style={{fontSize: '30px'}}>{formatInfo(accountDetails?.name)}</Text>
						<IconButton onClick={handleCopyCustomerName} size="xs">
							<NewCopyIcon />
						</IconButton>
					</Layout>
					<Layout direction="row">
						<Text variant='body1' color="textSecondary">
							{
								data?.caller ?
									`${t('Caller')}: ${data?.caller?.phoneNumber} (${data?.caller?.name})` : `${t('Caller')}:`
							}
						</Text>
					</Layout>
				</Layout>
				<Divider vertical/>
				<Layout
					direction="column"
					style={{minWidth: '123px', padding: '16px'}}
					nowrap
					gap="xs"
					alignItems="center"
				>
					<Layout>
						<Text variant='h1' style={{fontSize: '30px'}}>{data?.npsScore || 'N/A'}</Text>
					</Layout>
					<Layout>
						<Text variant='body1' color="textSecondary">{t('NPS score')}</Text>
					</Layout>
				</Layout>
				<Layout>
					<StyledShevronButton $open={showContent}>
						<StyledShevronIcon size="xl" />
					</StyledShevronButton>
				</Layout>
			</Layout>
			{
				showContent && !accountDetails?.note && (
					<>
						<Divider fullWidth />
						<Layout
							fullWidth
							direction="row"
							style={{padding: '16px'}}
							justifyContent="space-between"
						>
							<Layout>
								{/* <TextWithLabel label={t('Number of employees')} text={formatInfo(accountDetails?.employers_count)} /> */}
								<TextWithLabel label={t('Customer responsible')} text={accountDetails?.customer_responsible?.name || ''} />
								<TextWithLabel label={t('Org. number')} text={formatInfo(accountDetails?.vat_number)} />
							</Layout>
							<Layout
								direction="column"
								gap="xs"
							>
								<Layout alignItems="center" style={{gap: '5px'}}>
									<NewOkIcon color={accountDetails?.is_vip_customer ? undefined : '#9798A9'} />
									<Text>VIP</Text>
								</Layout>
								<Layout alignItems="center" style={{gap: '5px'}}>
									<NewOkIcon color={accountDetails?.is_major_customer ? undefined : '#9798A9'} />
									<Text>{t('Major customer')}</Text>
								</Layout>
							</Layout>
						</Layout>
					</>
				)
			}
			<Divider />
			{
				!showContent && (
					<>
						<Layout
							fullWidth
							direction="row"
							style={{padding: '16px'}}
						>
							<CustomerLinks data={data} />
						</Layout>
						<Divider />
						<CustomerCompanyInfo data={data} />
						<Divider />
						<CustomerDevices data={data} />
					</>
				)
			}
		</>
	);
}

CustomerDetails.propTypes = {
	warnings: PropTypes.bool,
	data: PropTypes.object,
};

export default CustomerDetails;
