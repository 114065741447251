import sc from 'styled-components';

export const StyledInput = sc.input`
	padding: 8px 10px;
	background-color: rgb(244, 244, 248);
	border: 0px;
	height: fit-content;
	width: 100%;
	white-space: pre-line;
	word-break: break-word;
	overflow-wrap: break-word;
	&:focus {
        outline: none;
    }
`;
