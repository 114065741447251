import {Record} from 'immutable';

/**
 * @class TicketModel
 */
export default class TicketModel extends Record({
	description: '',
	severity: '',
	ticketType: 'Incident',
	name: '',
	customer: {
		reportedByName: '',
		reportedByPhoneNumber: '',
	},
	faultyService: {
		serviceType: 'Mobil',
		identifier: '',
	},
	identifierType: {
		id: 'MD',
	},
	zendeskRef: '',
	notificationEmails: [],
	attachments: [],
}) {
	/**
     * @constructor
     * @param {TicketInfoType} props
     */
	constructor(props) {
		super({
			description: props?.description,
			severity: props?.severity,
			ticketType: props?.ticketType,
			name: props?.name,
			customer: {
				reportedByName: props?.customer.reportedByName,
				reportedByPhoneNumber: props?.customer.reportedByPhoneNumber,
			},
			faultyService: {
				serviceType: props?.faultyService.serviceType,
				identifier: props?.faultyService.identifier,
			},
			identifierType: {
				id: props?.identifierType.id,
			},
			zendeskRef: props?.zendeskRef,
			notificationEmails: props?.notificationEmails,
			attachments: props?.attachments,
		});
	}

	/**
     * Updates the ticket with the given changes.
     * @param {Object} changes
     * @return {TicketModel}
     */
	update(changes) {
		return this.merge(changes);
	}

	/**
     * Clears the form by resetting all fields to their default values.
     * @return {TicketModel}
     */
	clearForm() {
		return this.clear();
	}

	/**
     * Clears the attachments.
     * @return {TicketModel}
     */
	clearAttachments() {
		return this.set('attachments', []);
	}

	/**
     * Clears the notifications.
     * @return {TicketModel}
     */
	clearNotifications() {
		return this.set('notificationEmails', []);
	}

	/**
     * Converts the ticket data to a FormData object.
     * @return {FormData}
     */
	toFormData() {
		const formData = new FormData();
		Object.entries(this.toJS()).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((item, index) => {
					formData.append(`${key}[${index}]`, item);
				});
			} else {
				formData.append(key, value);
			}
		});
		return formData;
	}
}
