import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Layout, Table} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';
import EmailNotificationTableRow from './EmailNotificationTableRow';

function EmailNotificationTable({data, onDelete}) {
	const [t] = useTranslation();
	const renderItem = useCallback((item, index) => {
		return (
			<EmailNotificationTableRow
				key={`item:${index}`}
				index={index}
				item={item}
				onDelete={onDelete}
			/>
		);
	}, [data]);

	return (
		<Layout fullWidth>
			<>
				<Table
					items={data}
					renderItem={renderItem}
					variant="compact"
				>
					<Table.Row>
						<Table.Cell>{t('Email')}</Table.Cell>
						<Table.Cell width={45}>{t('Delete')}</Table.Cell>
					</Table.Row>
				</Table>
			</>
		</Layout>
	);
}

EmailNotificationTable.propTypes = {
	data: PropTypes.array,
	onDelete: PropTypes.func,
};

export default EmailNotificationTable;
