/* eslint-disable max-len */
import React from 'react';

function NewQuestionIcon() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="10" cy="9.99951" r="10" fill="#E0E0E5"/>
			<path d="M9.81322 4.77026C7.67189 4.77026 6.13189 6.26626 6.08789 8.3196L8.19989 8.5396C8.19989 7.55693 8.81589 6.88226 9.73989 6.88226C10.5466 6.88226 11.0892 7.36626 11.0892 8.08493C11.0892 9.58093 8.59589 9.40493 8.59589 12.0156H10.6639C10.6639 10.7249 13.3332 10.6663 13.3332 7.86493C13.3332 6.06093 11.8666 4.77026 9.81322 4.77026ZM9.68122 15.3303C10.4292 15.3303 11.0159 14.7436 11.0159 14.0103C11.0159 13.2769 10.4292 12.7049 9.68122 12.7049C8.91856 12.7049 8.33189 13.2769 8.33189 14.0103C8.33189 14.7436 8.91856 15.3303 9.68122 15.3303Z" fill="#262738" />
		</svg>
	);
}

export default NewQuestionIcon;
