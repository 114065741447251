import i18n from 'common/i18n';

/**
 *
 * @param {*} info
 * @return {string} info or 'N/A'
 */
export function formatInfo(info) {
	return !info ? 'N/A' : info;
}

export function serviceStatuses(status) {
	switch (status) {
	case '100':
		return i18n.t('Under innportering');
	case '200':
		return i18n.t('Active');
	case '220':
		return i18n.t('Sperret - høyt forbruk');
	case '230':
		return i18n.t('Sperret');
	case '240':
		return i18n.t('Reservert');
	case '280':
		return i18n.t('Hard blocked');
	case '310':
		return i18n.t('Under utportering');
	case '400':
		return i18n.t('Terminert');
	case '410':
		return i18n.t('Utportert');
	default:
		return 'N/A';
	}
}

/**
 * Masks a string by replacing each character with the Unicode character &#9679;.
 * @param {string} str - The input string to mask.
 * @return {string} The masked string.
 */
export function maskString(str) {
	if (!str) {
		return '';
	}
	const value = str.toString();
	return value?.split('').map(() => '•︎').join('');
}

export function checkResponisble(respId, respEst) {
	if (respId) {
		return respId?.name;
	} else if (respEst) {
		return respEst;
	}
	return '';
}

export function nullCheck(value) {
	if (!value) {
		return '';
	}
	return value;
}
