/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function BroadbandIcon({customSize = 28}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_22003_49524)">
				<path d="M1.00002 18C0.99947 19 1.00855 20.4518 1.39076 21.6173C1.77296 22.7829 2.42337 23.8424 3.28968 24.7108C4.15809 25.5771 5.21777 26.2275 6.38333 26.6097C7.5489 26.9919 9 27 10.0006 27" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M5 18C5.00083 18.5 5.00966 19.3608 5.22296 20.0071C5.43627 20.6535 5.79607 21.2418 6.27436 21.7261C6.75866 22.2044 7.34699 22.5643 7.99337 22.7776C8.63976 22.9909 9.49946 22.9563 10 22.9563" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M18.1738 3.86887C18.5635 3.47392 19.2003 3.4718 19.5927 3.86416L24.1632 8.43472C24.5538 8.82524 24.5538 9.45841 24.1632 9.84893L15.2841 18.728C14.8936 19.1186 14.4302 19.4287 13.9204 19.6406C13.4107 19.8526 12.8646 19.9622 12.3133 19.9633C11.762 19.9643 11.2163 19.8567 10.7073 19.6467C10.1984 19.4367 9.73613 19.1283 9.34703 18.7392C8.56121 17.9534 8.12096 16.8865 8.12307 15.7731C8.12517 14.6596 8.56949 13.591 9.35829 12.8022L18.1738 3.86887Z" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<path d="M23.8765 7.35485L25.9002 5.33119C26.2907 4.94064 26.2928 4.30889 25.9047 3.91881L24.0689 2.07341C23.6794 1.68189 23.0448 1.68227 22.6529 2.07425L20.6159 4.11119" stroke="#9798A9" strokeWidth="1.5" strokeMiterlimit="10"/>
				<circle cx="0.998382" cy="0.998382" r="0.998382" transform="matrix(0.999998 -0.00188873 -0.00188873 0.999998 11.6621 14.4238)" fill="#9798A9"/>
			</g>
			<defs>
				<clipPath id="clip0_22003_49524">
					<rect width={`${customSize}px`} height={`${customSize}px`} fill="white"/>
				</clipPath>
			</defs>
		</svg>

	);
}

BroadbandIcon.propTypes = {
	customSize: PropTypes.number,
};
export default BroadbandIcon;

