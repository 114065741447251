import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {StyledUnifonWarningDiv, StyledUnifonWarningLayout, StyledUnifonWarningText} from './styled';
import {IconButton, Layout, WarningIcon} from '@phonectas/unifon-ui-kit';
import NewWindowIcon from 'common/components/icons/NewWindowIcon';

function CustomerWarnings({warnings}) {
	const [t] = useTranslation();
	const someWarnings = {
		one: t('Debt Collection'),
		two: t('Ongoing Churn'),
		three: t('Something Else'),
	};
	const someWarningsTwo = {
		one: t('Debt Collection'),
		two: t('Ongoing Churn'),
	};
	// function randomWarnings() {
	// 	const random = Math.floor(Math.random() * 2);
	// 	return random === 0 ? someWarnings : someWarningsTwo;
	// }
	const randomWarnings = useCallback(() => {
		const random = Math.floor(Math.random() * 2);
		return random === 0 ? someWarnings : someWarningsTwo;
	}, [warnings]);
	const onNewWindowClick = () => {
		console.log('new window clicked');
	};

	return (
		<Layout
			direction="column"
			nowrap
			fullWidth
			gap="xs"
			style={{padding: '0px'}}
		>
			{
				Object.keys(randomWarnings()).map((key, index) =>
					<StyledUnifonWarningLayout key={key} $delay={index}>
						<StyledUnifonWarningDiv nowrap $delay={index}>
							<WarningIcon size="lg" color="white"/>
							<StyledUnifonWarningText>
								{someWarnings[key]}
							</StyledUnifonWarningText>
							<IconButton
								onClick={onNewWindowClick}
								size="sm"
							>
								<NewWindowIcon customSize={20} color="white" />
							</IconButton>
						</StyledUnifonWarningDiv>
					</StyledUnifonWarningLayout>,
				)
			}
		</Layout>
	);
}


CustomerWarnings.propTypes = {
	warnings: PropTypes.bool,
};
export default CustomerWarnings;
