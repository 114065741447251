import React from 'react';

function NewEllipseIcon(props) {
	return (
		<svg width="2" height="3" viewBox="0 0 2 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="1" cy="1.5" r="1" fill="#9798A9"/>
		</svg>
	);
}

export default NewEllipseIcon;
