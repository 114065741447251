import React, {useCallback, useEffect} from 'react';
import {Layout, Page, Tabs} from '@phonectas/unifon-ui-kit';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ROUTES} from 'common/constants';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';

import {StyledToolbarNew} from 'common/components/styled';
import {useChildRoutes} from '../../common/hooks/useChildRoutes';

import CasesTab from './tabs/CasesTab';
import CreateTicketTab from './tabs/CreateTicketTab';
import Case from './tabs/Case';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import useBMOStore from 'common/stores/BMO';

/**
 * @module Impersonation
 * @return {JSX.Element}
 * @constructor
 */
function BMODashboard() {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const isLoading = useBMOStore((state) => state.BMO.isLoading);
	const isSmallScreen = useSmallScreen();
	// eslint-disable-next-line prefer-const
	let location = useLocation();
	const {pathname} = location;
	const {activeRoute} = useChildRoutes([
		ROUTES.BMO_CASES,
		ROUTES.BMO_CREATE_TICKET,
	]);

	const onTabChange = useCallback((newPage) => {
		navigate(`${ROUTES.BMODASHBOARD.replace('/*', '')}/${newPage}`);
	}, [navigate]);

	useEffect(() => {
		if (pathname === ROUTES.BMODASHBOARD) {
			navigate(activeRoute);
		}
	}, [activeRoute, pathname, navigate]);

	return (
		<Page style={{padding: '0px'}}>
			<Layout fullWidth fullHeight direction="column" nowrap autoOverflow alignItems="center">
				<Layout
					fullWidth
					fullHeight
					gap="none"
					direction="column"
					nowrap
					style={{padding: isSmallScreen ? '0px 0px 0px 20px' : '0px 20px'}}
				>
					<StyledToolbarNew
						nowrap
						fullWidth
						alignItems="center"
						justifyContent="space-between"
					>
						<Tabs
							items={[
								{value: ROUTES.BMO_CASES, label: t('Cases')},
								{value: ROUTES.BMO_CREATE_TICKET, label: t('Create Ticket')},
							]}
							active={activeRoute}
							onChange={onTabChange}
						/>
					</StyledToolbarNew>
					<Routes>
						<Route path={ROUTES.BMO_CASES} element={<CasesTab />}/>
						<Route path={ROUTES.BMO_CREATE_TICKET} element={<CreateTicketTab />}/>
						<Route path={ROUTES.BMO_CASES_ID} element={<Case />}/>
					</Routes>
				</Layout>
			</Layout>
			<LoaderPortal isLoading={isLoading} />
		</Page>
	);
}

export default BMODashboard;
