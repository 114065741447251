import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {truncate} from 'lodash';
import SimpleUploader from '../caseAttachments/SimpleUploader';

import {Layout, Text} from '@phonectas/unifon-ui-kit';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';

CaseAttachments.propTypes = {
	data: PropTypes.object,
};

function CaseAttachments({data = {}}) {
	const [t] = useTranslation();
	const isFiles = data?.attachment?.length > 0;

	return (
		<Layout fullWidth>
			<Layout direction="column" fullWidth>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<Text color="secondary">
										{t('Name')}
									</Text>
								</TableCell>
								<TableCell>
									<Text color="secondary">
										{t('Sender')}
									</Text>
								</TableCell>
								<TableCell sx={{width: '80px'}}>
									<Text color="secondary">
										{t('Date')}
									</Text>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								isFiles && data?.attachment.map((file, index) => {
									return (
										<TableRow key={index}>
											<TableCell>
												<Text variant="body">
													{truncate(file.name, {length: 34})}
												</Text>
											</TableCell>
											<TableCell>
												<Text variant="body">
													{file.sourceSystem.split(' ')[0]}
												</Text>
											</TableCell>
											<TableCell>
												<Text variant="body">
													{getDateByCustomFormat(file.timestamp, DATE_FORMATS.DD_MM_YY)}
												</Text>
											</TableCell>
										</TableRow>
									);
								})
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Layout>
			<SimpleUploader
				id={data.id}
				onUploadOk={true}
			/>
		</Layout>
	);
}

export default CaseAttachments;
