import sc from 'styled-components';
import {IconButton, Layout, Select} from '@phonectas/unifon-ui-kit';
import {alpha} from 'utils/color';

export const StyledAppBar = sc(Layout)`
	min-height: 58px;
	background-color: ${({theme}) => theme.palette.primary};
	position: relative;
	padding: ${({theme}) => theme.spacing(0, 5, 0, 4)};
`;

export const StyledAppBarIcon = sc(IconButton)`
	&:hover {
		background-color: transparent !important;
	}
	svg {
		fill: ${({theme}) => theme.palette.white} !important;
	}
`;

export const Logo = sc.img`
	margin-bottom: ${({theme}) => theme.spacing(2)}px;
	margin-left: ${({theme}) => theme.spacing(3)}px;
	vertical-align: middle;
`;

export const StyledSelect = sc(Select)`
	button {
		background: ${({theme}) => alpha(theme.palette.white, 0.1)};
		color: ${({theme}) => theme.palette.white};
		
		&:hover {
			background: ${({theme}) => alpha(theme.palette.white, 0.2)} !important;
		}
		
		svg {
			color: ${({theme}) => theme.palette.white};
		}
	}
`;

export const StyledImpersonationBar = sc(Layout)`
	position: absolute;
	background: ${({theme}) => theme.palette.success};
	padding: ${({theme}) => theme.spacing(5)}px;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 40px;
`;
